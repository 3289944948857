import { HoverCard, Flex, Box, Heading, Text } from "@radix-ui/themes";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { ReactNode } from "react";

export const InfoHoverCard: React.FC<{
  title?: string;
  content?: string | ReactNode;
  children?: ReactNode | null;
}> = ({ title, content, children }) => {
  return (
    <HoverCard.Root>
      <HoverCard.Trigger>
        {children ? (
          children
        ) : (
          <Flex justify="center" style={{ cursor: "help" }}>
            <p style={{ position: "relative" }}>
              {title}
              <InfoCircledIcon
                style={{
                  position: "absolute",
                  top: "0",
                  right: "-20px",
                }}
              />
            </p>
          </Flex>
        )}
      </HoverCard.Trigger>

      <HoverCard.Content>
        <Flex gap="4">
          <InfoCircledIcon width="24" height="24" />
          <Box>
            {title && (
              <Heading size="3" as="h3">
                {title}
              </Heading>
            )}
            {content && (
              <Text
                as="div"
                size="2"
                style={{ maxWidth: 300 }}
                mt={title ? "3" : "0"}
              >
                {content}
              </Text>
            )}
          </Box>
        </Flex>
      </HoverCard.Content>
    </HoverCard.Root>
  );
};
