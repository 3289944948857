import { Popover, Flex, Text, TextField, Switch } from "@radix-ui/themes";
import { Button } from "../../../../Components/Button/Button";
import { ChatBubbleIcon } from "@radix-ui/react-icons";
import { QuotePresenter } from "../../../../Products/QuotePresenter";
import { observer } from "mobx-react";
import { SelectCurrency } from "./SelectCurrency";
import { currencies } from "../../../../Core/Types";
import { PriceBreaks } from "../PriceBreaks/PriceBreaks";
import { useValidation } from "../../../../Core/Providers/useValidation";
import { MessagesComponent } from "../../../../Core/Messages/MessagesComponent";
import { toDecimals, validateNumber } from "../../../../Core/utils";

export const PopShippingCost: React.FC<{
  presenter: QuotePresenter;
}> = observer(({ presenter }) => {
  const [validationMessages, updateClientValidationMessages] = useValidation();

  const handleCostChange = (e: React.BaseSyntheticEvent): void => {
    const valid = validateNumber(
      e.target.name,
      e.target.value,
      updateClientValidationMessages,
    );
    if (!valid) return;
    const value = e.target.value === "" ? null : toDecimals(e.target.value);
    presenter.updateCost("shipping_cost", value);
  };

  const cleanAndSave = (opening: boolean) => {
    updateClientValidationMessages([]);
    // if the popover is closing, save the quote
    if (!opening) {
      presenter.setPreventReorder(false);
      presenter.setEditingPriceBreak(null);
      presenter.saveQuote();
    } else {
      presenter.setPreventReorder(true);
    }
  };

  const handleCurrencyChange = (value: Currencies | null) => {
    presenter.updateCurrency("shipping_cost_currency", value);
  };

  const currencySymbol =
    presenter.quote && presenter.quote.shipping_cost_currency === "USD"
      ? "$"
      : "€";

  if (!presenter.quote) {
    return <div>{presenter.locale.translate("NO_QUOTES")}</div>;
  }

  const isValueAvailable =
    (presenter.quote.shipping_cost !== null &&
      !presenter.quote.use_shipping_price_breaks) ||
    (presenter.shipping_cost_calculated !== null &&
      typeof presenter.shipping_cost_calculated !== "string" &&
      presenter.quote.use_shipping_price_breaks);

  return (
    <Popover.Root onOpenChange={cleanAndSave}>
      <Popover.Trigger>
        <Button variant="soft" color={isValueAvailable ? "indigo" : "orange"}>
          <ChatBubbleIcon width="16" height="16" />
          {!presenter.quote.use_shipping_price_breaks
            ? presenter.quote.shipping_cost !== null
              ? presenter.quote.shipping_cost + " " + currencySymbol
              : presenter.locale.translate("NO_DATA")
            : presenter.shipping_cost_calculated !== null
            ? typeof presenter.shipping_cost_calculated === "string"
              ? presenter.shipping_cost_calculated
              : presenter.shipping_cost_calculated + " " + currencySymbol
            : presenter.locale.translate("NO_BREAKS")}
        </Button>
      </Popover.Trigger>
      <Popover.Content style={{ width: 360 }}>
        <Flex gap="3" direction={"column"}>
          <Text as="label" size="2">
            <Flex gap="2" direction="row" justify="between">
              <Flex gap="2">
                <Switch
                  onCheckedChange={(checked) => {
                    presenter.overrideCost(
                      "use_shipping_price_breaks",
                      checked,
                    );
                  }}
                  checked={presenter.quote.use_shipping_price_breaks}
                />{" "}
                {presenter.locale.translate("USE_PRICE_BREAKS")}
              </Flex>
              <SelectCurrency
                options={[...currencies]}
                value={presenter.quote.shipping_cost_currency}
                onChange={handleCurrencyChange}
                fallback={presenter.locale.translate("NO_CURRENCY")}
              />
            </Flex>
          </Text>
          {presenter.quote.use_shipping_price_breaks ? (
            <>
              <Text size="2" mb="1" weight="bold">
                {presenter.locale.translate("SHIPPING_PRICE_BREAKS")}
              </Text>
              <PriceBreaks type="Shipping" presenter={presenter} />
            </>
          ) : (
            <label>
              <Text as="div" size="2" mb="1" weight="bold">
                {presenter.locale.translate("MANUAL_SHIPPING_COST")}
              </Text>
              <TextField.Root
                placeholder={presenter.locale.translate("SHIPPING_COST")}
                value={
                  presenter.quote.shipping_cost !== null
                    ? presenter.quote.shipping_cost
                    : ""
                }
                onChange={handleCostChange}
                type="number"
                name="shipping_cost"
                disabled={presenter.status === "LOADING"}
              />
            </label>
          )}
        </Flex>
        {validationMessages.length > 0 && <br />}
        <MessagesComponent />
      </Popover.Content>
    </Popover.Root>
  );
});
