import React from "react";
import { observer } from "mobx-react";
import { QuoteListPresenter } from "../../../Products/QuoteListPresenter";
import { withInjection } from "../../../Core/Providers/withInjection";
import { Quote } from "./Quote/Quote";
import { QuoteHeader } from "./Quote/QuotesHeader";
import { TopSection } from "./TopSection/TopSection";
import { Heading, ScrollArea } from "@radix-ui/themes";
import classes from "./QuoteList.module.css";
import { LayoutGroup, motion } from "framer-motion";

const QuotesListComp: React.FC<{
  presenter: QuoteListPresenter;
}> = ({ presenter }) => {
  React.useEffect(() => {
    presenter.initQuotePresenterInstances();
    return () => {
      // to remove all quotePresenter Instances
      // and clean up the reaction
      presenter.cleanUp();
    };
  }, [presenter]);

  return (
    <div>
      <Heading>{presenter.locale.translate("QUOTES")}</Heading>
      <TopSection presenter={presenter} />
      <ScrollArea className={classes.tableWrapper} size="2">
        <div className={classes.table}>
          <QuoteHeader presenter={presenter}/>
          <LayoutGroup>
            <div className={classes.tableContent}>
              {presenter.quotePresenterInstances?.map((quotePresenter) => (
                <motion.div
                  layoutId={quotePresenter.quoteId as string}
                  key={quotePresenter.quoteId}
                >
                  <Quote presenter={quotePresenter} />
                </motion.div>
              ))}
            </div>
          </LayoutGroup>
          {presenter.noQuotes && (
            <div
              style={{
                display: "flex",
                height: "50vh",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {presenter.statusHint}
            </div>
          )}
        </div>
      </ScrollArea>
    </div>
  );
};

export const QuotesList = withInjection({
  presenter: QuoteListPresenter,
})(observer(QuotesListComp));
