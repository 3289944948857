import { injectable, inject } from "inversify";
import {
  makeObservable,
  computed,
  action,
  observable,
  reaction,
  comparer,
} from "mobx";
import { MessagesPresenter } from "../Core/Messages/MessagesPresenter";
import { ProductDetailRepository } from "./ProductDetailRepository";
import {
  computePriceBreaks,
  FinCalculator,
  MissingFieldsError,
} from "../Core/financials";
import { debounce } from "lodash-es";
import { isNumber } from "../Core/utils";
import { findPriceBreakById } from "./quoteUtils";

@injectable()
export class QuotePresenter extends MessagesPresenter {
  @inject(ProductDetailRepository)
  productDetailRepository: ProductDetailRepository;

  quoteId: string | null = null;

  createdAtTimestamp: number | null = new Date().getTime();

  supplierDetails: Partial<Supplier> | null = null;

  newPriceBreak: Partial<PriceBreak> = {
    type: null,
    price: null,
    quantity: null,
    incoterm: null,
    currency: "EUR",
  };

  editingPriceBreak: Partial<PriceBreak> = {
    id: null,
    type: null,
    price: null,
    quantity: null,
    incoterm: null,
    currency: null,
  };

  reactionDisposer: (() => void) | null = null;

  removeInstance: (quoteId: string) => void;

  // this method is being set from the QuoteListPresenter
  // it's used to prevent reordering the quotes when a popover is open
  setPreventReorder: (activated: boolean) => void;

  get product() {
    return this.productDetailRepository.product;
  }

  get quote() {
    if (!this.quoteId) {
      return null;
    }
    if (
      !this.productDetailRepository.product ||
      !this.productDetailRepository.product.quotes
    ) {
      return null;
    }

    const quote: Quote | undefined =
      this.productDetailRepository.product?.quotes.find(
        (quote) => quote.id === this.quoteId
      );

    const suppliers: Supplier[] | null = this.productDetailRepository.suppliers;

    const quoteWithSupplier = quote
      ? {
          ...quote,
          supplier: suppliers?.find(
            (supplier) => supplier.id === quote.supplier_id
          ),
        }
      : null;
    return quoteWithSupplier;
  }

  get productBuffer() {
    return this.productDetailRepository.productBuffer;
  }

  get status() {
    return this.productDetailRepository.status;
  }

  get exchangeRateEURUSD() {
    return this.productDetailRepository.exchangeRateEURUSD;
  }

  get autonomoMode() {
    return this.productDetailRepository.autonomoMode;
  }

  get priceBreaks() {
    if (!this.quote) {
      return null;
    }
    const priceBreaks = {
      manufacturing_price_breaks: this.quote.manufacturing_price_breaks.filter(
        (pb) => pb.is_deleted !== true
      ),
      shipping_price_breaks: this.quote.shipping_price_breaks.filter(
        (pb) => pb.is_deleted !== true
      ),
    };
    return priceBreaks;
  }

  get manufacturing_cost_calculated() {
    if (!this.quote || !this.productBuffer) {
      return null;
    }
    if (
      !this.productBuffer.quantity ||
      !this.productBuffer.chosen_incoterm ||
      !this.quote.manufacturing_cost_currency
    ) {
      return null;
    }
    if (!this.priceBreaks) {
      return null;
    }
    const manufacturing_cost_calculated = computePriceBreaks(
      this.priceBreaks.manufacturing_price_breaks,
      this.productBuffer?.quantity,
      this.productBuffer?.chosen_incoterm,
      this.quote?.manufacturing_cost_currency,
      this.productDetailRepository.exchangeRateEURUSD
    );
    return manufacturing_cost_calculated;
  }

  get shipping_cost_calculated() {
    if (!this.quote || !this.productBuffer) {
      return null;
    }
    if (
      !this.productBuffer.quantity ||
      !this.productBuffer.chosen_incoterm ||
      !this.quote.shipping_cost_currency
    ) {
      return null;
    }
    if (!this.priceBreaks) {
      return null;
    }

    const shipping_cost_calculated = computePriceBreaks(
      this.priceBreaks.shipping_price_breaks,
      this.productBuffer?.quantity,
      this.productBuffer?.chosen_incoterm,
      this.quote?.shipping_cost_currency,
      this.productDetailRepository.exchangeRateEURUSD
    );
    return shipping_cost_calculated;
  }

  get computedMargin() {
    let financialCalculus: FinCalculator;
    let missing: string[] = [];
    let result: number | null = null;
    let variables: Record<string, number> | null = null;

    if (!this.quote || !this.productBuffer) {
      return { missing, result, variables };
    }

    try {
      financialCalculus = new FinCalculator(this.exchangeRateEURUSD).init(
        this.quote,
        this.productBuffer,
        {
          manufacturing_cost_calculated: this.manufacturing_cost_calculated,
          shipping_cost_calculated: this.shipping_cost_calculated,
        }
      );
      result = financialCalculus.margin({ autonomo: this.autonomoMode });
      missing = [];
      variables = financialCalculus.formattedVariables();
      return { missing, result, variables };
    } catch (error) {
      if (error instanceof MissingFieldsError) {
        missing = error.missingFields;
        result = null;
        variables = null;
      } else {
        console.error("unexpected error calculating computedMargin ", error);
      }
      return { missing, result, variables };
    }
  }

  get computedROI() {
    let financialCalculus: FinCalculator;
    let missing: string[] = [];
    let result: number | null = null;
    let variables: Record<string, number> | null = null;

    try {
      financialCalculus = new FinCalculator(this.exchangeRateEURUSD).init(
        this.quote,
        this.productBuffer,
        {
          manufacturing_cost_calculated: this.manufacturing_cost_calculated,
          shipping_cost_calculated: this.shipping_cost_calculated,
        }
      );
      result = financialCalculus.roi({ autonomo: this.autonomoMode });
      missing = [];
      variables = financialCalculus.formattedVariables();
    } catch (error) {
      if (error instanceof MissingFieldsError) {
        missing = error.missingFields;
        result = null;
        variables = null;
      } else {
        console.error("unexpected error calculating computedROI ", error);
      }
    }
    return { missing, result, variables };
  }

  constructor() {
    super();
    makeObservable(this, {
      quoteId: observable,
      supplierDetails: observable,
      newPriceBreak: observable,
      editingPriceBreak: observable,
      quote: computed,
      status: computed,
      product: computed,
      productBuffer: computed,
      autonomoMode: computed,
      priceBreaks: computed,
      manufacturing_cost_calculated: computed,
      shipping_cost_calculated: computed,
      computedMargin: computed,
      computedROI: computed,
      initQuoteId: action,
      overrideFee: action,
      updateAmazonFee: action,
      updatePackagingProperty: action,
      updateImportFee: action,
      overrideCost: action,
      updateCost: action,
      updateCurrency: action,
      saveQuote: action,
      setProductBuffer: action,
      saveProduct: action,
      updateFees: action,
      editSupplier: action,
      addPriceBreak: action,
      setEditingPriceBreak: action,
      savePriceBreak: action,
      deletePriceBreak: action,
    });
  }

  initQuoteId = (
    quoteId: string,
    removeInstance: (quoteId: string) => void,
    setPreventReorder: (activated: boolean) => void
  ) => {
    if (!this.quoteId) {
      this.quoteId = quoteId;
    }
    if (this.quote?.created_at) {
      this.createdAtTimestamp = new Date(this.quote.created_at).getTime();
    }
    // check if the reference_fee_calculated is missing and the product has a quantity and incoterm
    // then update the fees
    if (
      !this.quote?.reference_fee_calculated &&
      this.product?.quantity &&
      this.product?.category
    ) {
      const newReferenceFee = this.quote?.fee_calculation?.reference_fee;
      if (newReferenceFee) {
        this.updateFees();
      }
    }
    // initialize the supplier details buffer
    if (this.quote && this.quote.supplier) {
      this.supplierDetails = {
        ...this.quote?.supplier,
      };
    }
    this.removeInstance = removeInstance;
    this.setPreventReorder = setPreventReorder;
    this.reactionDisposer = reaction(
      () => ({
        packaging_length: this.quote?.package_length,
        packaging_width: this.quote?.package_width,
        packaging_height: this.quote?.package_height,
        packaging_weight: this.quote?.package_weight,
      }),
      debounce(() => {
        this.updateFees();
      }, 500),
      { equals: comparer.structural }
    );
  };

  dispose = () => {
    if (this.reactionDisposer) {
      this.reactionDisposer();
    }
  };

  updateImportFee = (importFee: number | null) => {
    if (
      !this.productDetailRepository.product ||
      !this.productDetailRepository.product.quotes
    ) {
      return null;
    }
    if (this.quoteId) {
      const quote = this.productDetailRepository.product?.quotes.find(
        (quote) => quote.id === this.quoteId
      );
      if (!quote) {
        return;
      }
      quote.import_fees = importFee;
    }
  };

  overrideFee = (feeName: OverridableFees, value: boolean) => {
    if (
      !this.productDetailRepository.product ||
      !this.productDetailRepository.product.quotes
    ) {
      return null;
    }
    if (this.quoteId) {
      const quote = this.productDetailRepository.product?.quotes.find(
        (quote) => quote.id === this.quoteId
      );
      if (!quote) {
        return;
      }
      quote[feeName] = value;
    }
  };

  updateAmazonFee = (
    feeName:
      | "reference_fee"
      | "logistic_fee"
      | "storage_fee"
      | "storage_fee_xmas",
    amazonFee: number | null
  ) => {
    if (
      !this.productDetailRepository.product ||
      !this.productDetailRepository.product.quotes
    ) {
      return null;
    }
    if (this.quoteId) {
      const quote = this.productDetailRepository.product?.quotes.find(
        (quote) => quote.id === this.quoteId
      );
      if (!quote) {
        return;
      }
      quote[feeName] = amazonFee;
    }
  };

  overrideCost = (
    costName: "use_manufacturing_price_breaks" | "use_shipping_price_breaks",
    value: boolean
  ) => {
    if (
      !this.productDetailRepository.product ||
      !this.productDetailRepository.product.quotes
    ) {
      return null;
    }
    if (this.quoteId) {
      const quote = this.productDetailRepository.product?.quotes.find(
        (quote) => quote.id === this.quoteId
      );
      if (!quote) {
        return;
      }
      quote[costName] = value;
    }
  };

  updateCost = (
    costName: "manufacturing_cost" | "packaging_cost" | "shipping_cost",
    value: number | null
  ) => {
    if (
      !this.productDetailRepository.product ||
      !this.productDetailRepository.product.quotes
    ) {
      return null;
    }
    if (this.quoteId) {
      const quote = this.productDetailRepository.product?.quotes.find(
        (quote) => quote.id === this.quoteId
      );
      if (!quote) {
        return;
      }
      quote[costName] = value;
    }
  };

  updateCurrency = (
    costName: "manufacturing_cost_currency" | "shipping_cost_currency",
    value: Currencies | null
  ) => {
    if (
      !this.productDetailRepository.product ||
      !this.productDetailRepository.product.quotes
    ) {
      return null;
    }
    if (this.quoteId) {
      const quote = this.productDetailRepository.product?.quotes.find(
        (quote) => quote.id === this.quoteId
      );
      if (!quote) {
        return;
      }
      quote[costName] = value;
    }
  };

  updatePackagingProperty = (
    property:
      | "package_length"
      | "package_width"
      | "package_height"
      | "package_weight",
    value: number | null
  ) => {
    if (
      !this.productDetailRepository.product ||
      !this.productDetailRepository.product.quotes
    ) {
      return null;
    }
    if (this.quoteId) {
      const quote = this.productDetailRepository.product?.quotes.find(
        (quote) => quote.id === this.quoteId
      );
      if (!quote) {
        return;
      }
      quote[property] = value;
    }
  };

  updateIsImportFeePercentage = (isPercentage: boolean) => {
    if (
      !this.productDetailRepository.product ||
      !this.productDetailRepository.product.quotes
    ) {
      return null;
    }
    if (this.quoteId) {
      const quote = this.productDetailRepository.product?.quotes.find(
        (quote) => quote.id === this.quoteId
      );
      if (!quote) {
        return;
      }
      quote.import_fees_is_percentage = isPercentage;
    }
  };

  saveQuote = () => {
    if (!this.productDetailRepository.product) {
      return;
    }

    if (!this.quote) {
      return;
    }

    this.quote.margin = this.computedMargin.missing.length
      ? null
      : isNumber(this.computedMargin.result)
      ? this.computedMargin.result
      : null;
    this.quote.roi = this.computedROI.missing.length
      ? null
      : isNumber(this.computedROI.result)
      ? this.computedROI.result
      : null;

    this.productDetailRepository.saveQuote(this.quote);
  };

  setProductBuffer = (newTarget: Partial<Product>) => {
    const oldTarget = this.productDetailRepository.productBuffer as Product;
    this.productDetailRepository.productBuffer = { ...oldTarget, ...newTarget };
  };

  saveProduct = () => {
    if (!this.productDetailRepository.product) {
      return;
    }
    // TODO: fix unnecessarily passing the product to the update method
    this.productDetailRepository.update(this.productDetailRepository.product);
  };

  updateFees = async () => {
    if (!this.productDetailRepository.product) {
      return;
    }
    await this.productDetailRepository.updateFees();
  };

  removeQuote = async () => {
    if (!this.productDetailRepository.product) {
      return;
    }
    if (!this.quoteId) {
      return;
    }
    await this.productDetailRepository.removeQuote(this.quoteId);
    this.removeInstance(this.quoteId);
  };

  editSupplier = async () => {
    if (!this.supplierDetails) {
      return;
    }
    await this.productDetailRepository.updateSupplier(this.supplierDetails);
  };

  addPriceBreak = async (type: "Manufacturing" | "Shipping") => {
    if (!this.productDetailRepository.product) {
      return;
    }
    if (!this.quoteId) {
      return;
    }
    if (!this.newPriceBreak.price || !this.newPriceBreak.quantity) {
      return;
    }
    await this.productDetailRepository.addPriceBreak({
      ...this.newPriceBreak,
      type,
      quote_id: this.quoteId,
    });

    // reset newPriceBreak (keep currency and incoterm)
    this.newPriceBreak = {
      ...this.newPriceBreak,
      type: null,
      price: null,
      quantity: null,
      // incoterm: null,
      // currency: null,
    };
  };

  setEditingPriceBreak = (priceBreakId: string | null) => {
    if (!this.quote) {
      return null;
    }
    if (priceBreakId === null) {
      this.editingPriceBreak = {
        id: null,
        type: null,
        price: null,
        quantity: null,
        incoterm: null,
        currency: null,
      };
      return;
    }
    const priceBreak: PriceBreak | undefined = findPriceBreakById(
      priceBreakId,
      this
    );
    if (!priceBreak) {
      return;
    }
    this.editingPriceBreak = {
      id: priceBreak.id,
      type: priceBreak.type,
      price: priceBreak.price,
      quantity: priceBreak.quantity,
      incoterm: priceBreak.incoterm,
      currency: priceBreak.currency,
    };
  };

  savePriceBreak = async () => {
    if (!this.productDetailRepository.product) {
      return;
    }
    if (!this.quoteId) {
      return;
    }
    if (
      !this.editingPriceBreak.price ||
      !this.editingPriceBreak.quantity ||
      !this.editingPriceBreak.id
    ) {
      return;
    }

    const priceBreak: PriceBreak | undefined = findPriceBreakById(
      this.editingPriceBreak.id,
      this
    );
    if (!priceBreak) {
      return;
    }
    await this.productDetailRepository.savePriceBreak({
      ...priceBreak,
      ...this.editingPriceBreak,
    });

    // reset editingPriceBreak
    this.editingPriceBreak = {
      id: undefined,
      type: null,
      price: null,
      quantity: null,
      incoterm: null,
      currency: null,
    };
  };

  deletePriceBreak = async (
    type: "Manufacturing" | "Shipping",
    priceBreakId: string
  ) => {
    if (!this.productDetailRepository.product) {
      return;
    }
    if (!this.quoteId) {
      return;
    }
    await this.productDetailRepository.deletePriceBreak(
      type,
      priceBreakId,
      this.quoteId
    );
  };
}
