import React from "react";
import { observer } from "mobx-react";
import { withInjection } from "./Core/Providers/withInjection.tsx";
import { AppPresenter } from "./AppPresenter.ts";
import { Navigation } from "./Components/Navigator/Navigation.tsx";
import LoginRegister from "./Pages/LoginRegister/LoginRegister.tsx";
import NotFound from "./Pages/NotFound.tsx";
import { ProductList } from "./Pages/ProductList/ProductList.tsx";
import Logout from "./Components/Logout/Logout.tsx";
import ThemeSelector from "./Components/ThemeSelector/ThemeSelector.tsx";
import { useValidation } from "./Core/Providers/useValidation.tsx";
import { ProductDetail } from "./Pages/ProductDetail/ProductDetail.tsx";
import classes from "./App.module.css";
import ToastShelf from "./Core/Messages/Toasts/ToastShelf.tsx";
import { Impersonator } from "./Components/Impersonator/Impersonator.tsx";
import { useKeyShortCutWithModifier } from "./Core/hooks/useKeyShortCutWithModifier.tsx";
import { VerifyCallout } from "./Components/VerifyCallout/VerifyCallout.tsx";
import { TrialCallout } from "./Components/TrialCallout/TrialCallout.tsx";
import LocaleSwitcher from "./Components/LocaleSwitcher/LocaleSwitcher.tsx";

export const AppComp = observer(
  ({ presenter }: { presenter: AppPresenter }) => {
    const [, updateClientValidationMessages] = useValidation();

    const [showImpersonator, setShowImpersonator] = React.useState(false);

    const {
      userId,
      isAdmin,
      isVerified,
      email,
      isFreeUser: freeUser,
      daysLeft,
    } = presenter.userModel;

    useKeyShortCutWithModifier("KeyI", () => {
      setShowImpersonator((current) => !current);
    });

    const currentRoute = presenter.currentRoute;

    const onRouteChange = React.useCallback(async () => {
      updateClientValidationMessages([]);
    }, [updateClientValidationMessages]);

    React.useEffect(() => {
      if (!presenter.accessToken) {
        presenter.router.goToId("loginLink");
      }
    }, [presenter.accessToken, presenter.router]);

    const renderedComponents = [
      {
        id: "homeLink",
        component: <ProductList key="homePage" />,
      },
      {
        id: "productDetails",
        component: <ProductDetail key="productDetailPageInfo" />,
      },
      {
        id: "default",
        component: <NotFound key="notFound" fallback={presenter.locale.translate("NOT_FOUND")} />,
      },
    ];

    React.useEffect(() => {
      presenter.load(onRouteChange);
      presenter.addToast(presenter.locale.translate("WELCOME"), "info");
    }, [presenter, onRouteChange]);

    return (
      <div className={classes.container}>
        {["loginLink", "registerLink"].includes(
          currentRoute ? currentRoute : "",
        ) ? (
          <LoginRegister register={currentRoute === "registerLink"} />
        ) : (
          <div className={classes.appWrapper}>
            {!isVerified && <VerifyCallout email={email} />}
            {isVerified && freeUser && (
              <TrialCallout daysLeft={daysLeft} userId={userId || ""} presenter={presenter}/>
            )}
            <div className={classes.topActions}>
              <Navigation />
              <Logout />
              {isAdmin && showImpersonator && <Impersonator />}
              <ThemeSelector presenter={presenter} />
              <LocaleSwitcher presenter={presenter}/>
            </div>
            <div className={classes.mainContent}>
              <div className={classes.pageContent}>
                {renderedComponents.map((current) => {
                  return currentRoute === current.id && current.component;
                })}
              </div>
            </div>
          </div>
        )}
        <ToastShelf presenter={presenter} />
      </div>
    );
  },
);

export const App = withInjection({
  presenter: AppPresenter,
})(AppComp);
