import React from "react";
import { observer } from "mobx-react";
import { Flex, Text, TextArea, TextField, RadioGroup } from "@radix-ui/themes";
import { ProductDetailPresenter } from "../../../Products/ProductDetailPresenter";
import { SelectCategory } from "../../../Components/SelectCategory/SelectCategory";
import { SelectStatus } from "../../../Components/SelectStatus/SelectStatus";
import { categories, productStatuses } from "../../../Core/Types";
import { useValidation } from "../../../Core/Providers/useValidation";
import { MessagesComponent } from "../../../Core/Messages/MessagesComponent";
import { handleNullValue, validateNumber, toDecimals } from "../../../Core/utils";

const EditDialogComp: React.FC<{
  presenter: ProductDetailPresenter;
}> = ({ presenter }) => {
  const [, updateClientValidationMessages] = useValidation();
  const [initialized, setInitialized] = React.useState(false);
  type inputKeys = "name" | "url" | "status" | "notes";

  // general input change handler
  const handleInputChange = (e: React.BaseSyntheticEvent): void => {
    const name = e.target.name as inputKeys;
    const value = e.target.value;
    presenter.modifiedProduct[name] = value;
  };

  // utilities for handling radio inputs
  const boolToRadioValue = (value: boolean | null | undefined): string => {
    if (value === true) {
      return "1";
    } else if (value === false) {
      return "2";
    } else {
      return "3";
    }
  };

  const radioToBoolValue = (value: string): boolean | null => {
    if (value === "1") {
      return true;
    } else if (value === "2") {
      return false;
    } else {
      return null;
    }
  };

  const handleRadioChange = (value: string): void => {
    presenter.modifiedProduct.hazmat = radioToBoolValue(value);
  };

  // utilities for handling number inputs
  const handleNumberInputChange = (e: React.BaseSyntheticEvent): void => {
    const name = e.target.name as "pvp" | "quantity" | "vat_percentage" | "additional_cost" | "return_rate" | "marketing_rate";
    // turn empty string into null
    if (e.target.value === "") {
      presenter.modifiedProduct[name] = null;
      return;
    }
    const valid = validateNumber(e.target.name, e.target.value, updateClientValidationMessages);
    if (!valid) return;

    let decimals = 2
    if (name === "quantity") {
      decimals= 0
    }
    if (name === "vat_percentage" || name === "return_rate" || name === "marketing_rate") {
      decimals = 1
    }

    const value = toDecimals(e.target.value, decimals);
    presenter.modifiedProduct[name] = Number(value);
  };

  React.useEffect(() => {
    presenter.preloadProductDetails();
    setInitialized(true);

    return () => {};
  }, [presenter]);

  React.useEffect(() => {
    // on exit clear the validation messages
    return () => {
      updateClientValidationMessages([]);
    };
  }, [updateClientValidationMessages]);

  // make sure the data was loaded before rendering
  // if (!presenter?.viewModel) return null;
  // if (!presenter?.modifiedProduct.category) return null;
  if (!initialized) return null;
  return (
    <Flex direction="column" gap="3">
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {presenter.locale.translate("NAME")}
        </Text>
        <TextField.Root
          placeholder={presenter.locale.translate("PRODUCT_NAME_PLACEHOLDER")}
          value={presenter.modifiedProduct.name}
          name="name"
          onChange={handleInputChange}
        />
      </label>
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {presenter.locale.translate("PRODUCT_URL")}
        </Text>
        <TextField.Root
          placeholder={presenter.locale.translate("PRODUCT_URL_PLACEHOLDER")}
          value={presenter.modifiedProduct.url}
          onChange={handleInputChange}
          name="url"
        />
      </label>
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {`${presenter.locale.translate("PRODUCT_PRICE")} (€)`}
        </Text>
        <TextField.Root
          placeholder={presenter.locale.translate("PRODUCT_PRICE_PLACEHOLDER")}
          value={handleNullValue(presenter.modifiedProduct.pvp)}
          onChange={handleNumberInputChange}
          type="number"
          name="pvp"
        />
      </label>
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {presenter.locale.translate("VAT_PRCT")}
        </Text>
        <TextField.Root
          placeholder={presenter.locale.translate("VAT_PRCT_PLACEHOLDER")}
          value={handleNullValue(presenter.modifiedProduct.vat_percentage)}
          onChange={handleNumberInputChange}
          type="number"
          name="vat_percentage"
        />
      </label>
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {presenter.locale.translate("RETURN_RATE_PRCT")}
        </Text>
        <TextField.Root
          placeholder={presenter.locale.translate("RETURN_RATE_PRCT_PLACEHOLDER")}
          value={handleNullValue(presenter.modifiedProduct.return_rate)}
          onChange={handleNumberInputChange}
          type="number"
          name="return_rate"
        />
      </label>
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {presenter.locale.translate("MARKETING_RATE_PRCT")}
        </Text>
        <TextField.Root
          placeholder={presenter.locale.translate("MARKETING_RATE_PRCT_PLACEHOLDER")}
          value={handleNullValue(presenter.modifiedProduct.marketing_rate)}
          onChange={handleNumberInputChange}
          type="number"
          name="marketing_rate"
        />
      </label>
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {`${presenter.locale.translate("ADDED_COSTS")} (€)`}
        </Text>
        <TextField.Root
          placeholder={presenter.locale.translate("ADDED_COSTS_PLACEHOLDER")}
          value={handleNullValue(presenter.modifiedProduct.additional_cost)}
          onChange={handleNumberInputChange}
          type="number"
          name="additional_cost"
        />
      </label>
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {presenter.locale.translate("AMOUNT")}
        </Text>
        <TextField.Root
          placeholder={presenter.locale.translate("AMOUNT_PLACEHOLDER")}
          value={handleNullValue(presenter.modifiedProduct.quantity)}
          onChange={handleNumberInputChange}
          type="number"
          name="quantity"
        />
      </label>
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {presenter.locale.translate("CATEGORY")}
        </Text>
        <SelectCategory
          options={[...categories]}
          value={presenter?.modifiedProduct?.category || "Equipaje"}
          onChange={(value) => {
            presenter.modifiedProduct.category = value;
          }}
          presenter={presenter}
        />
      </label>
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {presenter.locale.translate("STATUS")}
        </Text>
        <SelectStatus
          options={[...productStatuses]}
          value={presenter?.modifiedProduct?.status || "Discarded"}
          onChange={(value) => {
            presenter.modifiedProduct.status = value;
          }}
        />
      </label>
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {presenter.locale.translate("HAZMAT")}
        </Text>
        <RadioGroup.Root
          value={boolToRadioValue(presenter.modifiedProduct.hazmat)}
          onValueChange={handleRadioChange}
        >
          <Flex gap="2" direction="column">
            <Text as="label" size="2">
              <Flex gap="2">
                <RadioGroup.Item value="1" /> {presenter.locale.translate("YES")}
              </Flex>
            </Text>
            <Text as="label" size="2">
              <Flex gap="2">
                <RadioGroup.Item value="2" /> {presenter.locale.translate("NO")}
              </Flex>
            </Text>
            <Text as="label" size="2">
              <Flex gap="2">
                <RadioGroup.Item value="3" /> {presenter.locale.translate("NOT_AVAILABLE")}
              </Flex>
            </Text>
          </Flex>
        </RadioGroup.Root>
      </label>
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {presenter.locale.translate("NOTES")}
        </Text>
        <TextArea
          placeholder="notas del producto"
          value={presenter.modifiedProduct.notes}
          onChange={handleInputChange}
          name="notes"
        />
      </label>
      <MessagesComponent />
    </Flex>
  );
};

export const EditDialog = observer(EditDialogComp);
