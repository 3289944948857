import { inject, injectable } from "inversify";
import { makeObservable, computed, observable, action } from "mobx";
import { Router } from "./Routing/Router";
import { UserModel } from "./Authentication/UserModel";

import { Types } from "./Core/Types";
import type IStorageGateway from "./Core/LocalStorage/IStorageGateway";
import { MessagesPresenter } from "./Core/Messages/MessagesPresenter";

@injectable() 
export class AppPresenter extends MessagesPresenter { // currently, this is a transient class
  @inject(Router)
  router: Router;

  @inject(UserModel)
  userModel: UserModel;

  @inject(Types.ILocalStorageGateway)
  localStorageGateway: IStorageGateway;

  get currentRoute() {
    return this.router.currentRoute.routeId;
  }

  get accessToken() {
    return this.userModel.token;
  }

  theme: string | null = null;

  constructor() {
    super()
    makeObservable(this, {
      theme: observable,
      currentRoute: computed,
      load: action,
      loadTheme: action,
      updateTheme: action,
    });
  }

  load = (onRouteChange: () => Promise<void>) => {
    const onRouteChangeWrapper: () => Promise<void> = async () => {
      this.clearServerMessages();
      this.dismissAllToasts();
      onRouteChange();
    };
    this.router.registerRoutes(onRouteChangeWrapper);
    this.loadTheme();
  };

  loadTheme = () => {
    this.theme = this.detectTheme();
    this.updateTheme(this.theme);
  };

  detectTheme = () => {
    // get browser theme
    const browserDefault = window.matchMedia("(prefers-color-scheme: dark)")
      .matches
      ? "dark"
      : "light";
    // get theme from local storage
    const storedTheme = this.localStorageGateway.get("theme");
    return storedTheme || browserDefault;
  };

  updateTheme = (desiredTheme: string) => {
    this.theme = desiredTheme;
    this.localStorageGateway.set("theme", desiredTheme);
    // first find out if the html has the class 'dark'
    const htmlClasses = document.documentElement.classList;
    const isDark = htmlClasses.contains("dark");

    if (desiredTheme === "dark" && !isDark) {
      // if theme is dark and html does not have class dark, add it
      htmlClasses.add("dark");
    }
    if (desiredTheme === "light" && isDark) {
      // if theme is light and html has class dark, remove it
      htmlClasses.remove("dark");
    }
  };
}
