import { injectable } from "inversify";
import { action, makeObservable, observable, computed } from "mobx";
import translate, { possibleKeys, possibleLanguages } from "../translations";

// check if we are in the browser, then get the browser language
const navigator = typeof window !== "undefined" ? window.navigator : null;
const browserLanguage = navigator ? navigator?.language.split("-")[0] as possibleLanguages : "en"

@injectable()
export class Locale {
  locale: possibleLanguages = browserLanguage;

  constructor() {
    makeObservable(this, {
      locale: observable,
      setLocale: action,
      currentLocale: computed,
    });
  }

  setLocale(locale: possibleLanguages) {
    this.locale = locale;
  }

  get currentLocale() {
    return this.locale;
  }

  translate = (key: possibleKeys) => {
    return translate(key, this.locale);
  };

  mapStrings = (missing: string[]) => {
    const translatedStrings = missing.map((missing) => {
      switch (missing) {
        case "pvp":
          return this.translate("PRODUCT_PRICE");
        case "packaging_cost":
          return this.translate("PACKAGING_COST");
        case "import_fees":
          return this.translate("IMPORT_FEES");
        case "shipping_cost":
          return this.translate("SHIPPING_COST");
        case "reference_fee":
          return this.translate("REFERENCE_FEE");
        case "logistic_fee":
          return this.translate("LOGISTIC_FEE");
        case "storage_fee_xmas":
          return this.translate("STORAGE_FEE_XMAS");
        case "manufacturing_cost":
          return this.translate("MANUFACTURING_COST");
        case "additional_cost":
          return this.translate("ADDED_COSTS");
        case "vat_total":
          return this.translate("VAT_TOTAL");
        case "unitary_benefit":
          return this.translate("UNITARY_BENEFIT");
        case "exchangeRate":
          return this.translate("EXCHANGE_RATE");
        case "return_rate":
          return this.translate("RETURN_RATE_TOTAL");
        case "marketing_rate":
          return this.translate("MARKETING_RATE_TOTAL");
        default:
          return missing;
      }
    });
    return translatedStrings;
  };

  translateCategory = (category: Categories): string => {

    const categoryTranslationMap = {
      "Accesorios para dispositivos de Amazon": this.translate("CAT_ACCESSORIES"),
      "Coche y Moto": this.translate("CAT_AUTOMOTIVE"),
      "Productos para bebé": this.translate("CAT_BABY"),
      "Mochilas y bolsos": this.translate("CAT_BAGS"),
      "Belleza, salud y cuidado personal": this.translate("CAT_BEAUTY"),
      "Cerveza, vino y licores": this.translate("CAT_ALCOHOL"),
      Libros: this.translate("CAT_BOOKS"),
      "Suministros de industria, empresa y ciencia":
        this.translate("CAT_INDUSTRIAL"),
      "Ropa y accesorios": this.translate("CAT_CLOTHING"),
      "Suministros comerciales de electricidad y energía":
        this.translate("CAT_ELECTRICITY"),
      "Electrodomésticos compactos": this.translate("CAT_SMALL_APPLIANCES"),
      Informática: this.translate("CAT_COMPUTERS"),
      Electrónica: this.translate("CAT_ELECTRONICS"),
      "Accesorios de ciclismo": this.translate("CAT_CYCLING"),
      "Material educativo": this.translate("CAT_EDUCATIONAL"),
      "Accesorios de electrónica": this.translate("CAT_ELECTRONICS_ACCESSORIES"),
      "Gafas y accesorios": this.translate("CAT_GLASSES"),
      Zapatos: this.translate("CAT_SHOES"),
      "Electrodomésticos de tamaño completo": this.translate("CAT_LARGE_APPLIANCES"),
      Mobiliario: this.translate("CAT_FURNITURE"),
      "Alimentación y bebidas": this.translate("CAT_GROCERY"),
      "Amazon Handmade": this.translate("CAT_AMAZON_HANDMADE"),
      "Hogar y cocina": this.translate("CAT_HOME_KITCHEN"),
      Joyería: this.translate("CAT_JEWELRY"),
      Jardín: this.translate("CAT_GARDENING"),
      Equipaje: this.translate("CAT_LUGGAGE"),
      Colchones: this.translate("CAT_MATTRESSES"),
      "Música, Video y DVD": this.translate("CAT_MEDIA"),
      "Instrumentos musicales y producción audiovisual": this.translate("CAT_MUSIC"),
      "Oficina y papelería": this.translate("CAT_OFFICE"),
      "Productos para mascotas": this.translate("CAT_PET_SUPPLIES"),
      Software: this.translate("CAT_SOFTWARE"),
      "Deportes y aire libre": this.translate("CAT_SPORTS"),
      Neumáticos: this.translate("CAT_TIRES"),
      "Bricolaje y herramientas": this.translate("CAT_TOOLS"),
      "Juguetes y Juegos": this.translate("CAT_TOYS"),
      "Videojuegos y accesorios para videojuegos": this.translate("CAT_VIDEO_GAMES"),
      "Consolas de videojuegos": this.translate("CAT_VIDEO_GAME_CONSOLES"),
      Relojes: this.translate("CAT_WATCHES"),
      Otros: this.translate("CAT_OTHER"),
    };

    const found = Object.keys(categoryTranslationMap).find(
      (key) => key === category,
    );
    if (found !== undefined) {
      return categoryTranslationMap[category];
    } else {
      return category;
    }
  };
}
