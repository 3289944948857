import { observer } from "mobx-react";
import { IconButton } from "@radix-ui/themes";
import { MoonIcon, SunIcon } from "@radix-ui/react-icons";
import { AppPresenter } from "../../AppPresenter";

const ThemeSelectorComp = observer(
  ({ presenter }: { presenter: AppPresenter }) => {
    const handleThemeChange = () => {
      presenter.updateTheme(presenter.theme === "dark" ? "light" : "dark");
    };

    return (
      <IconButton style={{ marginLeft: "1rem", cursor: "pointer" }} onClick={handleThemeChange}>
        {presenter.theme === null ? null : presenter.theme === "dark" ? (
          <MoonIcon width="1rem" height="1rem" />
        ) : (
          <SunIcon width="1rem" height="1rem" />
        )}
      </IconButton>
    );
  }
);

const ThemeSelector = ThemeSelectorComp;

export default ThemeSelector;
