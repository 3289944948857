import { observer } from "mobx-react";
import { QuotePresenter } from "../../../../Products/QuotePresenter";
import classes from "./PriceBreaks.module.css";
import { AddBreakInline } from "./AddBreakInline";
import { BreakInline } from "./BreakInline";

export const PriceBreaks: React.FC<{
  presenter: QuotePresenter;
  type: "Manufacturing" | "Shipping";
}> = observer(({ presenter, type }) => {
  if (!presenter.quote) {
    return <div>{presenter.locale.translate("NO_QUOTES")}</div>;
  }
  if (!presenter.priceBreaks) {
    return <div>{presenter.locale.translate("NO_PRICE_BREAKS")}</div>;
  }
  const priceBreaks =
    type === "Manufacturing"
      ? presenter.priceBreaks.manufacturing_price_breaks
      : presenter.priceBreaks.shipping_price_breaks;

  const sortedPriceBreaks = [...priceBreaks].sort((a, b) => {
    if (!a || !b) return 0;
    if (a.incoterm === b.incoterm) {
      if (!a.quantity || !b.quantity) return 0;
      return a.quantity - b.quantity;
    } else {
      if (!a.incoterm || !b.incoterm) return 0;
      return a.incoterm.localeCompare(b.incoterm);
    }
  });

  return (
    <div>
      <div className={classes.priceBreaksContainer}>
        <div className={classes.priceBreakHeader}>
          <div>{presenter.locale.translate("AMOUNT")}</div>
          <div>{presenter.locale.translate("PRICE")}</div>
          <div>{presenter.locale.translate("INCOTERM")}</div>
          <div></div>
        </div>
        {sortedPriceBreaks.map((priceBreak) => (
          <BreakInline
            key={priceBreak.id}
            presenter={presenter}
            type={type}
            priceBreak={priceBreak}
          />
        ))}
        {/* Row to add a new pricebreak */}
        <div className={classes.addBreakContainer}>
          <AddBreakInline presenter={presenter} type={type} priceBreak={null} />
        </div>
      </div>
    </div>
  );
});
