import React from "react"
import { observer } from "mobx-react-lite"
import { withInjection } from "../../../Core/Providers/withInjection"
import { ProductListPresenter } from "../../../Products/ProductListPresenter"
import * as Menubar from "@radix-ui/react-menubar"
import {
  CheckIcon,
  ChevronRightIcon,
  DotFilledIcon,
} from "@radix-ui/react-icons"
import "./styles.css"
import { SortColumn } from "../../../Products/ProductListRepository"

const FilterMenuComp: React.FC<{
  presenter: ProductListPresenter
}> = ({ presenter }) => {
  const sortCriteria: { value: SortColumn; label: string }[] = [
    { value: "none", label: presenter.locale.translate("NONE") },
    { value: "name", label: presenter.locale.translate("NAME") },
    { value: "pvp", label: presenter.locale.translate("PRODUCT_PRICE") },
    { value: "pvp_min", label: presenter.locale.translate("PRODUCT_MIN_PRICE") },
    { value: "category", label: presenter.locale.translate("CATEGORY") },
    { value: "status", label: presenter.locale.translate("STATUS") },
  ]

  return (
    <Menubar.Root className="MenubarRoot">
      <Menubar.Menu>
        <Menubar.Trigger className="MenubarTrigger">{presenter.locale.translate("FILTER")}</Menubar.Trigger>
        <Menubar.Portal>
          <Menubar.Content
            className="MenubarContent"
            align="start"
            sideOffset={5}
            alignOffset={-14}
          >
            <Menubar.Sub>
              <Menubar.SubTrigger className="MenubarSubTrigger">
                {presenter.locale.translate("CATEGORY")}
                <div className="RightSlot">
                  <ChevronRightIcon />
                </div>
              </Menubar.SubTrigger>

              <Menubar.Portal>
                <Menubar.SubContent
                  className="MenubarSubContent"
                  alignOffset={-5}
                >
                  {Object.entries(presenter.categoryFilters).map((item) => (
                    <Menubar.CheckboxItem
                      className="MenubarCheckboxItem inset"
                      key={item[0]}
                      checked={item[1]}
                      onCheckedChange={() => {
                        presenter.toggleFilter(
                          "category",
                          item[0] as Categories
                        )
                      }}
                    >
                      <Menubar.ItemIndicator className="MenubarItemIndicator">
                        <CheckIcon />
                      </Menubar.ItemIndicator>
                      {item ? presenter.locale.translateCategory(item[0] as Categories) : presenter.locale.translate("NO_CATEGORY")}
                    </Menubar.CheckboxItem>
                  ))}
                </Menubar.SubContent>
              </Menubar.Portal>
            </Menubar.Sub>
            <Menubar.Sub>
              <Menubar.SubTrigger className="MenubarSubTrigger">
                {presenter.locale.translate("STATUS")}
                <div className="RightSlot">
                  <ChevronRightIcon />
                </div>
              </Menubar.SubTrigger>

              <Menubar.Portal>
                <Menubar.SubContent
                  className="MenubarSubContent"
                  alignOffset={-5}
                >
                  {Object.entries(presenter.statusFilters).map((item) => (
                    <Menubar.CheckboxItem
                      className="MenubarCheckboxItem inset"
                      key={item[0]}
                      checked={item[1]}
                      onCheckedChange={() => {
                        presenter.toggleFilter("status", item[0] as Categories)
                      }}
                    >
                      <Menubar.ItemIndicator className="MenubarItemIndicator">
                        <CheckIcon />
                      </Menubar.ItemIndicator>
                      {item}
                    </Menubar.CheckboxItem>
                  ))}
                </Menubar.SubContent>
              </Menubar.Portal>
            </Menubar.Sub>
          </Menubar.Content>
        </Menubar.Portal>
      </Menubar.Menu>

      <Menubar.Menu>
        <Menubar.Trigger className="MenubarTrigger">
          {presenter.locale.translate("SORT_BY")}
        </Menubar.Trigger>
        <Menubar.Portal>
          <Menubar.Content
            className="MenubarContent"
            align="start"
            sideOffset={5}
            alignOffset={-14}
          >
            <Menubar.RadioGroup
              value={
                presenter.sorting.column === "none"
                  ? "none"
                  : presenter.sorting.column + " " + presenter.sorting.order
              }
              onValueChange={(val) => {
                presenter.sortBy(
                  val.split(" ")[0] as SortColumn,
                  val.split(" ")[1] as "asc" | "desc"
                )
              }}
            >
              {sortCriteria
                .filter((item) => item.value !== "none")
                .map((item) => (
                  <Menubar.Sub key={item.value}>
                    <Menubar.SubTrigger className="MenubarSubTrigger">
                      {item.label}
                      <div className="RightSlot">
                        <ChevronRightIcon />
                      </div>
                    </Menubar.SubTrigger>

                    <Menubar.Portal>
                      <Menubar.SubContent
                        className="MenubarSubContent"
                        alignOffset={-5}
                      >
                        <Menubar.RadioItem
                          className="MenubarRadioItem inset"
                          key={item.value + " asc"}
                          value={item.value + " asc"}
                        >
                          <Menubar.ItemIndicator className="MenubarItemIndicator">
                            <DotFilledIcon />
                          </Menubar.ItemIndicator>
                          {item.label + " " + presenter.locale.translate("ASCENDING")}
                        </Menubar.RadioItem>
                        <Menubar.RadioItem
                          className="MenubarRadioItem inset"
                          key={item.value + " desc"}
                          value={item.value + " desc"}
                        >
                          <Menubar.ItemIndicator className="MenubarItemIndicator">
                            <DotFilledIcon />
                          </Menubar.ItemIndicator>
                          {item.label + " " + presenter.locale.translate("DESCENDING")}
                        </Menubar.RadioItem>
                      </Menubar.SubContent>
                    </Menubar.Portal>
                  </Menubar.Sub>
                ))}
              <Menubar.RadioItem
                className="MenubarRadioItem inset"
                key={"none" + " asc"}
                value={"none" + " asc"}
              >
                <Menubar.ItemIndicator className="MenubarItemIndicator">
                  <DotFilledIcon />
                </Menubar.ItemIndicator>
                {presenter.locale.translate("NO_SORTING")}
              </Menubar.RadioItem>
            </Menubar.RadioGroup>
          </Menubar.Content>
        </Menubar.Portal>
      </Menubar.Menu>
    </Menubar.Root>
  )
}

export const FilterMenu = withInjection({
  presenter: ProductListPresenter,
})(observer(FilterMenuComp))
