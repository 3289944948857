import React from "react"
import { Text } from "@radix-ui/themes"
import classes from "./ProductInfo.module.css"

export const InfoRow: React.FC<{
  label: string
  value: string | number | undefined | null
  fallback: string 
}> = ({ label, value, fallback = "not available" }) => {
  const valueToShow = value === null || value === undefined ? fallback : value
  return (
    <div className={classes.infoRow}>
      <Text>{label}:</Text>  <Text size="3" weight="bold">{valueToShow}</Text>
    </div>
  )
}
