import { RadioCards, Flex, Text } from "@radix-ui/themes";

type SelectSupplierProps = {
  value: string | null;
  options: Supplier[];
  onChange: (value: string) => void;
};

export const SelectSupplier: React.FC<SelectSupplierProps> = ({
  onChange,
  value,
  options,
}) => {
  return (
    <>
      <RadioCards.Root
        onValueChange={onChange}
        value={value ? value : "none"}
        columns={{ initial: "1", sm: "3" }}
      >
        {options.map((option) => {
          return (
            <RadioCards.Item key={option.id} value={option.id}>
              <Flex direction="column" width="100%">
                <Text weight="bold">{option.contact_name}</Text>
                <Text>{option.email}</Text>
              </Flex>
            </RadioCards.Item>
          );
        })}
      </RadioCards.Root>
    </>
  );
};
