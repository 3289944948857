import sign from "jwt-encode";
import { jwtDecode, JwtPayload } from "jwt-decode";

interface MyJwtPayload extends JwtPayload {
  sub: string;
  props: {
    id: string;
    role: string;
    verified: boolean;
  };
  max_products: number;
  trial_ends: number;
}

export function parseJwt(token: string) {
  return jwtDecode<MyJwtPayload>(token);
}

const seed = "test-seed";
export function encodeJwt(data: object) {
  const jwt = sign(data, seed);
  return jwt;
}
