import { injectable, inject } from "inversify";
import { makeObservable, action } from "mobx";
import { Types } from "../Core/Types";
import { Router } from "../Routing/Router";
import { UserModel } from "../Authentication/UserModel";
import {
  MessagePacking,
  PackedMessage,
  ServerDto,
} from "../Core/Messages/MessagePacking";
import type { AuthGateway } from "../Core/AuthGateway";
import { parseJwt } from "./utils";

@injectable()
export class AuthenticationRepository {
  @inject(Router)
  router: Router;

  @inject(Types.IAuthGateway)
  authGateway: AuthGateway;

  @inject(UserModel)
  userModel: UserModel;

  constructor() {
    makeObservable(this, {
      login: action,
      register: action,
      logOut: action,
    });
  }

  login = async (
    email: string,
    password: string,
    callback: (loginPm: PackedMessage) => void,
  ) => {
    const loginDto = await this.authGateway.post("/auth/token", {
      usernameOrEmail: email,
      password: password,
    });

    // moddedLoginDto is a modified version of loginDto
    // we are only setting success to true if it is not defined
    const moddedLoginDto: ServerDto =
      loginDto.success === undefined
        ? {
            success: true,
            result: { message: "Successful Login" },
          }
        : {
            success: loginDto.success,
            result: { message: loginDto.result.message },
          };

    const loginPm = MessagePacking.unpackServerDtoToPm(moddedLoginDto);
    callback(loginPm);

    // currently if success is not defined then it is a success
    // we are only setting it when it is false
    if (loginDto.success === undefined) {
      this.userModel.store(
        email,
        loginDto.access_token,
        loginDto.refresh_token,
      );

      this.router.goToId("homeLink");

    }

    return loginDto;
  };

  register = async (
    email: string,
    password: string,
    callback: (registerPm: PackedMessage) => void,
  ) => {
    const registerDto = await this.authGateway.post("/users/", {
      usernameOrEmail: email,
      password: password,
    });

    const moddedRegisterDto: ServerDto =
      registerDto.success === undefined
        ? {
            success: true,
            result: { message: "Successful Registration" },
          }
        : {
            success: registerDto.success,
            result: { message: registerDto.result.message },
          };

    const registerPm = MessagePacking.unpackServerDtoToPm(moddedRegisterDto);
    callback(registerPm);
    return registerDto;
  };

  logOut = async () => {
    this.userModel.clear();
    this.router.goToId("loginLink");
  };

  impersonate = async (id: string) => {
    const impersonateDto = await this.authGateway.post(
      "/auth/impersonate/" + id,
      {},
    );

    if (impersonateDto.success === undefined) {
      const email = parseJwt(impersonateDto.access_token).sub;
      this.userModel.store(
        email, // empty because we are impersonating
        impersonateDto.access_token,
        impersonateDto.refresh_token,
      );
      this.router.goToId("homeLink");
    }

    // after impersonating, we need to reload the page
    window.location.reload();
  };

  resendVerificationEmail = async () => {
    const userId = this.userModel.userId;
    const res = await this.authGateway.get(
      "/users/resend-verification-email/" + userId,
    );
    return res;
  };
}
