import BigNumber from "bignumber.js";

// specific Error that carries an array of missing fields
export class MissingFieldsError extends Error {
  public missingFields: string[];
  constructor(missingFields: string[]) {
    super("missing fields: " + missingFields.join(", "));
    this.name = "MissingFieldsError";
    this.missingFields = missingFields;
  }
}

export class FinCalculator {
  private exchangeRateEURUSD: number;
  private precision: number;
  private recargoEquivRatio: number = 0.052; // constant for now
  private quote: Quote | null;
  private product: Product | null;
  private calculatedCosts: {
    manufacturing_cost_calculated: number | string | null;
    shipping_cost_calculated: number | string | null;
  };
  public isInitialized: boolean = false;

  public exchangeRate: BigNumber;
  public pvp: BigNumber;
  public manufacturing_cost: BigNumber;
  public assumedPackagingCost: number | undefined;
  public packaging_cost: BigNumber;
  public shipping_cost: BigNumber;
  public import_fees: BigNumber;
  public reference_fee: BigNumber;
  public logistic_fee: BigNumber;
  public storage_fee_xmas: BigNumber;
  public additional_cost: BigNumber;
  public vatRatio: BigNumber;
  public vat_total: BigNumber;

  public return_rate: BigNumber;
  public marketing_rate: BigNumber;

  constructor(exchangeRateEURUSD: number = 1.09, precision: number = 3) {
    if (exchangeRateEURUSD <= 0) {
      throw new Error("exchange rate must be greater than 0");
    }
    if (typeof exchangeRateEURUSD !== "number" || isNaN(exchangeRateEURUSD)) {
      throw new Error("exchange rate must be a number");
    }
    this.exchangeRateEURUSD = exchangeRateEURUSD;
    this.precision = precision;
  }

  public init(
    quote: Quote | null,
    product: Product | null,
    calculatedCosts: {
      manufacturing_cost_calculated: number | string | null;
      shipping_cost_calculated: number | string | null;
    },
  ) {
    this.quote = quote;
    this.product = product;
    this.calculatedCosts = calculatedCosts;

    if (!this.quote || !this.product) {
      throw new Error("quote and product are required");
    }

    if (this.missingFields().length > 0) {
      throw new MissingFieldsError(this.missingFields());
    }

    this.setVariables();

    this.isInitialized = true;
    return this;
  }

  /**
   * Check if all required fields are present
   * if not, return missing fields
   * @returns missing fields
   */
  private missingFields() {
    if (!this.quote && !this.product) {
      throw new Error("quote and product are required");
    }
    if (!this.quote) {
      throw new Error("quote is required");
    }
    if (!this.product) {
      throw new Error("product is required");
    }
    const missing: string[] = [];
    const checks = [
      { condition: !this.product.pvp, message: "pvp" },
      {
        condition:
          (this.quote.manufacturing_cost === null &&
            !this.quote.use_manufacturing_price_breaks) ||
          ((this.calculatedCosts.manufacturing_cost_calculated === null ||
            typeof this.calculatedCosts.manufacturing_cost_calculated ===
              "string") &&
            this.quote.use_manufacturing_price_breaks),
        message: "manufacturing_cost",
      },
      {
        condition:
          (this.quote.shipping_cost === null &&
            !this.quote.use_shipping_price_breaks) ||
          ((this.calculatedCosts.shipping_cost_calculated === null ||
            typeof this.calculatedCosts.shipping_cost_calculated ===
              "string") &&
            this.quote.use_shipping_price_breaks),
        message: "shipping_cost",
      },
      { condition: this.quote.import_fees === null, message: "import_fees" },
      {
        condition:
          (this.quote.reference_fee === null &&
            this.quote.reference_fee_overridden) ||
          (this.quote.reference_fee_calculated === null &&
            !this.quote.reference_fee_overridden),
        message: "reference_fee",
      },
      {
        condition:
          (this.quote.logistic_fee === null &&
            this.quote.logistic_fee_overridden) ||
          (this.quote.logistic_fee_calculated === null &&
            !this.quote.logistic_fee_overridden),
        message: "logistic_fee",
      },
      {
        condition:
          (this.quote.storage_fee_xmas === null &&
            this.quote.storage_fee_xmas_overridden) ||
          (this.quote.storage_fee_xmas_calculated === null &&
            !this.quote.storage_fee_xmas_overridden),
        message: "storage_fee_xmas",
      },
      {
        condition: this.product.vat_percentage === null,
        message: "vat_percentage",
      },
    ];

    checks.forEach((check) => {
      if (check.condition) missing.push(check.message);
    });

    return missing;
  }

  public checkComputable() {
    if (!this.isInitialized) {
      throw new Error("not initialized");
    }
    if (this.missingFields().length > 0) {
      throw new Error("missing fields: " + this.missingFields().join(", "));
    }
  }

  private setVariables() {
    this.exchangeRate = BigNumber(this.exchangeRateEURUSD);
    this.pvp = BigNumber(Number(this.product?.pvp));
    this.manufacturing_cost = this.quote?.use_manufacturing_price_breaks
      ? this.quote.manufacturing_cost_currency === "EUR"
        ? BigNumber(Number(this.calculatedCosts.manufacturing_cost_calculated))
        : BigNumber(
            Number(this.calculatedCosts.manufacturing_cost_calculated),
          ).dividedBy(this.exchangeRate)
      : this.quote?.manufacturing_cost_currency === "EUR"
      ? BigNumber(Number(this.quote.manufacturing_cost))
      : BigNumber(Number(this.quote?.manufacturing_cost)).dividedBy(
          this.exchangeRate,
        );
    this.assumedPackagingCost =
      this.quote?.packaging_cost === null ? 0 : this.quote?.packaging_cost;
    this.packaging_cost =
      this.quote?.manufacturing_cost_currency === "EUR"
        ? BigNumber(Number(this.assumedPackagingCost))
        : BigNumber(Number(this.assumedPackagingCost)).dividedBy(
            this.exchangeRate,
          );
    this.shipping_cost = this.quote?.use_shipping_price_breaks
      ? this.quote?.shipping_cost_currency === "EUR"
        ? BigNumber(Number(this.calculatedCosts.shipping_cost_calculated))
        : BigNumber(
            Number(this.calculatedCosts.shipping_cost_calculated),
          ).dividedBy(this.exchangeRate)
      : this.quote?.shipping_cost_currency === "EUR"
      ? BigNumber(Number(this.quote.shipping_cost))
      : BigNumber(Number(this.quote?.shipping_cost)).dividedBy(
          this.exchangeRate,
        );
    this.import_fees = this.quote?.import_fees_is_percentage
      ? this.manufacturing_cost
          .plus(this.packaging_cost)
          .plus(this.shipping_cost)
          .times(BigNumber(Number(this.quote?.import_fees)))
          .dividedBy(100)
      : BigNumber(Number(this.quote?.import_fees));
    this.reference_fee = this.quote?.reference_fee_overridden
      ? BigNumber(Number(this.quote?.reference_fee))
      : BigNumber(Number(this.quote?.reference_fee_calculated));
    this.logistic_fee = this.quote?.logistic_fee_overridden
      ? BigNumber(Number(this.quote?.logistic_fee))
      : BigNumber(Number(this.quote?.logistic_fee_calculated));
    this.storage_fee_xmas = this.quote?.storage_fee_xmas_overridden
      ? BigNumber(Number(this.quote?.storage_fee_xmas))
      : BigNumber(Number(this.quote?.storage_fee_xmas_calculated));
    this.additional_cost = BigNumber(
      Number(
        this.product?.additional_cost === null
          ? 0
          : this.product?.additional_cost,
      ),
    );
    this.return_rate = BigNumber(
      this.product?.return_rate ? this.product?.return_rate : 0,
    );
    this.marketing_rate = BigNumber(
      this.product?.marketing_rate ? this.product?.marketing_rate : 0,
    );
    this.vatRatio = BigNumber(Number(this.product?.vat_percentage)).dividedBy(
      BigNumber(100),
    );
    // vat total = pvp / (1 + vatRatio) * vatRatio due to the fact that VAT Percentage is already included in pvp
    this.vat_total = this.pvp
      .dividedBy(BigNumber(1).plus(this.vatRatio))
      .multipliedBy(this.vatRatio);

    return true;
  }

  public unitaryBenefit(
    options: {
      withReturnsAndMarketing?: boolean;
      asBigNumber?: boolean;
      autonomo?: boolean;
    } = {}, // to avoid reading properties of undefined
  ): BigNumber | number | null {
    // better default values than expected object
    if (options.asBigNumber === undefined) {
      options.asBigNumber = true;
    }
    if (options.withReturnsAndMarketing === undefined) {
      options.withReturnsAndMarketing = true;
    }
    if (options.autonomo === undefined) {
      options.autonomo = false;
    }
    //
    this.checkComputable();
    let unitaryBenefit;
    if (options.autonomo) {
      // amz costs
      const amazonCosts = this.reference_fee
        .plus(this.logistic_fee)
        .plus(this.storage_fee_xmas)
        .times(this.vatRatio.plus(1));
      // no amz costs
      const manuShippingCost = this.manufacturing_cost
        .plus(this.packaging_cost)
        .plus(this.shipping_cost)
        .plus(this.import_fees);
      const VatOfManuShipping = manuShippingCost.times(this.vatRatio);
      const recargoEquivalencia = manuShippingCost.times(
        this.recargoEquivRatio,
      );
      const generalCosts = manuShippingCost
        .plus(VatOfManuShipping)
        .plus(recargoEquivalencia)
        .plus(this.additional_cost);
      // unitary benefit
      unitaryBenefit = this.pvp.minus(amazonCosts).minus(generalCosts);
    } else {
      // amz costs
      const amazonCosts = this.reference_fee
        .plus(this.logistic_fee)
        .plus(this.storage_fee_xmas);
      // no amz costs
      const generalCosts = this.manufacturing_cost
        .plus(this.packaging_cost)
        .plus(this.shipping_cost)
        .plus(this.import_fees)
        .plus(this.additional_cost);
      // unitary benefit
      unitaryBenefit = this.pvp
        .minus(amazonCosts)
        .minus(generalCosts)
        .minus(this.vat_total);
    }

    if (options.withReturnsAndMarketing) {
      // returns and marketing are subtracted from the unitary benefit
      // irrespective of the value of options.autonomo
      unitaryBenefit = unitaryBenefit
        .minus(this.logistic_fee.multipliedBy(this.return_rate.dividedBy(100)))
        .minus(this.pvp.multipliedBy(this.marketing_rate.dividedBy(100)));
    }

    if (options.asBigNumber) {
      return unitaryBenefit;
    } else {
      return Number(unitaryBenefit.toPrecision(this.precision));
    }
  }

  public margin(
    options: { withReturnsAndMarketing?: boolean; autonomo?: boolean } = {},
  ) {
    if (options.withReturnsAndMarketing === undefined) {
      options.withReturnsAndMarketing = true;
    }
    if (!options.autonomo === undefined) {
      options.autonomo = false;
    }
    this.checkComputable();

    const unitaryBenefit = this.unitaryBenefit(options) as BigNumber;

    return !unitaryBenefit
      ? null
      : Number(unitaryBenefit.dividedBy(this.pvp).toPrecision(this.precision));
  }

  public roi(
    options: { withReturnsAndMarketing?: boolean; autonomo?: boolean } = {},
  ) {
    if (options.withReturnsAndMarketing === undefined) {
      options.withReturnsAndMarketing = true;
    }
    if (!options.autonomo === undefined) {
      options.autonomo = false;
    }
    this.checkComputable();

    const unitaryBenefit = this.unitaryBenefit(options) as BigNumber; // I'm sure it's a BigNumber

    let generalCosts;
    if (options.autonomo) {
      const manuShippingCost = this.manufacturing_cost
        .plus(this.packaging_cost)
        .plus(this.shipping_cost)
        .plus(this.import_fees);
      const VatOfManuShipping = manuShippingCost.times(this.vatRatio);
      const recargoEquivalencia = manuShippingCost.times(
        this.recargoEquivRatio,
      );
      generalCosts = manuShippingCost
        .plus(VatOfManuShipping)
        .plus(recargoEquivalencia)
        .plus(this.additional_cost);
    } else {
      generalCosts = this.manufacturing_cost
        .plus(this.packaging_cost)
        .plus(this.shipping_cost)
        .plus(this.import_fees)
        .plus(this.additional_cost);
    }

    return !unitaryBenefit
      ? null
      : Number(
          unitaryBenefit.dividedBy(generalCosts).toPrecision(this.precision),
        );
  }

  public formattedVariables(): Record<string, number> {
    this.checkComputable();
    this.setVariables();

    return {
      pvp: Number(this.pvp.toPrecision(this.precision + 1)),
      manufacturing_cost: Number(
        this.manufacturing_cost.toPrecision(this.precision + 1),
      ),
      packaging_cost: Number(
        this.packaging_cost.toPrecision(this.precision + 1),
      ),
      shipping_cost: Number(this.shipping_cost.toPrecision(this.precision + 1)),
      import_fees: Number(this.import_fees.toPrecision(this.precision + 1)),
      reference_fee: Number(this.reference_fee.toPrecision(this.precision + 1)),
      logistic_fee: Number(this.logistic_fee.toPrecision(this.precision + 1)),
      storage_fee_xmas: Number(
        this.storage_fee_xmas.toPrecision(this.precision + 1),
      ),
      additional_cost: Number(
        this.additional_cost.toPrecision(this.precision + 1),
      ),
      vat_total: Number(this.vat_total.toPrecision(this.precision + 1)),
      unitary_benefit: Number(
        this.unitaryBenefit({ asBigNumber: false })?.toPrecision(
          this.precision + 1,
        ),
      ),
      exchangeRate: Number(this.exchangeRate.toPrecision(this.precision + 2)),
      return_rate: Number(this.return_rate.toPrecision(this.precision + 1)),
      marketing_rate: Number(
        this.marketing_rate.toPrecision(this.precision + 1),
      ),
    };
  }
}

export function computePriceBreaks(
  priceBreaks: PriceBreak[],
  quantity: number,
  incoterm: Incoterms,
  currency: Currencies,
  exchangeRateEURUSD: number = 1.09,
) {
  if (!priceBreaks || priceBreaks.length === 0) {
    return null;
  }

  const priceBreaksCopy = [...priceBreaks];
  const filteredPriceBreaks = priceBreaksCopy.filter(
    (priceBreak) => priceBreak.incoterm === incoterm,
  );
  if (!filteredPriceBreaks || filteredPriceBreaks.length === 0) {
    return "no data " + incoterm;
  }
  const priceBreaksSameCurrency = filteredPriceBreaks.map((priceBreak) => {
    if (priceBreak.currency === currency) {
      return priceBreak;
    } else if (priceBreak.currency === "EUR") {
      return {
        ...priceBreak,
        price: Number(
          BigNumber(priceBreak.price as number)
            .multipliedBy(exchangeRateEURUSD)
            .toPrecision(4),
        ),
      };
    } else if (priceBreak.currency === "USD") {
      return {
        ...priceBreak,
        price: Number(
          BigNumber(priceBreak.price as number)
            .dividedBy(exchangeRateEURUSD)
            .toPrecision(4),
        ),
      };
    }
  });
  if (priceBreaksSameCurrency.length === 0) {
    return null;
  }
  const sortedPriceBreaks = [...priceBreaksSameCurrency].sort((a, b) => {
    if (!a || !b) {
      return 0;
    }
    if (a.quantity && b.quantity) {
      return b.quantity - a.quantity;
    }
    return 0;
  });
  const validPriceBreak = sortedPriceBreaks.find((priceBreak) => {
    if (!priceBreak) {
      return false;
    }
    return priceBreak.quantity && priceBreak.quantity <= quantity;
  });

  if (!validPriceBreak && sortedPriceBreaks.length > 0) {
    return "pocas unidades";
  }

  return validPriceBreak ? validPriceBreak.price : null;
}
