import { Select, Text } from "@radix-ui/themes";
import { possibleKeys } from "../../../../translations";

type SelectProductProps = {
  value: string | null;
  options: Product[];
  onChange: (value: string) => void;
  translate: (key: possibleKeys) => string; 
};

export const ProductFilterSelect: React.FC<SelectProductProps> = ({
  onChange,
  value,
  options,
  translate
}) => {
  return (
    <Select.Root value={value ? value : "none"} onValueChange={onChange}>
        <Text as="div" size="2" mb="1">{translate("FILTER_BY_PRODUCT")}</Text>
      <Select.Trigger />
      <Text as="div" size="2" mb="1" weight="bold">
        </Text>
      <Select.Content>
        <Select.Group>
          {(
            <Select.Item key="none" value={"none"}>
                {translate("ALL")}
            </Select.Item>
          )}
          {options.map((option) => {
            return (
              <Select.Item key={option.id} value={option.id || "none"}>
                {option.name}
              </Select.Item>
            );
          })}
        </Select.Group>
      </Select.Content>
    </Select.Root>
  );
};
