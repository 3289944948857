export const handleNullValue = (
  value: number | null | undefined,
  defaultValue = ""
): number | string => {
  if (value === null || value === undefined) {
    return defaultValue
  } else {
    return value
  }
}

export function isNumber(value: number | string | null | undefined) {
  return typeof value === "number" && !isNaN(value)
}

export function validateNumber(
  name: string,
  value: number | string | null | undefined,
  updateClientValidationMessages: (messages: string[]) => void
) {
  const maxNumber = 1000000000
  const formattedMaxNumber = new Intl.NumberFormat("es-ES").format(maxNumber)
  if (value === null || value === undefined) {
    updateClientValidationMessages([])
    return true
  }
  if (Number(value) < 0) {
    updateClientValidationMessages([
      "El valor de " + mapNamepropToName(name) + " debe ser positivo",
    ])
    return false
  }
  if (Number(value) > maxNumber) {
    updateClientValidationMessages([
      "El valor de " +
        mapNamepropToName(name) +
        " no puede ser superior a " +
        formattedMaxNumber,
    ])
    return false
  }
  updateClientValidationMessages([])
  return true
}

export function mapNamepropToName(name: string, lang = "es") {
  if (lang === "es") {
    switch (name) {
      case "name":
        return "Nombre"
      case "pvp":
        return "Precio de Venta"
      case "pvp_min":
        return "Precio de Venta Mínimo"
      case "vat_percentage":
        return "IVA %"
      case "url":
        return "URL"
      case "quantity":
        return "Cantidad"
      case "additional_cost":
        return "Costes Adicionales"
      case "import_fee":
        return "Aranceles"
      case "logistic_fee":
        return "Tarifa Logística"
      case "reference_fee":
        return "Tarifa de Referencia"
      case "storage_fee_xmas":
        return "Tarifa de Almacenamiento"
      case "package_length":
        return "Longitud del Paquete"
      case "package_width":
        return "Anchura del Paquete"
      case "package_height":
        return "Altura del Paquete"
      case "package_weight":
        return "Peso del Paquete"
      default:
        return ""
    }
  }
}

export function toDecimals(value: number | string, decimals: number = 2) {
  if (typeof value === "number"){
    value = String(value)
  }
  const [integer, decimal] = value.split(".");
  if (decimal) {
    value = integer + "." + decimal.slice(0, decimals);
  } 
  return Number(value);
}
