import { Select } from "@radix-ui/themes";

type SelectIncoTermProps = {
  value: Incoterms | null;
  options: Incoterms[];
  onChange: (value: Incoterms) => void;
  disabled?: boolean;
  fallback: string;
};

export const SelectIncoterm: React.FC<SelectIncoTermProps> = ({
  onChange,
  value,
  options,
  disabled = false,
  fallback = "not available",
}) => {
  return (
    <Select.Root
      value={value === null ? "none" : value}
      onValueChange={onChange}
      disabled={disabled}
    >
      <Select.Trigger />
      <Select.Content>
        <Select.Group>
          {value === null && (
            <Select.Item key="none" value={"none"}>
              {fallback}
            </Select.Item>
          )}
          {options.map((option) => {
            return (
              <Select.Item key={option} value={option}>
                {option}
              </Select.Item>
            );
          })}
        </Select.Group>
      </Select.Content>
    </Select.Root>
  );
};
