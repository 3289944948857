import { injectable, inject } from "inversify";
import { makeObservable, observable, action, computed } from "mobx";
import { Locale } from "../Locale";
import { MessagesRepository } from "./MessagesRepository";
import { PackedMessage } from "./MessagePacking";

@injectable()
export class MessagesPresenter {
  @inject(Locale)
  locale: Locale;

  @inject(MessagesRepository)
  messagesRepository: MessagesRepository;

  showValidationWarning: boolean | null = null;

  get messages() {
    return this.messagesRepository.appMessages;
  }

  get toastMessages() {
    return this.messagesRepository.toastMessages;
  }

  get currentLocale() {
    return this.locale.currentLocale;
  }

  constructor() {
    makeObservable(this, {
      showValidationWarning: observable,
      messages: computed,
      toastMessages: computed,
      init: action,
      unpackRepositoryPmToVm: action,
      clearServerMessages: action,
      addToast: action,
      removeToast: action,
      closeToast: action,
      dismissAllToasts: action,
    });
  }

  init = () => {
    this.showValidationWarning = false;
  };

  unpackRepositoryPmToVm = (pm: PackedMessage, userMessage: string) => {
    this.showValidationWarning = !pm.success;
    this.messagesRepository.appMessages = pm.success
      ? [{ message: userMessage, success: true }]
      : [{ message: pm.serverMessage, success: false }];
  };

  clearServerMessages = () => {
    this.messagesRepository.appMessages = [];
  };

  addToast = (message: string, type: "success" | "error" | "info" | "warning") => {
    this.messagesRepository.addToast(message, type);
  }

  // straight up removes
  removeToast = (id: string) => {
    this.messagesRepository.removeToast(id);
  }

  // closes with animation and delay
  closeToast = (id: string) => {
    this.messagesRepository.closeToast(id);
  }

  dismissAllToasts = () => {
    this.messagesRepository.dismissAllToasts();
  }

}
