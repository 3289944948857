import { Select } from "@radix-ui/themes";

type SelectCurrencyProps = {
  value: Currencies | null;
  options: Currencies[];
  onChange: (value: Currencies) => void;
  compact?: boolean;
  disabled?: boolean;
  fallback: string
};

export const SelectCurrency: React.FC<SelectCurrencyProps> = ({
  onChange,
  value,
  options,
  compact,
  disabled = false,
  fallback = "no currency"
}) => {
  return (
    <Select.Root
      value={value ? value : "none"}
      onValueChange={onChange}
      disabled={disabled}
    >
      <Select.Trigger />
      <Select.Content>
        <Select.Group>
          {value === null && (
            <Select.Item key="none" value={"none"}>
              {fallback}
            </Select.Item>
          )}
          {options.map((option) => {
            return (
              <Select.Item key={option} value={option}>
                {compact
                  ? option === "EUR" || option === "USD"
                    ? option === "EUR"
                      ? "€"
                      : "$"
                    : option
                  : option}
              </Select.Item>
            );
          })}
        </Select.Group>
      </Select.Content>
    </Select.Root>
  );
};
