import { injectable, inject } from "inversify";
import { action, makeObservable, observable, computed } from "mobx";
import { Types } from "../Core/Types";
import type IStorageGateway from "../Core/LocalStorage/IStorageGateway";
import { parseJwt } from "./utils";

@injectable()
export class UserModel {
  email: string | null = null;

  token: string | null = null;

  refreshToken: string | null = null;

  localStorageGateway: IStorageGateway;

  get tokenData() {
    return this.token
      ? parseJwt(this.token)
      : {
          props: {
            id: null,
            role: null,
            verified: null,
          },
          max_products: null,
          trial_ends: new Date().getTime() / 1000,
        };
  }

  get userId() {
    return this.tokenData?.props?.id;
  }

  get isAdmin() {
    return this.tokenData?.props?.role === "Admin";
  }

  get isVerified() {
    return this.tokenData?.props?.verified;
  }

  get isFreeUser() {
    return this.tokenData?.props?.role === "Free User";
  }

  get trialEnd() {
    return new Date(this.tokenData?.trial_ends * 1000);
  }

  get daysLeft() {
    return Math.floor(
      (this.trialEnd.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24),
    );
  }

  get maxProducts() {
    return this.tokenData?.max_products;
  }

  constructor(
    @inject(Types.ILocalStorageGateway) localStorageGateway: IStorageGateway,
  ) {
    // get token from local storage
    this.email = localStorageGateway.get("email");
    this.token = localStorageGateway.get("token");
    this.refreshToken = localStorageGateway.get("refreshToken");

    makeObservable(this, {
      email: observable,
      token: observable,
      refreshToken: observable,
      tokenData: computed,
      userId: computed,
      isAdmin: computed,
      isVerified: computed,
      isFreeUser: computed,
      trialEnd: computed,
      daysLeft: computed,
      maxProducts: computed,
      store: action,
      clear: action,
    });
    this.localStorageGateway = localStorageGateway;
  }

  store = (email: string, token: string, refreshToken: string) => {
    this.email = email;
    this.token = token;
    this.refreshToken = refreshToken;
    this.localStorageGateway.set("email", email);
    this.localStorageGateway.set("token", token);
    this.localStorageGateway.set("refreshToken", refreshToken);
  };

  clear = () => {
    this.email = null;
    this.token = null;
    this.refreshToken = null;
    this.localStorageGateway.remove("email");
    this.localStorageGateway.remove("token");
    this.localStorageGateway.remove("refreshToken");
    this.localStorageGateway.remove("userInfo"); // leaving this to clear out old versions
  };
}
