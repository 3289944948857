import React from "react";
import { observer } from "mobx-react";
import { QuoteListPresenter } from "../../../../Products/QuoteListPresenter";
import { Badge, Flex, Text, TextField, Switch, Spinner } from "@radix-ui/themes";
import { SelectIncoterm } from "./SelectIncoterm";
import { handleNullValue, toDecimals } from "../../../../Core/utils";
import classes from "./TopSection.module.css";
import { incoterms } from "../../../../Core/Types";
import { ButtonDialog } from "../../../../Components/DialogButton/DialogButton";
import { AddQuote } from "../AddQuote/AddQuote";
import { useValidation } from "../../../../Core/Providers/useValidation";
import { MessagesComponent } from "../../../../Core/Messages/MessagesComponent";
import { validateNumber } from "../../../../Core/utils";
import { PlusCircle } from "react-feather";
import { InfoHoverCard } from "../../../../Components/InfoTooltip/InfoTooltip";

const TopSectionComp: React.FC<{
  presenter: QuoteListPresenter;
}> = ({ presenter }) => {
  const [, updateClientValidationMessages] = useValidation();

  const handleNumberInputChange = (e: React.BaseSyntheticEvent): void => {
    if (!presenter.productBuffer) {
      return;
    }

    const valid = validateNumber(
      e.target.name,
      e.target.value,
      updateClientValidationMessages,
    );
    if (!valid) return;

    const name = e.target.name as "pvp" | "quantity";
    const target = presenter.productBuffer;
    // turn empty string into null
    if (e.target.value === "") {
      presenter.productBuffer[name] = null;
      return;
    }
    const value = toDecimals(e.target.value);
    target[name] = Number(value);
    presenter.setProductBuffer(target);
  };

  const handleQuantityChange = (e: React.BaseSyntheticEvent): void => {
    if (!presenter.productBuffer) {
      return;
    }
    const valid = validateNumber(
      e.target.name,
      e.target.value,
      updateClientValidationMessages,
    );
    if (!valid) return;
    // turn empty string into null
    if (e.target.value === "") {
      presenter.productBuffer.quantity = null;
      return;
    }
    const value = toDecimals(e.target.value, 0);
    const newValues = {
      quantity: Number(value),
    };
    // need to set the buffer and save it
    // TODO: refactor the whole product buffer thing
    presenter.setProductBuffer(newValues);
    presenter.saveProductBuffer();
  };

  const handleIncotermChange = (value: Incoterms): void => {
    if (!presenter.productBuffer) {
      return;
    }
    const newValues = {
      chosen_incoterm: value,
    };
    presenter.setProductBufferAndSave(newValues);
  };

  React.useEffect(() => {
    return () => {
      updateClientValidationMessages([]);
    };
  }, [updateClientValidationMessages]);

  if (!presenter.productBuffer) {
    return <div>No target found</div>;
  }

  return (
    <>
      <div className={classes.wrapper}>
        <fieldset className={classes.productFieldSet}>
          <legend>{presenter.locale.translate("CALCULATION_PARAMS")}</legend>
          <label>
            <Text>{presenter.locale.translate("PRODUCT_PRICE")}</Text>
            <TextField.Root
              value={handleNullValue(presenter.productBuffer.pvp, "")}
              onChange={handleNumberInputChange}
              type="number"
              name="pvp"
              disabled={presenter.status === "LOADING"}
            />
          </label>
          <label>
            <Text>{presenter.locale.translate("AMOUNT")}</Text>
            <TextField.Root
              value={handleNullValue(presenter.productBuffer.quantity, "")}
              onChange={handleQuantityChange}
              type="number"
              name="quantity"
              disabled={presenter.status === "LOADING"}
            />
          </label>
          <label>
            <Text>{presenter.locale.translate("INCOTERM")}</Text>
            <div>
              <SelectIncoterm
                options={[...incoterms]}
                value={presenter.productBuffer.chosen_incoterm || null}
                onChange={handleIncotermChange}
                fallback={presenter.locale.translate("NO_INCOTERM")}
              />
            </div>
          </label>
          <label style={{ margin: "0 1rem 0 0.5rem" }}>
            <InfoHoverCard
              title={presenter.locale.translate("CALCULATION_MODE")}
              content={
                <>
                {presenter.locale.translate("CALCULATION_MODE_TOOLTIP")}
                </>
              }
            />
            <Flex
              justify={"between"}
              align={"center"}
              style={{ minHeight: "32px" }}
            >
              <Switch
                onCheckedChange={() => {
                  presenter.switchAutonomoMode();
                }}
                checked={presenter.autonomoMode}
              />
              {presenter.autonomoMode ? presenter.locale.translate("FREELANCER") : presenter.locale.translate("COMPANY")}
            </Flex>
          </label>
        </fieldset>
        <Flex justify={"center"} align={"center"}>
          <MessagesComponent />
        </Flex>
        { presenter.status === "LOADING" && <Flex justify={"center"} align={"center"}>
          <Spinner size="3" loading={true}>Editable</Spinner>
        </Flex>}
        <Badge style={{ marginLeft: "auto" }} color="green">
          {"EUR/USD " + presenter.exchangeRate.toFixed(4)}
        </Badge>
        <ButtonDialog
          title="Añadir cotización"
          description="Presione Añadir para guardar la cotización"
          cancelText="Cancelar"
          confirmText="Añadir"
          onConfirm={() => {
            presenter.addQuote();
          }}
          body={<AddQuote presenter={presenter} />}
          disabled={
            // conditions for the 3 cases where the button should be disabled
            (!presenter.newSupplier.contact_name &&
              presenter.newSupplierMenuOpen) ||
            (!presenter.newQuote.supplier_id &&
              !presenter.newSupplierMenuOpen &&
              !!presenter.availableSuppliers.length) ||
            (!presenter.availableSuppliers.length &&
              !presenter.newSupplier.contact_name)
          }
        >
          {" "}
          <PlusCircle width="1rem" height="1rem" />
          {presenter.locale.translate("ADD_QUOTE")}
        </ButtonDialog>
      </div>
    </>
  );
};

export const TopSection = observer(TopSectionComp);
