import { SimpleList } from "./SimpleList/SimpleList";
import { TopActions } from "./TopActons/TopActions";

export const ProductList: React.FC = () => {
  return (
    <div>
      <TopActions />
      <SimpleList />
    </div>
  );
};
