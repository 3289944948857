import { Select } from "@radix-ui/themes";
import { MessagesPresenter } from "../../Core/Messages/MessagesPresenter";

type SelectCategoryProps<T> = {
  value: T;
  options: T[];
  onChange: (value: T) => void;
  sorted?: boolean;
  disabled?: boolean;
  presenter: MessagesPresenter
};

export const SelectCategory: React.FC<SelectCategoryProps<Categories>> = ({
  onChange,
  value,
  options,
  sorted = true,
  disabled = false,
  presenter
}) => {
  if (!value) return null;
  const sortedOptions = sorted
    ? [...options].sort((a, b) => a.localeCompare(b))
    : options;
  return (
    <Select.Root disabled={disabled} value={value} onValueChange={onChange}>
      <Select.Trigger />
      <Select.Content>
        <Select.Group>
          {sortedOptions.map((option) => {
            return (
              <Select.Item key={option} value={option}>
                {presenter.locale.translateCategory(option)}
              </Select.Item>
            );
          })}
        </Select.Group>
      </Select.Content>
    </Select.Root>
  );
};
