import wretch, { Wretch } from "wretch";
import { WretchError } from "wretch/resolver";
import FormDataAddon from "wretch/addons/formData";
import { injectable, inject } from "inversify";
import { Config } from "./Config";
import { UserModel } from "../Authentication/UserModel";
import IDataGateway from "./IDataGateway";
import { Locale } from "./Locale";

export type AuthResponseDto =
  | {
      success: undefined;
      access_token: string;
      token_type: "bearer";
      refresh_token: string;
    }
  | {
      success: false;
      result: { message: string };
    };
@injectable()
export class AuthGateway implements IDataGateway {
  @inject(Config)
  config: Config;

  @inject(Locale)
  locale: Locale;

  @inject(UserModel)
  userModel: UserModel;

  authFetcher: Wretch = wretch()
    .catcher(403, (error) => {
      console.error("forbidden", error.status);
      return {
        success: false,
        result: {
          message: this.locale.translate("FORBIDDEN"),
        },
      };
    })
    .catcher(409, (error) => {
      console.error("conflict", error.status);
      return {
        success: false,
        result: {
          message: this.locale.translate("CONFLICT"),
        },
      };
    })
    .catcher(422, (error) => {
      console.error("unprocessable entity", error.status);
      return {
        success: false,
        result: {
          message:
            this.locale.translate("VALIDATION_ERROR"),
        },
      };
    })
    .catcher(500, (error) => {
      console.error("server error", error.status);
      return {
        success: false,
        result: {
          message: this.locale.translate("SERVER_ERROR"),
        },
      };
    })
    .catcherFallback((error) => {
      console.error("unexpected error fetching", error);
      return {
        success: false,
        result: {
          message: this.locale.translate("ERROR") + " " + error.status,
        },
      };
    });

  postFetcher = wretch()
    .addon(FormDataAddon)
    .catcher(401, (error) => {
      console.error("wrong credentials", error.status);
      return {
        success: false,
        result: {
          message: this.locale.translate("WRONG_CREDENTIALS"),
        },
      };
    })
    .catcher(404, (error) => {
      console.error("not found", error.status);
      return {
        success: false,
        result: {
          message: this.locale.translate("USER_NOT_FOUND"),
        },
      };
    })
    .catcher(422, (error) => {
      console.error("unprocessable entity", error.status);
      return {
        success: false,
        result: {
          message:
            this.locale.translate("VALIDATION_ERROR"),
        },
      };
    })
    .catcher(500, (error) => {
      console.error("server error", error.status);
      return {
        success: false,
        result: {
          message: this.locale.translate("SERVER_ERROR"),
        },
      };
    })
    .catcherFallback((error) => {
      console.error("error fetching @ AuthGateway.post", error);
      return {
        success: false,
        result: {
          message:
            this.locale.translate("ERROR") + "" +
            (error instanceof WretchError ? error.status : error.message),
        },
      };
    });

  get = async (path: string): Promise<object> => {
    const dto: object = await this.authFetcher
      .url(this.config.authApiUrl)
      .url(path)
      .auth(`Bearer ${this.userModel.token}`)
      .get()
      .json();

    return dto;
  };

  post = async (
    path: string,
    requestDto: { usernameOrEmail?: string; password?: string }
  ): Promise<AuthResponseDto> => {
    // if path includes /auth/impersonate, we are impersonating a user
    if (path.includes("/auth/impersonate")) {
      return await this.authFetcher
        .url(this.config.authApiUrl)
        .url(path)
        .auth(`Bearer ${this.userModel.token}`)
        .post()
        .json();
    }

    // else we are either logging in or registering
    const { usernameOrEmail, password } = requestDto;

    let formData;
    if (path === "/auth/token") {
      // case login
      formData = new FormData();
      formData.append("username", usernameOrEmail ? usernameOrEmail : "");
      formData.append("password", password ? password : "");
    }
    const response: AuthResponseDto =
      path === "/auth/token"
        ? await this.postFetcher
            .url(this.config.authApiUrl)
            .url(path)
            .formData({
              username: usernameOrEmail,
              password: password,
            })
            .post()
            .json()
        : await this.authFetcher
            .url(this.config.authApiUrl)
            .url(path)
            .post({
              email: usernameOrEmail,
              password: password,
              is_active: true,
              role: "Free User",
            })
            .json();
    return response;
  };

  delete = async (path: string) => {
    const dto: object = await this.authFetcher
      .url(this.config.authApiUrl)
      .url(path)
      .auth(`Bearer ${this.userModel.token}`)
      .delete()
      .json();
    return dto;
  };
}
