import React from "react";
import { observer } from "mobx-react";
import { withInjection } from "../../Core/Providers/withInjection";
import { AuthenticationPresenter } from "../../Authentication/AuthenticationPresenter";
import { Popover, Text, TextArea } from "@radix-ui/themes";
import { Button } from "../Button/Button";

export const ImpersonatorComp: React.FC<{ presenter: AuthenticationPresenter }> = ({
  presenter,
}) => {
    const [userId, setUserId] = React.useState<string>('');
    const handleImpersonate = (e: React.BaseSyntheticEvent): void => {
        e.preventDefault();
        if (!userId) return;
        presenter.impersonate(userId);
    }
  return <Popover.Root>
    <Popover.Trigger>
      <Button style={{marginLeft: "1rem"}}>Impersonate</Button>
    </Popover.Trigger>
    <Popover.Content sideOffset={10}>
      <form onSubmit={handleImpersonate}>
            <label>
                <Text as="div" size="2" mb="1" weight="bold">Id de Usuario</Text>
                <TextArea 
                    placeholder="id de usuario"
                    onChange={(e) => setUserId(e.target.value)}
                /> 
            </label>
            <Button type="submit" mt="3">Impersonate</Button>
      </form>
    </Popover.Content>
  </Popover.Root>;
};

export const Impersonator = withInjection(({
    presenter: AuthenticationPresenter
}))(observer(ImpersonatorComp))