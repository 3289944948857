import { injectable, inject } from "inversify";
import { makeObservable, observable, action, computed } from "mobx";
import { AuthenticationRepository } from "./AuthenticationRepository";
import { MessagesPresenter } from "../Core/Messages/MessagesPresenter";
import { PackedMessage } from "../Core/Messages/MessagePacking";

@injectable()
export class AuthenticationPresenter extends MessagesPresenter {
  @inject(AuthenticationRepository)
  authenticationRepository: AuthenticationRepository;

  email: string | null = null;
  password: string | null = null;
  option: string | null = null;
  acceptedTerms: boolean = false;

  get title() {
    if (!this.option) {
      return "uninitialized";
    }
    return this.option === "login" ? this.locale.translate("WELCOME_SHORT") : this.locale.translate("REGISTER");
  }

  get switchButtonTitle() {
    return this.option === "login"
      ? this.locale.translate("NO_ACCOUNT")
      : this.locale.translate("ALREADY_ACCOUNT");
  }

  get submitButtonTitle() {
    return this.option === "login" ? this.locale.translate("ENTER") : this.locale.translate("REGISTER_SHORT");
  }

  constructor() {
    super();
    makeObservable(this, {
      email: observable,
      password: observable,
      option: observable,
      acceptedTerms: observable,
      reset: action,
      login: action,
      register: action,
      logOut: action,
      title: computed,
      switchButtonTitle: computed,
      submitButtonTitle: computed,
    });
    this.init();
    this.reset();
  }

  reset = () => {
    this.email = "";
    this.password = "";
    this.option = "login";
  };

  login = async () => {
    if (!this.email || !this.password) {
      console.error(this.locale.translate("MISSING_CREDENTIALS"));
      return;
    }
    const callback = (loginPm: PackedMessage) => {
      this.unpackRepositoryPmToVm(loginPm, this.locale.translate("USER_LOGGED"));
    };

    await this.authenticationRepository.login(
      this.email,
      this.password,
      callback,
    );
  };

  register = async () => {
    if (!this.email || !this.password) {
      console.error(this.locale.translate("MISSING_CREDENTIALS"));
      return;
    }
    const callback = (registerPm: PackedMessage) => {
      this.unpackRepositoryPmToVm(
        registerPm,
        this.locale.translate("VERIFY_EMAIL"),
      );
    };
    const registerDto = await this.authenticationRepository.register(
      this.email,
      this.password,
      callback,
    );
    if (registerDto.success) {
      this.login();
    }
  };

  logOut = async () => {
    this.authenticationRepository.logOut();
  };

  impersonate = async (id: string) => {
    await this.authenticationRepository.impersonate(id);
  };

  resendVerificationEmail = async () => {

    const res: {success: boolean} = await this.authenticationRepository.resendVerificationEmail() as {success: boolean}
    if (res?.success === true) {
      this.addToast(this.locale.translate("EMAIL_SENT"), "success");
    } else {
      this.addToast(this.locale.translate("ERROR_SENDING_EMAIL"), "error");
    }
  };
}
