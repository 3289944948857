import { HoverCard, Flex, Text, Blockquote } from "@radix-ui/themes";
// import { Button } from "../../../../Components/Button/Button";
import { Cross2Icon, CheckIcon } from "@radix-ui/react-icons";
import { QuotePresenter } from "../../../../Products/QuotePresenter";
import { observer } from "mobx-react";
import { isNumber } from "../../../../Core/utils";

export const PopROIResult: React.FC<{
  presenter: QuotePresenter;
}> = observer(({ presenter }) => {
  if (!presenter.quote) {
    return <div>{presenter.locale.translate("NO_QUOTES")}</div>;
  }

  const roiValue = isNumber(presenter.computedROI.result)
    ? presenter.computedROI.result
    : 0;
  const formattedRoiValue = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 0,
  }).format(roiValue as number);
  const roiMissing = presenter.computedROI.missing;
  const areMissing = roiMissing.length > 0;

  const variables = presenter.computedROI.variables;

  const valueSemaphore = (value: number) => {
    if (value < 0.5) {
      return "crimson";
    } else if (value < 1) {
      return "orange";
    } else if (value < 2) {
      return "cyan";
    } else {
      return "indigo";
    }
  };

  return (
    <HoverCard.Root>
      <HoverCard.Trigger>
        <Flex justify="center" align="center" style={{ height: "100%" }}>
          <Blockquote
            style={{
              fontWeight: "bold",
              cursor: "help",
              display: "flex",
              alignItems: "center",
              minWidth: "fit-content",
            }}
            color={areMissing ? "crimson" : valueSemaphore(roiValue as number)}
          >
            {areMissing ? presenter.locale.translate("MISSING_DATA") : formattedRoiValue}
            {areMissing ? (
              <Cross2Icon
                width="1rem"
                height="1rem"
                style={{ marginLeft: "8px" }}
              />
            ) : (
              <CheckIcon
                width="1rem"
                height="1rem"
                style={{ marginLeft: "8px" }}
              />
            )}
          </Blockquote>
        </Flex>
      </HoverCard.Trigger>
      <HoverCard.Content style={{ width: 360 }}>
        <Flex gap="3" direction={"column"}>
          {areMissing && <Text weight={"bold"}>{presenter.locale.translate("MISSING_VALUES")}:</Text>}
          {presenter.locale.mapStrings(roiMissing).map((missing, index) => (
            <Text key={index}>{missing}</Text>
          ))}
          {!areMissing && <Text weight={"bold"}>{presenter.locale.translate("USED_VALUES")}:</Text>}
          {variables &&
            Object.entries(variables).map((variable, index) => (
              <Flex key={index} justify={"between"}>
                <Text key={index}>{presenter.locale.mapStrings([variable[0]])[0]}: </Text>
                <Text style={{ minWidth: "20%" }}>{variable[1]}</Text>
              </Flex>
            ))}
        </Flex>
      </HoverCard.Content>
    </HoverCard.Root>
  );
});
