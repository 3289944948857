import React from "react";
import { observer } from "mobx-react";
import styles from "./Toast.module.css";
import {
    AlertOctagon,
    AlertTriangle,
    CheckCircle,
    Info,
    X,
} from "react-feather";

const ICONS_BY_VARIANT = {
  success: CheckCircle,
  error: AlertOctagon,
  info: Info,
  warning: AlertTriangle,
};

const Toast: React.FC<{
  variant: "success" | "error" | "info" | "warning";
  isOpen?: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  closing?: boolean;
}> = observer(({ variant, isOpen = true, onClose, children, closing }) => {

  if (!isOpen) return null;

  const SelectedIcon = ICONS_BY_VARIANT[variant];

  return (
    <li className={closing ? styles.toastWrapperExit : styles.toastWrapper}>
    <div className={`${styles.toast} ${styles[variant]}`}>
      <div className={styles.iconContainer}><SelectedIcon/></div>
      <p className={styles.content}>{children}</p>
      <button
        aria-label="Dismiss message"
        aria-live="off"
        className={styles.closeButton}
        onClick={onClose}
      >
        <X width={"1rem"} height={"1rem"} />
      </button>
    </div>
    </li>
  );
});

export default Toast;
