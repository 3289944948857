import { injectable, inject } from "inversify";
import { action, computed, makeObservable, observable } from "mobx";
import {
  ProductListRepository,
  SortColumn,
  SortOrder,
} from "./ProductListRepository";
import { MessagesPresenter } from "../Core/Messages/MessagesPresenter";
import { UserModel } from "../Authentication/UserModel";

const initialProduct: Product = {
  name: "New Product",
  pvp: 0,
  category: "Equipaje",
  status: "In Progress",
  notes: "",
  vat_percentage: 21,
};

@injectable()
export class ProductListPresenter extends MessagesPresenter {
  @inject(ProductListRepository)
  productListRepository: ProductListRepository;

  @inject(UserModel)
  userModel: UserModel;

  newProduct: Product = initialProduct;

  get viewModel() {
    try {
      const filteredProducts = this.productListRepository.products.filter(
        (product) => {
          const productStatus = product.status as ProductStatus;
          const productCategory = product.category as Categories;
          // conditions: not deleted, deleted optionally filtered, status not filtered, category not filtered,
          const filteringConditions =
            (!product.is_deleted ||
              product.is_deleted ===
                this.productListRepository.isDeletedFilter) &&
            this.productListRepository.statusFilters[productStatus] &&
            this.productListRepository.categoryFilters[productCategory];

          return filteringConditions;
        },
      );
      const column = this.productListRepository.sorting.column;
      if (column === "none") return filteredProducts;
      const sortedProducts =
        this.productListRepository.sorting.column === "none"
          ? filteredProducts
          : this.productListRepository.sorting.type === "numeric"
          ? filteredProducts.sort((a, b) => {
              const productA = a[column] as number;
              const productB = b[column] as number;
              return this.productListRepository.sorting.order === "asc"
                ? productA - productB
                : productB - productA;
            })
          : filteredProducts.sort((a, b) => {
              const productA = a[column] as string;
              const productB = b[column] as string;
              return this.productListRepository.sorting.order === "asc"
                ? productA.localeCompare(productB)
                : productB.localeCompare(productA);
            });

      return sortedProducts;
    } catch (error) {
      console.error(this.locale.translate("ERROR_FILTERSORTING_PRODUCTS"), error);

      return this.productListRepository.products.filter(
        (product) => !product.is_deleted,
      );
    }
  }

  get status() {
    return this.productListRepository.status;
  }

  get statusHint() {
    return this.viewModel.length === 0 && this.status === "LOADED"
      ? this.locale.translate("ADD_PRODUCT_SUGGESTION")
      : this.status === "LOADING"
      ? this.locale.translate("LOADING")
      : "";
  }

  get statusFilters() {
    return this.productListRepository.statusFilters;
  }

  get categoryFilters() {
    return this.productListRepository.categoryFilters;
  }

  get isDeletedFilter() {
    return this.productListRepository.isDeletedFilter;
  }

  get sorting() {
    return this.productListRepository.sorting;
  }

  get hasMaxProducts() {
    if (this.userModel.isAdmin) return false;
    if (!this.userModel.maxProducts) return false;
    return this.viewModel.length >= this.userModel.maxProducts;
  }

  constructor() {
    super();
    makeObservable(this, {
      newProduct: observable,
      viewModel: computed,
      status: computed,
      statusFilters: computed,
      categoryFilters: computed,
      isDeletedFilter: computed,
      sorting: computed,
      addProduct: action,
      toggleFilter: action,
      sortBy: action,
    });
    this.init();
  }

  addProduct = async () => {
    if (this.hasMaxProducts === true) {
      this.addToast(
        this.locale.translate("MAX_PRODUCTS") + this.userModel.maxProducts,
        "error",
      );
      return;
    }
    await this.productListRepository.addProduct(this.newProduct);
    this.newProduct = initialProduct;
  };

  deleteProduct = async (id: string) => {
    await this.productListRepository.deleteProduct(id);
  };

  toggleFilter = (
    filterCategory: "status" | "category" | "isDeleted",
    filterCondition: Categories | ProductStatus | undefined,
  ) => {
    this.productListRepository.toggleFilter(filterCategory, filterCondition);
  };

  sortBy = (column: SortColumn, order: SortOrder) => {
    this.productListRepository.changeSorting(column, order);
  };
}
