import React from "react";
import { observer } from "mobx-react";
import { ProductDetailPresenter } from "../../../Products/ProductDetailPresenter";
import { withInjection } from "../../../Core/Providers/withInjection";
import { InfoRow } from "./InfoRow";
import { ButtonDialog } from "../../../Components/DialogButton/DialogButton";
import { EditDialog } from "./EditDialog";
import { Edit } from "react-feather";
import classes from "./ProductInfo.module.css";
import { Heading } from "@radix-ui/themes";

const ProductInfoComp: React.FC<{
  presenter: ProductDetailPresenter;
}> = ({ presenter }) => {
  const fallback = presenter.locale.translate("NOT_AVAILABLE");
  const handleEmptyString = (
    value: string | number | null | undefined,
  ): string | number => {
    if (value === null || value === undefined) {
      return presenter.locale.translate("NOT_AVAILABLE");
    }
    return value;
  };

  return (
    <div className={classes.prodInfoContainer}>
      {presenter.viewModel ? (
        <>
          <InfoRow
            label={presenter.locale.translate("NAME")}
            value={presenter.viewModel.name}
            fallback={fallback}
          />
          <InfoRow
            label={presenter.locale.translate("PRODUCT_PRICE")}
            value={handleEmptyString(presenter.viewModel.pvp)}
            fallback={fallback}
          />
          <InfoRow
            label={presenter.locale.translate("CATEGORY")}
            value={
              presenter.viewModel.category
                ? presenter.locale.translateCategory(presenter.viewModel.category)
                : presenter.locale.translate("NO_CATEGORY")
            }
            fallback={fallback}
          />
          <InfoRow
            label={presenter.locale.translate("STATUS")}
            value={presenter.viewModel.status}
            fallback={fallback}
          />
          <InfoRow
            label={presenter.locale.translate("VAT_PRCT")}
            value={presenter.viewModel.vat_percentage}
            fallback={fallback}
          />
          <InfoRow
            label={presenter.locale.translate("RETURN_RATE_PRCT")}
            value={presenter.viewModel.return_rate}
            fallback={fallback}
          />
          <InfoRow
            label={presenter.locale.translate("MARKETING_RATE_PRCT")}
            value={presenter.viewModel.marketing_rate}
            fallback={fallback}
          />
          <InfoRow
            label={presenter.locale.translate("ADDED_COSTS")}
            value={presenter.viewModel.additional_cost}
            fallback={fallback}
          />
          <InfoRow
            label={presenter.locale.translate("HAZMAT")}
            value={
              presenter.viewModel.hazmat === null
                ? presenter.locale.translate("NOT_AVAILABLE")
                : presenter.viewModel.hazmat
                ? presenter.locale.translate("YES")
                : presenter.locale.translate("NO")
            }
            fallback={fallback}
          />
          <InfoRow
            label={presenter.locale.translate("AMOUNT")}
            value={presenter.viewModel.quantity}
            fallback={fallback}
          />
          <InfoRow
            label={presenter.locale.translate("NOTES")}
            value={presenter.viewModel.notes}
            fallback={fallback}
          />
          <InfoRow
            label={presenter.locale.translate("QUOTES")}
            value={presenter.viewModel.quotes?.length}
            fallback={fallback}
          />
          <InfoRow
            label={presenter.locale.translate("CREATED_AT")}
            value={new Date(
              presenter.viewModel.created_at as string,
            ).toLocaleDateString()}
            fallback={fallback}
          />
          <InfoRow
            label={presenter.locale.translate("UPDATED_AT")}
            value={new Date(
              presenter.viewModel.updated_at as string,
            ).toLocaleDateString()}
            fallback={fallback}
          />
          <div className={classes.actions}>
            <ButtonDialog
              title={presenter.locale.translate("EDIT_PRODUCT")}
              description={presenter.locale.translate("EDIT_PRODUCT_SUGGESTION")}
              cancelText={presenter.locale.translate("CANCEL")}
              confirmText={presenter.locale.translate("UPDATE")}
              onConfirm={presenter.updateProduct}
              body={<EditDialog presenter={presenter} />}
            >
              <Edit width="1rem" height="1rem" />
              {presenter.locale.translate("EDIT_PRODUCT")}
            </ButtonDialog>
          </div>
        </>
      ) : (
        <Heading>{presenter.statusString}</Heading>
      )}
    </div>
  );
};

export const ProductInfo = withInjection({
  presenter: ProductDetailPresenter,
})(observer(ProductInfoComp));
