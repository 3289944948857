import React from "react";
import { observer } from "mobx-react";
import { QuoteListPresenter } from "../../../../Products/QuoteListPresenter";
import { Flex, TextField, Text, Switch } from "@radix-ui/themes";
import { SelectSupplier } from "./SelectSupplier";
import { ProductFilterSelect } from "./ProductFilterSelect";

const AddQuoteComp: React.FC<{
  presenter: QuoteListPresenter;
}> = ({ presenter }) => {
  const handleSupplierChange = (e: React.BaseSyntheticEvent): void => {
    const name = e.target.name as "contact_name";
    presenter.newSupplier[name] = e.target.value;
  };

  React.useEffect(() => {
    presenter.initNewQuote();
  }, [presenter]);

  return (
    <Flex direction="column" gap="3">
      {!presenter.availableSuppliers || !presenter.availableSuppliers.length ? (
        <Flex direction="column" gap="3">
          <Text>{presenter.locale.translate("NO_MANUFACTURERS_WITHOUT_QUOTE")}</Text>{" "}
          <label>
            <Text as="div" size="2" mb="1" weight="bold">
              {presenter.locale.translate("NEW_MANUFACTURER")}
            </Text>
            <TextField.Root
              placeholder={presenter.locale.translate("MANUFACTURER_CONTACT_NAME")}
              value={
                (presenter.newSupplier.contact_name as string | undefined) || ""
              }
              name="contact_name"
              onChange={handleSupplierChange}
            />
          </label>
        </Flex>
      ) : (
        <Flex direction="column" gap="3">
          <Flex gap="2">
            <Switch
              checked={presenter.newSupplierMenuOpen}
              onCheckedChange={(checked) =>
                (presenter.newSupplierMenuOpen = checked)
              }
            />{" "}
            <Text>{presenter.locale.translate("NEW_MANUFACTURER")}</Text>
          </Flex>
          {presenter.newSupplierMenuOpen ? (
            <Flex direction="column" gap="3">
              <label>
                <Text as="div" size="2" mb="1" weight="bold">
                  {presenter.locale.translate("NEW_MANUFACTURER")}
                </Text>
                <TextField.Root
                  placeholder={presenter.locale.translate("MANUFACTURER_CONTACT_NAME")}
                  value={
                    (presenter.newSupplier.contact_name as
                      | string
                      | undefined) || ""
                  }
                  name="contact_name"
                  onChange={handleSupplierChange}
                />
              </label>
            </Flex>
          ) : (
            <>
            <ProductFilterSelect 
              value={presenter.filterByProductId || null}
              options={presenter.productsToFilterBy || []}
              onChange={(value) => (presenter.filterByProductId = value)}
              translate={presenter.locale.translate}
            />
            <Text as="div" size="2" mb="1">{presenter.locale.translate("SELECT_MANUFACTURER")}</Text>
            <SelectSupplier
              value={presenter.newQuote.supplier_id || null}
              options={presenter.availableSuppliersFilteredByProduct}
              onChange={(value) => (presenter.newQuote.supplier_id = value)}
            />
            </>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export const AddQuote = observer(AddQuoteComp);
