import React from "react";
import { observer } from "mobx-react";
import { QuotePresenter } from "../../../../Products/QuotePresenter";
import { PopImportFees } from "./PopImportFees";
import { PopReferenceFee } from "./PopReferenceFee";
import classes from "./Quote.module.css";
import { PopLogisticFee } from "./PopLogisticFee";
import { PopStorageFee } from "./PopStorageFee";
import { PopManufacturingCost } from "./PopManufacturingCost";
import { PopShippingCost } from "./PopShippingCost";
import { PopMarginResult } from "./PopMarginResult";
import { AlertDialogButton } from "../../../../Components/AlertDialog/AlertDialog";
import { PopROIResult } from "./PopROIResult";
import { Trash2 } from "react-feather";
import { Flex, Text } from "@radix-ui/themes";
import { ButtonDialog } from "../../../../Components/DialogButton/DialogButton";
import { EditSupplier } from "./EditSupplier/EditSupplier";
import { Pencil1Icon } from "@radix-ui/react-icons";

const QuoteComp: React.FC<{
  presenter: QuotePresenter;
  ref?: React.Ref<HTMLDivElement>;
}> = React.forwardRef(({ presenter }, ref) => {
  if (!presenter.quote) {
    return <div>{presenter.locale.translate('NO_QUOTES')}{presenter.quoteId}</div>;
  }

  const supplier = presenter.quote.supplier;

  return (
    <div ref={ref} className={classes.quoteRow}>
      <Flex align="center" gap="3">
        {supplier && supplier.url ? (
          <a
            href={supplier.url}
            onClick={(e) => e.stopPropagation()}
            target="_blank"
          >
            <img
              className={classes.alibabaLogo}
              src="/alibabalogocropped.png"
              alt="alibaba-logo"
              width="24px"
              height="24px"
            />
          </a>
        ) : (
          <span
            className={classes.alibabaLogo}
            style={{ minWidth: "24px", minHeight: "24px" }}
          />
        )}
        <Text>
          {presenter.quote.supplier
            ? presenter.quote.supplier.contact_name
            : "no contact name found"}
        </Text>
        <ButtonDialog
          title="Editar Fabricante"
          description="Cambie detalles del fabricante"
          cancelText="Cancelar"
          confirmText="Guardar"
          body={<EditSupplier presenter={presenter} />}
          onConfirm={presenter.editSupplier}
          buttonVariant="ghost"
        >
          <Pencil1Icon width="1rem" height="1rem" />
        </ButtonDialog>
      </Flex>
      <div>
        <PopROIResult presenter={presenter} />
      </div>
      <div>
        <PopMarginResult presenter={presenter} />
      </div>
      <Flex justify="center" align="center">
        <PopManufacturingCost presenter={presenter} />
      </Flex>
      <Flex justify="center" align="center">
        <PopShippingCost presenter={presenter} />
      </Flex>
      <Flex justify="center" align="center">
        <PopReferenceFee presenter={presenter} />
      </Flex>
      <Flex justify="center" align="center">
        <PopLogisticFee presenter={presenter} />
      </Flex>
      <Flex justify="center" align="center">
        <PopStorageFee presenter={presenter} />
      </Flex>
      <Flex justify="center" align="center">
        <PopImportFees presenter={presenter} />
      </Flex>
      <Flex justify="center" align="center" className={classes.quoteActions}>
        <AlertDialogButton
          title="Confirmar Acción"
          cancelText="Cancelar"
          actionText="Descartar"
          description={`Seguro desea descartar la cotización?`}
          onClick={() => presenter.removeQuote()}
        >
          <Trash2 width="1rem" height="1rem" />
          {presenter.locale.translate("DISCARD")}
        </AlertDialogButton>
      </Flex>
    </div>
  );
});

export const Quote = observer(QuoteComp);
