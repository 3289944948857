import wretch, { Wretch } from "wretch";
import { injectable, inject } from "inversify";
import { Config } from "./Config";
import { UserModel } from "../Authentication/UserModel";
import IDataGateway from "./IDataGateway";
import { WretchError } from "wretch/resolver";

@injectable()
export class HttpGateway implements IDataGateway {
  @inject(Config)
  config: Config;

  @inject(UserModel)
  userModel: UserModel;

  fetcher: Wretch = wretch()
  .catcher(403, (error) => {
    console.error("unauthorized", error.status);
    throw new Error("Acceso denegado")
  })
  .catcher(404, (error) => {
    console.error("not found", error.status);
    const newError = new WretchError("Recurso no encontrado")
    newError.status = error.status;
    throw newError
  })
  .catcher(422, (error) => {
    console.error("unprocessable entity", error.status);
    throw new Error("Error de validación, revise los datos ingresados")
  })
  .catcher(500, (error) => {
    console.error("server error", error.status);
    throw new Error("Error del servidor")
  })
  .catcherFallback((error) => {
    console.error("unexpected error fetching", error);
    const newError = new WretchError("Error inesperado " + error.status)
    newError.status = error.status;
    throw newError
  })

  ;

  authFetcher: Wretch = wretch()
  .catcherFallback((error) => {
    console.error("unexpected error refreshing token", error);
    return {
      success: false,
      result: {
        message: "Error " + error.status,
      },
    };
  });


  get = async (path: string) => {
      const dto: object = await this.fetcher
        .url(this.config.apiUrl)
        .auth(`Bearer ${this.userModel.token}`)
        .url(path)
        .get()
        .unauthorized(async (_, req) => {
          // renew credentials
          console.log("unauthorized, refreshing token");
          const authData: {
            access_token: string;
            refresh_token: string;
            token_type: string;
          } = await this.authFetcher
            .url(this.config.authApiUrl)
            .url("/auth/refresh")
            .post({ refresh_token: this.userModel.refreshToken })
            .json();
          this.userModel.store(
            this.userModel.email as string,
            authData.access_token,
            authData.refresh_token
          );

          // retry the original request
          return req
            .auth(`Bearer ${this.userModel.token}`)
            .get()
            .unauthorized((error) => {
              // logout user
              console.log("unauthorized, logging out user ", error.status);
              this.userModel.clear();
            })
            .json();
        })
        .json();
      return dto;
  };

  put = async (path: string, requestDto: object) => {
    const dto: object = await this.fetcher
      .url(this.config.apiUrl)
      .auth(`Bearer ${this.userModel.token}`)
      .url(path)
      .put(requestDto)
      .unauthorized(async (_, req) => {
          // renew credentials
          console.log("unauthorized, refreshing token");
          const authData: {
            access_token: string;
            refresh_token: string;
            token_type: string;
          } = await this.authFetcher
            .url(this.config.authApiUrl)
            .url("/auth/refresh")
            .post({ refresh_token: this.userModel.refreshToken })
            .json();
          this.userModel.store(
            this.userModel.email as string,
            authData.access_token,
            authData.refresh_token
          );

          // retry the original request
          return req
            .auth(`Bearer ${this.userModel.token}`)
            .put(requestDto)
            .unauthorized((error) => {
              // logout user
              console.log("unauthorized, logging out user ", error.status);
              this.userModel.clear();
            })
            .json();
      })
      .json();
    return dto;
  };

  post = async (path: string, requestDto: object) => {
    const dto: object = await this.fetcher
      .url(this.config.apiUrl)
      .auth(`Bearer ${this.userModel.token}`)
      .url(path)
      .post(requestDto)
      .unauthorized(async (_, req) => {
          // renew credentials
          console.log("unauthorized, refreshing token");
          const authData: {
            access_token: string;
            refresh_token: string;
            token_type: string;
          } = await this.authFetcher
            .url(this.config.authApiUrl)
            .url("/auth/refresh")
            .post({ refresh_token: this.userModel.refreshToken })
            .json();
          this.userModel.store(
            this.userModel.email as string,
            authData.access_token,
            authData.refresh_token
          );

          // retry the original request
          return req
            .auth(`Bearer ${this.userModel.token}`)
            .post(requestDto)
            .unauthorized((error) => {
              // logout user
              console.log("unauthorized, logging out user ", error.status);
              this.userModel.clear();
            })
            .json();
      })
      .json();
    return dto;
  };

  delete = async (path: string) => {
    const dto: object = await this.fetcher
      .url(this.config.apiUrl)
      .auth(`Bearer ${this.userModel.token}`)
      .url(path)
      .delete()
      .unauthorized(async (_, req) => {
          // renew credentials
          console.log("unauthorized, refreshing token");
          const authData: {
            access_token: string;
            refresh_token: string;
            token_type: string;
          } = await this.authFetcher
            .url(this.config.authApiUrl)
            .url("/auth/refresh")
            .post({ refresh_token: this.userModel.refreshToken })
            .json();
          this.userModel.store(
            this.userModel.email as string,
            authData.access_token,
            authData.refresh_token
          );

          // retry the original request
          return req
            .auth(`Bearer ${this.userModel.token}`)
            .delete()
            .unauthorized((error) => {
              // logout user
              console.log("unauthorized, logging out user ", error.status);
              this.userModel.clear();
            })
            .json();
      })
      .json();
    return dto;
  };
}
