import { observer } from "mobx-react";
import classes from "./SimpleList.module.css";
import { Text } from "@radix-ui/themes";
import { ProductListPresenter } from "../../../Products/ProductListPresenter";

export const ListHeader: React.FC<{presenter: ProductListPresenter}> = observer(({presenter}) => {
  return (
    <div className={`${classes.headerContainer} ${classes.header}`}>
      <div className={classes.startCols}>
        <p onClick={() => { throw "test"}}>{presenter.locale.translate("PRODUCT")}</p>
      </div>
      <div className={classes.midCols + " " + classes.midHeader}>
        <Text>{presenter.locale.translate("PRODUCT_PRICE")}</Text>
        <Text>{presenter.locale.translate("CATEGORY")}</Text>
        <Text>{presenter.locale.translate("STATUS")}</Text>
      </div>
      <div className={classes.endCols}>{presenter.locale.translate("ACTIONS")}</div>
    </div>
  );
});
