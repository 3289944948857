import { injectable, inject } from "inversify";
import { action, makeObservable, observable } from "mobx";
import { Types, categories } from "../Core/Types";
import { MessagePacking } from "../Core/Messages/MessagePacking";
import { Locale } from "../Core/Locale";
import { MessagesRepository } from "../Core/Messages/MessagesRepository";
import { WretchError } from "wretch/resolver";

export interface IProductGateway {
  get: <T>(url: string) => Promise<ProductDto<T>>;
  post: <T, O>(
    url: string,
    requestDto: Partial<T> | Partial<T>[]
  ) => Promise<ProductDto<O>>;
  put: <T>(url: string, requestDto: T) => Promise<ProductDto<T>>;
  delete: <T, O>(url: T) => Promise<ProductDto<O>>;
}

type FilterType = "status" | "category" | "isDeleted";
export type SortColumn = "none" | keyof Product;
export type SortOrder = "asc" | "desc";
@injectable()
export class ProductListRepository {
  @inject(Types.IDataGateway)
  dataGateway: IProductGateway;

  @inject(Locale)
  locale: Locale;

  @inject(MessagesRepository)
  messagesRepository: MessagesRepository;

  status: RepoStatus = "UNINITIALIZED";
  statusMsg: string = "";
  addProductStatus: RepoStatus = "UNINITIALIZED";
  deleteProductStatus: RepoStatus = "UNINITIALIZED";

  products: Product[] = [];

  // all not deleted products are displayed by default
  statusFilters: Record<ProductStatus, boolean> = {
    "In Progress": true,
    Discarded: true,
    Reconsider: true,
    Selling: true,
  };
  // all categories are displayed by default, hence map to true
  categoryFilters: Record<Categories, boolean> = [...categories]
    .map((cat) => [cat, true])
    .reduce(
      (acc, [cat, value]) => ({ ...acc, [cat as string]: value }),
      {} as Record<Categories, boolean>
    );
  // deleted products are filtered by default
  isDeletedFilter = false;

  sorting: {
    column: SortColumn;
    order: SortOrder;
    type: "numeric" | "alphabetic";
  } = {
    column: "none",
    order: "asc",
    type: "numeric",
  };

  constructor() {
    makeObservable(this, {
      products: observable,
      status: observable,
      statusMsg: observable,
      statusFilters: observable,
      categoryFilters: observable,
      isDeletedFilter: observable,
      sorting: observable,
      load: action,
      addProduct: action,
      toggleFilter: action,
    });
  }

  load = async () => {
    this.status = "LOADING";
    try {
      const dto = await this.dataGateway.get<Product[]>("/products/");
      this.products = dto.result.data;
      this.statusMsg = dto.result.message ? dto.result.message : "";
      this.status = "LOADED";
      // this.messagesRepository.addToast("Lista de productos cargada con éxito", "success");
      return MessagePacking.unpackServerDtoToPm(dto);
    } catch (error) {
      // handle special case for 404
      if (error instanceof WretchError && error.status === 404) {
        this.messagesRepository.addToast(
          this.locale.translate("NO_PRODUCTS"),
          "info"
        );
        this.products = [];
        this.status = "LOADED";
        return MessagePacking.unpackServerDtoToPm({
          success: true,
          result: { message: this.locale.translate("NO_PRODUCTS") },
        });
      } // end - special case 404
      this.status = "ERROR";
      this.statusMsg =
        error instanceof Error
          ? error.message
          : this.locale.translate("ERROR_LOADING_PRODUCTS");
      this.messagesRepository.addToast(
        this.locale.translate("ERROR_LOADING_PRODUCTS"),
        "error"
      );
      return MessagePacking.unpackServerDtoToPm({
        success: false,
        result: { message: this.statusMsg },
      });
    }
  };

  reset = () => {
    this.status = "RESET";
    this.statusMsg = "";
    this.products = [];
  };

  addProduct = async (product: Product) => {
    this.addProductStatus = "LOADING";
    try {
      const dto = await this.dataGateway.post<Product, Product>(
        "/products/",
        product
      );
      this.products.push(dto.result.data);
      this.statusMsg = dto.result.message ? dto.result.message : "";
      this.addProductStatus = "LOADED";
      this.messagesRepository.addToast(this.locale.translate("PRODUCT_ADDED"), "success");
      return MessagePacking.unpackServerDtoToPm(dto);
    } catch (error) {
      this.addProductStatus = "ERROR";
      this.statusMsg =
        error instanceof Error
          ? error.message
          : this.locale.translate("ERROR_ADDING_PRODUCT");
      this.messagesRepository.addToast(this.locale.translate("ERROR_ADDING_PRODUCT"), "error");
      return MessagePacking.unpackServerDtoToPm({
        success: false,
        result: { message: this.statusMsg },
      });
    }
  };

  deleteProduct = async (id: string) => {
    this.deleteProductStatus = "LOADING";
    try {
      const dto = await this.dataGateway.delete("/products/" + id);
      this.products = this.products.filter((p) => p.id !== id);
      this.statusMsg = dto.result.message ? dto.result.message : "";
      this.deleteProductStatus = "LOADED";
      this.messagesRepository.addToast(this.locale.translate("PRODUCT_REMOVED"), "success");
      return MessagePacking.unpackServerDtoToPm(dto);
    } catch (error) {
      this.deleteProductStatus = "ERROR";
      this.statusMsg =
        error instanceof Error
          ? error.message
          : this.locale.translate("ERROR_REMOVING_PRODUCT");
      this.messagesRepository.addToast(this.locale.translate("ERROR_REMOVING_PRODUCT"), "error");
      return MessagePacking.unpackServerDtoToPm({
        success: false,
        result: { message: this.statusMsg },
      });
    }
  };

  toggleFilter = (
    filterCategory: FilterType,
    filterCondition: ProductStatus | Categories | undefined
  ) => {
    if (filterCategory === "status") {
      this.statusFilters[filterCondition as ProductStatus] =
        !this.statusFilters[filterCondition as ProductStatus];
    } else if (filterCategory === "category") {
      this.categoryFilters[filterCondition as Categories] =
        !this.categoryFilters[filterCondition as Categories];
    } else if (filterCategory === "isDeleted") {
      this.isDeletedFilter = !this.isDeletedFilter;
    }
  };

  changeSorting = (sortColumn: SortColumn, sortOrder: SortOrder) => {
    const type =
      sortColumn === "pvp" || sortColumn === "pvp_min"
        ? "numeric"
        : "alphabetic";
    this.sorting = {
      column: sortColumn,
      order: sortOrder,
      type,
    };
  };
}
