import { injectable, inject } from "inversify";
import {
  makeObservable,
  computed,
  observable,
  action,
  reaction,
  comparer,
} from "mobx";
import { MessagesPresenter } from "../Core/Messages/MessagesPresenter";
import { ProductDetailRepository } from "./ProductDetailRepository";
import { debounce } from "lodash-es";
import { container } from "../AppIOC";
import { QuotePresenter } from "./QuotePresenter";
import { sortQuotePresenters } from "./quoteUtils";
import { PackedMessage } from "../Core/Messages/MessagePacking";

@injectable()
export class QuoteListPresenter extends MessagesPresenter {
  @inject(ProductDetailRepository)
  productDetailRepository: ProductDetailRepository;

  container = container;

  newSupplierMenuOpen = false;

  newQuote: Partial<Quote> = {
    supplier_id: "",
    product_id: "",
    manufacturing_cost_currency: "EUR",
    shipping_cost_currency: "EUR",
  };

  newSupplier: Partial<Supplier> = {
    contact_name: "",
  };

  quotePresenterInstances: QuotePresenter[] | null = null;

  preventReorder = false;

  filterByProductId: string | null = null;

  reactionDisposer: (() => void) | null = null;

  get product() {
    return this.productDetailRepository.product;
  }

  get quotes() {
    const quotes: Quote[] | undefined =
      this.productDetailRepository.product?.quotes;
    const filteredQuotes = quotes?.filter(
      (quote) => quote.is_deleted === false,
    );
    const suppliers: Supplier[] | null = this.productDetailRepository.suppliers;

    const filteredQuotesWithSuppliers = filteredQuotes?.map((quote) => {
      const supplier = suppliers?.find(
        (supplier) => supplier.id === quote.supplier_id,
      );
      return {
        ...quote,
        supplier: supplier,
      };
    });

    return filteredQuotesWithSuppliers;
  }

  get productBuffer() {
    return this.productDetailRepository.productBuffer;
  }

  get status() {
    return this.productDetailRepository.status;
  }

  get exchangeRate() {
    return this.productDetailRepository.exchangeRateEURUSD;
  }

  get suppliers() {
    return this.productDetailRepository.suppliers;
  }

  get productsWithSuppliers() {
    return this.suppliers?.reduce((acc, supplier) => {
      const uniqueProducts = supplier.products.filter(
        (product) => !acc.some((p) => p.id === product.id),
      );
      return acc.concat(uniqueProducts);
    }, [] as Product[]);
  }

  get productsToFilterBy() {
    return this.productsWithSuppliers?.filter(prod => prod.id !== this.product?.id);
  }

  get availableSuppliers() {
    const suppliers = this.suppliers;
    const quotes = this.quotes;
    if (suppliers && quotes) {
      const usedSuppliers = quotes.map((quote) => quote.supplier_id);
      return suppliers.filter(
        (supplier) => !usedSuppliers.includes(supplier.id),
      );
    }
    return [];
  }

  get availableSuppliersFilteredByProduct() {
    if (!this.filterByProductId || this.filterByProductId === "none") {
      return this.availableSuppliers;
    }
    const filteredSuppliers = this.availableSuppliers.filter((supplier) => {
      return supplier.products.some(
        (product) => product.id === this.filterByProductId,
      );
    });
    return filteredSuppliers;
  }

  get autonomoMode() {
    return this.productDetailRepository.autonomoMode;
  }

  get noQuotes() {
    return (
      this.quotePresenterInstances?.length === 0 && this.status === "LOADED"
    );
  }

  get statusHint() {
    return this.noQuotes
      ? this.locale.translate("NO_QUOTES_HINT")
      : "";
  }

  constructor() {
    super();
    makeObservable(this, {
      newSupplierMenuOpen: observable,
      newQuote: observable,
      newSupplier: observable,
      quotePresenterInstances: observable,
      preventReorder: observable,
      filterByProductId: observable,
      product: computed,
      quotes: computed,
      productBuffer: computed,
      status: computed,
      exchangeRate: computed,
      suppliers: computed,
      availableSuppliers: computed,
      autonomoMode: computed,
      noQuotes: computed,
      statusHint: computed,
      cleanUp: action,
      removeInstance: action,
      initQuotePresenterInstances: action,
      initNewQuote: action,
      setProductBuffer: action,
      saveProductBuffer: action,
      setProductBufferAndSave: action,
      addSupplier: action,
      addQuote: action,
      switchAutonomoMode: action,
    });
    this.init();
  }

  cleanUp() {
    if (this.reactionDisposer) {
      this.reactionDisposer();
    }
    // dispose all instances of quotePresenter
    this.quotePresenterInstances?.forEach((quotePresenter) => {
      quotePresenter.dispose();
    });
  }

  removeInstance = (quoteId: string) => {
    const index = this.quotePresenterInstances?.findIndex(
      (quotePresenter) => quotePresenter.quoteId === quoteId,
    );
    if (index !== undefined && index !== -1) {
      this.quotePresenterInstances?.splice(index, 1);
    }
  };

  setPreventReorder = (activated: boolean) => {
    this.preventReorder = activated;
  };

  initQuotePresenterInstances() {
    const quotePresenters = this.quotes?.map((quote) => {
      const quotePresenter = this.container.get(QuotePresenter);
      quotePresenter.initQuoteId(
        quote.id,
        this.removeInstance,
        this.setPreventReorder,
      );
      return quotePresenter;
    });
    const sortedQuotePresenters = sortQuotePresenters(
      quotePresenters as QuotePresenter[],
    );
    this.quotePresenterInstances = sortedQuotePresenters as QuotePresenter[];

    this.reactionDisposer = reaction(
      () => ({
        quotes: this.quotes,
        product: this.product,
        preventReorder: this.preventReorder,
      }),
      debounce(() => {
        // sort quotePresenters by margin
        if (!this.quotePresenterInstances) return;
        if (this.preventReorder) return;
        sortQuotePresenters(this.quotePresenterInstances);
      }, 500),
      { equals: comparer.structural },
    );
  }

  initNewQuote = () => {
    this.newQuote.product_id = this.productDetailRepository.product?.id;
  };

  setProductBuffer = (newTarget: Partial<Product>) => {
    const oldTarget = this.productDetailRepository.productBuffer as Product;
    this.productDetailRepository.productBuffer = { ...oldTarget, ...newTarget };
  };

  saveProductBuffer = debounce(() => {
    this.productDetailRepository.saveAndUpdateProduct();
  }, 500);

  setProductBufferAndSave = debounce((newTarget: Partial<Product>) => {
    this.setProductBuffer(newTarget);
    const newProduct = {
      ...this.product,
      ...newTarget,
    } as Product;
    this.productDetailRepository.update(newProduct);
  }, 500);

  addSupplier = async () => {
    const result = await this.productDetailRepository.addSupplier(
      this.newSupplier,
    );
    this.newSupplierMenuOpen = false;
    return result;
  };

  addQuote = async () => {
    // all this if there is no supplier selected / available
    if (!this.newQuote.supplier_id || this.newSupplierMenuOpen) {
      // make sure a contact name is provided
      if (!this.newSupplier.contact_name) {
        return {
          success: false,
          serverMessage: this.locale.translate("CONTACT_NAME_MISSING"),
        };
      }
      // create the new supplier
      const supplierResult: Supplier | PackedMessage = await this.addSupplier();

      if (!supplierResult) {
        return;
      }
      if (!("id" in supplierResult)) {
        return supplierResult;
      }
      // select the new supplier
      this.newQuote.supplier_id = supplierResult.id;
    }
    /////////

    const result: Quote | undefined =
      await this.productDetailRepository.addQuote(this.newQuote);
    this.newQuote.supplier_id = "";
    if (!result) {
      return;
    }
    const newQuotePresenter = container.get(QuotePresenter);
    newQuotePresenter.initQuoteId(
      result.id,
      this.removeInstance,
      this.setPreventReorder,
    );
    this.quotePresenterInstances?.push(newQuotePresenter);
    return result;
  };

  switchAutonomoMode = () => {
    this.productDetailRepository.switchAutonomoMode();
  };
}
