import { injectable } from 'inversify'
import { makeObservable, observable, action } from 'mobx'

export type AppMessage = {
  message: string
  success: boolean
}

export type ToastMessage = {
  type: 'success' | 'error' | 'info' | 'warning'
  message: string,
  id: string
  closing?: boolean
}

const TOAST_DURATION = 5000
const TOAST_EXIT_DURATION = 2000
@injectable()
export class MessagesRepository {
  appMessages: Array<AppMessage> | null = null
  toastMessages: Array<ToastMessage> | null = null

  constructor() {
    makeObservable(this, {
      appMessages: observable,
      toastMessages: observable,
      addToast: action,
      removeToast: action,
      closeToast: action,
      dismissAllToasts: action,
    })
    this.reset()
  }

  reset = () => {
    this.appMessages = []
    this.toastMessages = []
  }

  addToast = (message: string, type: 'success' | 'error' | 'info' | 'warning') => {
    const id = crypto.randomUUID() 
    const closing = false
    this.toastMessages?.push({ message, type, id, closing })
    setTimeout(() => {
      this.closeToast(id)
    }, TOAST_DURATION) 
  }

  removeToast = (id: string) => {
    this.toastMessages = this.toastMessages?.filter((toast) => toast.id !== id) || []
  }

  closeToast = (id: string) => {
    const toast = this.toastMessages?.find((toast) => toast.id === id)
    if (toast) {
      toast.closing = true
    }
    setTimeout(() => {
      this.removeToast(id)
    }, TOAST_EXIT_DURATION)
  }

  dismissAllToasts = () => {
    this.toastMessages = []
  }
}
