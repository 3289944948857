import { QuotePresenter } from "./QuotePresenter";
export const sortQuotePresenters = (quotePresenters: QuotePresenter[]) => {
  return quotePresenters.sort((a, b) => {
    if (a.computedMargin.missing.length && b.computedMargin.missing.length) {
      const aCreatedAt = a.createdAtTimestamp as number;
      const bCreatedAt = b.createdAtTimestamp as number;
      return aCreatedAt - bCreatedAt;
    } else if (b.computedMargin.missing.length) {
      return -1;
    } else if (a.computedMargin.missing.length) {
      return 1;
    } else if (a.computedMargin.result && b.computedMargin.result) {
      return b.computedMargin.result - a.computedMargin.result;
    } else {
      return 0;
    }
  });
};

export const findPriceBreakById = (id: string, presenter: QuotePresenter) => {
  // Search for the price break in the manufacturing price breaks
  let priceBreak;
  if (!presenter.quote) {
    return;
  }
  const mfPriceBreakIndex =
    presenter.quote.manufacturing_price_breaks?.findIndex((pb) => pb.id === id);
  if (mfPriceBreakIndex !== -1) {
    priceBreak = presenter.quote?.manufacturing_price_breaks[mfPriceBreakIndex];
  }
  // Search for the price break in the shipping price breaks
  const shpPriceBreakIndex = presenter.quote.shipping_price_breaks?.findIndex(
    (pb) => pb.id === id
  );
  if (shpPriceBreakIndex !== -1) {
    priceBreak = presenter.quote.shipping_price_breaks[shpPriceBreakIndex];
  }
  return priceBreak;
};
