import {
  Popover,
  Flex,
  Text,
  TextField,
  Switch,
  Separator,
} from "@radix-ui/themes";
import { Button } from "../../../../Components/Button/Button";
import { ChatBubbleIcon } from "@radix-ui/react-icons";
import { QuotePresenter } from "../../../../Products/QuotePresenter";
import { observer } from "mobx-react";
import { SelectCurrency } from "./SelectCurrency";
import { currencies } from "../../../../Core/Types";
import { PriceBreaks } from "../PriceBreaks/PriceBreaks";
import { useValidation } from "../../../../Core/Providers/useValidation";
import { MessagesComponent } from "../../../../Core/Messages/MessagesComponent";
import { toDecimals, validateNumber } from "../../../../Core/utils";

export const PopManufacturingCost: React.FC<{
  presenter: QuotePresenter;
}> = observer(({ presenter }) => {
  const [validationMessages, updateClientValidationMessages] = useValidation();
  const handleCostChange = (e: React.BaseSyntheticEvent): void => {
    const valid = validateNumber(
      e.target.name,
      e.target.value,
      updateClientValidationMessages,
    );
    if (!valid) return;
    const value = e.target.value === "" ? null : toDecimals(e.target.value);
    presenter.updateCost(e.target.name, value);
  };

  const cleanAndSave = (opening: boolean) => {
    updateClientValidationMessages([]);
    // if the popover is closing, save the quote
    if (!opening) {
      presenter.setPreventReorder(false);
      presenter.setEditingPriceBreak(null);
      presenter.saveQuote();
    } else {
      presenter.setPreventReorder(true);
    }
  };

  const handleCurrencyChange = (value: Currencies | null) => {
    presenter.updateCurrency("manufacturing_cost_currency", value);
  };

  const currencySymbol =
    presenter.quote && presenter.quote.manufacturing_cost_currency === "USD"
      ? "$"
      : "€";

  const getTotalManufacturingCost = () => {
    if (!presenter.quote) {
      return presenter.locale.translate("NO_DATA");
    }

    let manufacturingCost: number | null;
    let totalManufacturingCost: number | null;

    if (presenter.quote.use_manufacturing_price_breaks) {
      // TODO: provide totalManufacturingCost here when using price breaks
      return presenter.manufacturing_cost_calculated !== null
        ? typeof presenter.manufacturing_cost_calculated === "string"
          ? presenter.manufacturing_cost_calculated
          : presenter.manufacturing_cost_calculated + " " + currencySymbol
        : presenter.locale.translate("NO_BREAKS");
    } else {
      manufacturingCost = presenter.quote.manufacturing_cost;
      totalManufacturingCost =
        Number(manufacturingCost) + Number(presenter.quote.packaging_cost);
      return presenter.quote.manufacturing_cost !== null
        ? totalManufacturingCost + " " + currencySymbol
        : presenter.locale.translate("NO_DATA");
    }
  };

  const isValueAvailable =
    (presenter.manufacturing_cost_calculated !== null &&
      typeof presenter.manufacturing_cost_calculated !== "string" &&
      presenter.quote?.use_manufacturing_price_breaks) ||
    (presenter?.quote?.manufacturing_cost !== null &&
      !presenter.quote?.use_manufacturing_price_breaks);

  if (!presenter.quote) {
    return <div>{presenter.locale.translate("NO_QUOTES")}</div>;
  }

  return (
    <Popover.Root onOpenChange={cleanAndSave}>
      <Popover.Trigger>
        <Button variant="soft" color={isValueAvailable ? "indigo" : "orange"}>
          <ChatBubbleIcon width="16" height="16" />
          {getTotalManufacturingCost()}
        </Button>
      </Popover.Trigger>
      <Popover.Content style={{ width: 360 }}>
        <Flex gap="3" direction={"column"}>
          <Text as="label" size="2">
            <Flex gap="2" direction="row" justify="between">
              <Flex gap="2">
                <Switch
                  onCheckedChange={(checked) => {
                    presenter.overrideCost(
                      "use_manufacturing_price_breaks",
                      checked,
                    );
                  }}
                  checked={presenter.quote.use_manufacturing_price_breaks}
                />{" "}
                {presenter.locale.translate("USE_PRICE_BREAKS")}
              </Flex>
              <SelectCurrency
                options={[...currencies]}
                value={presenter.quote.manufacturing_cost_currency}
                onChange={handleCurrencyChange}
                fallback={presenter.locale.translate("NO_CURRENCY")}
              />
            </Flex>
          </Text>
          {presenter.quote.use_manufacturing_price_breaks ? (
            <>
              <Text size="2" mb="1" weight="bold">
                {presenter.locale.translate("MANUFACTURING_PRICE_BREAKS")}
              </Text>
              <PriceBreaks type="Manufacturing" presenter={presenter} />
            </>
          ) : (
            <label>
              <Text as="div" size="2" mb="1" weight="bold">
                {presenter.locale.translate("MANUAL_MANUFACTURING_COST")}
              </Text>
              <TextField.Root
                placeholder={presenter.locale.translate("MANUFACTURING_COST")}
                value={
                  presenter.quote.manufacturing_cost !== null
                    ? presenter.quote.manufacturing_cost
                    : ""
                }
                onChange={handleCostChange}
                type="number"
                name="manufacturing_cost"
                disabled={presenter.status === "LOADING"}
              />
            </label>
          )}
        </Flex>
        <Separator orientation="horizontal" size="4" my="4" />
        <Flex gap="2" direction="column">
          <label>
            <Text as="div" size="2" mb="1" weight="bold">
              {presenter.locale.translate("PACKAGING_COST")}
            </Text>
            <TextField.Root
              placeholder={presenter.locale.translate("PACKAGING_COST")}
              value={
                presenter.quote.packaging_cost !== null
                  ? presenter.quote.packaging_cost
                  : ""
              }
              onChange={handleCostChange}
              type="number"
              name="packaging_cost"
              disabled={presenter.status === "LOADING"}
            />
          </label>
        </Flex>
        {validationMessages.length > 0 && <br />}
        <MessagesComponent />
      </Popover.Content>
    </Popover.Root>
  );
});
