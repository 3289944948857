import { observer } from "mobx-react";
import classes from "./SimpleList.module.css";
import { Trash2 } from "react-feather";
import { AlertDialogButton } from "../../../Components/AlertDialog/AlertDialog";
import { Text } from "@radix-ui/themes";
import { ProductListPresenter } from "../../../Products/ProductListPresenter";

export const ListItem: React.FC<{
  product: Product;
  goTo: (productId: string) => void;
  deleteProd: (productId: string) => void;
  presenter: ProductListPresenter;
}> = observer(({ product, goTo, deleteProd, presenter }) => {
  return (
    <div
      key={product.id}
      className={classes.itemContainer}
      onClick={() => goTo(product.id ? product.id : "")}
    >
      <div className={classes.startCols}>
        {product.url ? (
          <a
            href={product.url}
            onClick={(e) => e.stopPropagation()}
            target="_blank"
          >
            <img
              className={classes.amazonLogo}
              src="/amazon.ico"
              alt="amazon-logo"
              width="24px"
              height="24px"
            />
          </a>
        ) : (
          <span
            className={classes.amazonLogo}
            style={{ minWidth: "24px", minHeight: "24px" }}
          />
        )}
        {product.name}
      </div>
      <div className={classes.midCols}>
        <Text>{product.pvp}</Text>
        <Text>
          {product.category
            ? presenter.locale.translateCategory(product.category)
            : presenter.locale.translate("NO_CATEGORY")}
        </Text>
        <Text>{product.status}</Text>
      </div>
      <div className={classes.endCols} onClick={(e) => e.stopPropagation()}>
        <AlertDialogButton
          title={presenter.locale.translate("CONFIRM_ACTION")}
          cancelText={presenter.locale.translate("CANCEL")}
          actionText={presenter.locale.translate("DISCARD")}
          description={`${presenter.locale.translate(
            "CONFIRM_DISCARD_PRODUCT",
          )}${product.name}?`}
          onClick={() => deleteProd(product.id || "no-id")}
        >
          <Trash2 width="1rem" height="1rem" />
          {presenter.locale.translate("DISCARD")}
        </AlertDialogButton>
      </div>
    </div>
  );
});
