import React from "react";
import { observer } from "mobx-react";
import { Flex, Text, TextArea, TextField } from "@radix-ui/themes";
import { ProductListPresenter } from "../../../Products/ProductListPresenter";
import { SelectCategory } from "../../../Components/SelectCategory/SelectCategory";
import { categories } from "../../../Core/Types";
import { useValidation } from "../../../Core/Providers/useValidation";
import { MessagesComponent } from "../../../Core/Messages/MessagesComponent";
import { validateNumber, toDecimals } from "../../../Core/utils";


const AddDialogComp: React.FC<{
  presenter: ProductListPresenter;
}> = ({ presenter }) => {
  type inputKeys = "name" | "url" | "status" | "notes";

  const [, updateClientValidationMessages] = useValidation();

  const handleInputChange = (e: React.BaseSyntheticEvent): void => {
    const name = e.target.name as inputKeys;
    const value = e.target.value;
    presenter.newProduct[name] = value;
  };

  // utilities for handling number inputs
  const handleNumberInputChange = (e: React.BaseSyntheticEvent): void => {
    const name = e.target.name as "pvp" | "pvp_min";
    // turn empty string into null
    if (e.target.value === "") {
      presenter.newProduct[name] = null;
      return;
    }
    const valid = validateNumber(e.target.name, e.target.value, updateClientValidationMessages);
    if (!valid) return;
    const value = toDecimals(e.target.value, 2);
    presenter.newProduct[name] = Number(value);
  };

  const handleNullValue = (
    value: number | null | undefined
  ): number | string => {
    if (value === null || value === undefined) {
      return "";
    } else {
      return value;
    }
  };

  React.useEffect(() => {
    // clear validation messages on unmount
    return () => {
      updateClientValidationMessages([]);
    }
  }, [updateClientValidationMessages])

  return (
    <Flex direction="column" gap="3">
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {presenter.locale.translate("NAME")}
        </Text>
        <TextField.Root
          placeholder={presenter.locale.translate("PRODUCT_NAME_PLACEHOLDER")}
          value={presenter.newProduct.name}
          name="name"
          onChange={handleInputChange}
        />
      </label>
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {presenter.locale.translate("PRODUCT_URL")}
        </Text>
        <TextField.Root
          placeholder={presenter.locale.translate("PRODUCT_URL_PLACEHOLDER")}
          value={presenter.newProduct.url}
          onChange={handleInputChange}
          name="url"
        />
      </label>
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {presenter.locale.translate("PRODUCT_PRICE")}
        </Text>
        <TextField.Root
          placeholder={presenter.locale.translate("PRODUCT_PRICE_PLACEHOLDER")}
          value={handleNullValue(presenter.newProduct.pvp)}
          onChange={handleNumberInputChange}
          type="number"
          name="pvp"
        />
      </label>
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {presenter.locale.translate("CATEGORY")}
        </Text>
        <SelectCategory
          options={[...categories]}
          value={presenter?.newProduct?.category || "Equipaje"}
          onChange={(value) => (presenter.newProduct.category = value)}
          presenter={presenter}
        />
      </label>
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {presenter.locale.translate("NOTES")}
        </Text>
        <TextArea
          placeholder={presenter.locale.translate("PRODUCT_NOTES_PLACEHOLDER")}
          value={presenter.newProduct.notes}
          onChange={handleInputChange}
          name="notes"
        />
      </label>
      <MessagesComponent />
    </Flex>
  );
};

export const AddDialog = observer(AddDialogComp);
