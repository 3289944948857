import React from "react";
import { Dialog, Flex } from "@radix-ui/themes";
import { Button } from "../Button/Button";
import { InfoHoverCard } from "../InfoTooltip/InfoTooltip";

export const ButtonDialog: React.FC<{
  children: React.ReactNode;
  title: string;
  description: string;
  body: React.ReactNode;
  cancelText: string;
  confirmText: string;
  onConfirm: () => void;
  disabled?: boolean;
  buttonVariant?:
    | "classic"
    | "solid"
    | "soft"
    | "surface"
    | "outline"
    | "ghost";
  menuDisabled?: boolean;
}> = ({
  children,
  title,
  description,
  body,
  cancelText = "Cancel",
  confirmText = "Confirm",
  onConfirm,
  disabled = false,
  buttonVariant = "classic",
  menuDisabled = false,
}) => {
  const handleSumbit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onConfirm();
  };

  return (
    <Dialog.Root>
      <Dialog.Trigger>
        {menuDisabled ? (
          <InfoHoverCard
            title="Has alcanzado el límite de productos"
            content="Actualiza tu plan o borra algún producto para añadir más productos."
          >
            <Button disabled variant={buttonVariant}>{children}</Button>
          </InfoHoverCard>
        ) : (
          <Button variant={buttonVariant}>{children}</Button>
        )}
      </Dialog.Trigger>

      <Dialog.Content style={{ maxWidth: 450 }}>
        <Dialog.Title>{title}</Dialog.Title>
        <Dialog.Description size="2" mb="4">
          {description}
        </Dialog.Description>

        <form onSubmit={handleSumbit}>
          {body}

          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button disabled={disabled} type="submit">
                {confirmText}
              </Button>
            </Dialog.Close>
            <Dialog.Close>
              <Button variant="soft" color="gray">
                {cancelText}
              </Button>
            </Dialog.Close>
          </Flex>
        </form>
      </Dialog.Content>
    </Dialog.Root>
  );
};
