import { Popover, Flex, Text, TextField, Switch } from "@radix-ui/themes";
import { Button } from "../../../../Components/Button/Button";
import { ChatBubbleIcon } from "@radix-ui/react-icons";
import { QuotePresenter } from "../../../../Products/QuotePresenter";
import { observer } from "mobx-react";
import { useValidation } from "../../../../Core/Providers/useValidation";
import { MessagesComponent } from "../../../../Core/Messages/MessagesComponent";
import { toDecimals, validateNumber } from "../../../../Core/utils";

export const PopImportFees: React.FC<{
  presenter: QuotePresenter;
}> = observer(({ presenter }) => {
  const [validationMessages, updateClientValidationMessages] = useValidation();

  const handleImportFeechange = (e: React.BaseSyntheticEvent): void => {
    const valid = validateNumber(
      e.target.name,
      e.target.value,
      updateClientValidationMessages,
    );
    if (!valid) return;
    const value = e.target.value === "" ? null : toDecimals(e.target.value);
    presenter.updateImportFee(value);
  };

  const cleanAndSave = (opening: boolean) => {
    updateClientValidationMessages([]);
    // if the popover is closing, save the quote
    if (!opening) {
      presenter.setPreventReorder(false);
      presenter.saveQuote();
    } else {
      presenter.setPreventReorder(true);
    }
  };

  const isValueAvailable = presenter?.quote?.import_fees !== null;

  // if the cost can be calculated show cost, otherwise percentage
  const costWhenPercentage = presenter?.computedMargin?.variables?.import_fees
    ? presenter?.computedMargin?.variables?.import_fees?.toFixed(2) + " €"
    : presenter?.quote?.import_fees + " %";

  const currentValue = presenter?.quote?.import_fees_is_percentage
    ? costWhenPercentage
    : presenter?.quote?.import_fees + " €";

  if (!presenter.quote) {
    return <div>{presenter.locale.translate("NO_QUOTES")}</div>;
  }

  return (
    <Popover.Root onOpenChange={cleanAndSave}>
      <Popover.Trigger>
        <Button variant="soft" color={isValueAvailable ? "indigo" : "orange"}>
          <ChatBubbleIcon width="16" height="16" />
          {presenter.quote.import_fees !== null
            ? currentValue
            : presenter.locale.translate("NO_DATA")}
        </Button>
      </Popover.Trigger>
      <Popover.Content style={{ width: 360 }}>
        <Flex gap="3" direction="column">
          <Flex gap="2" align="center">
            <Switch
              onCheckedChange={(checked) => {
                presenter.updateIsImportFeePercentage(checked);
              }}
              checked={Boolean(presenter?.quote?.import_fees_is_percentage)}
            />
            {presenter?.quote?.import_fees_is_percentage
              ? presenter.locale.translate("PERCENTAGE")
              : presenter.locale.translate("FIXED_AMOUNT") }
          </Flex>
          <label>
            <Text as="div" size="2" mb="1" weight="bold">
              {presenter.locale.translate("IMPORT_FEES")}{" "}
              {presenter?.quote?.import_fees_is_percentage ? "(%)" : "(€)"}
            </Text>
            <TextField.Root
              placeholder={presenter.locale.translate("IMPORT_FEES")}
              value={
                presenter.quote.import_fees !== null
                  ? presenter.quote.import_fees
                  : ""
              }
              onChange={handleImportFeechange}
              type="number"
              name="import_fee"
              disabled={presenter.status === "LOADING"}
            />
          </label>
        </Flex>
        {validationMessages.length > 0 && <br />}
        <MessagesComponent />
      </Popover.Content>
    </Popover.Root>
  );
});
