import React from "react"
import { AlertDialog, Flex } from '@radix-ui/themes'
import { Button } from "../Button/Button"

export const AlertDialogButton: React.FC<{
    children: React.ReactNode,
    title: string,
    description: string
    cancelText: string
    actionText: string
    onClick: () => void
}> = ({
   children,
   title,
   description,
   cancelText = "Cancel",
   actionText = "Proceed",
   onClick = () => {}
}) => {

return (
<AlertDialog.Root> <AlertDialog.Trigger>
    <Button color="red">{children}</Button>
  </AlertDialog.Trigger>
  <AlertDialog.Content style={{ maxWidth: 450 }}>
    <AlertDialog.Title>{title}</AlertDialog.Title>
    <AlertDialog.Description size="2">
      {description}
    </AlertDialog.Description>

    <Flex gap="3" mt="4" justify="end">
      <AlertDialog.Cancel>
        <Button variant="soft" color="gray">
          {cancelText}
        </Button>
      </AlertDialog.Cancel>
      <AlertDialog.Action>
        <Button onClick={onClick} variant="solid" color="red">
          {actionText}
        </Button>
      </AlertDialog.Action>
    </Flex>
  </AlertDialog.Content>
</AlertDialog.Root>
)
}