import { injectable, inject } from "inversify";
import { makeObservable, computed, action, observable } from "mobx";
import { MessagesPresenter } from "../Core/Messages/MessagesPresenter";
import { ProductDetailRepository } from "./ProductDetailRepository";

@injectable()
export class ProductDetailPresenter extends MessagesPresenter {
  @inject(ProductDetailRepository)
  productDetailRepository: ProductDetailRepository;

  modifiedProduct: Product = { name: "" };

  get viewModel() {
    return this.productDetailRepository.product;
  }

  get status() {
    return this.productDetailRepository.status;
  }

  get statusString() {
    let statusString: string;
    switch (this.productDetailRepository.status) {
      case "LOADING":
        statusString = this.locale.translate("LOADING");
        break;
      case "ERROR":
        statusString = this.locale.translate("ERROR");
        break;
      case "LOADED":
        statusString = this.locale.translate("LOADED");
        break;
      default:
        statusString = this.locale.translate("LOADING");
    }
    return statusString;
  }

  constructor() {
    super();
    makeObservable(this, {
      modifiedProduct: observable,
      viewModel: computed,
      status: computed,
      load: action,
      preloadProductDetails: action,
      updateProduct: action,
    });
    this.init();
  }

  load = async (id: string) => {
    await this.productDetailRepository.load(id);
  };

  preloadProductDetails = async () => {
    if (!this.productDetailRepository.product) {
      throw new Error("Product not found");
    }
    this.modifiedProduct = {
      name: this.productDetailRepository.product.name,
      url: this.productDetailRepository.product.url,
      pvp: this.productDetailRepository.product.pvp,
      pvp_min: this.productDetailRepository.product.pvp_min,
      status: this.productDetailRepository.product.status,
      notes: this.productDetailRepository.product.notes,
      category: this.productDetailRepository.product.category,
      additional_cost: this.productDetailRepository.product.additional_cost,
      quantity: this.productDetailRepository.product.quantity,
      vat_percentage: this.productDetailRepository.product.vat_percentage,
      hazmat: this.productDetailRepository.product.hazmat,
      return_rate: this.productDetailRepository.product.return_rate,
      marketing_rate: this.productDetailRepository.product.marketing_rate,
    };
  };

  updateProduct = async () => {
    await this.productDetailRepository.update(this.modifiedProduct as Product);
  };
}
