import { observer } from "mobx-react";
import { withInjection } from "../../../Core/Providers/withInjection";
import { ProductListPresenter } from "../../../Products/ProductListPresenter";
import { Router } from "../../../Routing/Router";
import { ListItem } from "./ListItem";
import { ListHeader } from "./ListHeader";
import classes from "./SimpleList.module.css";
import { ScrollArea } from "@radix-ui/themes";

const SimpleListComp: React.FC<{
  presenter: ProductListPresenter;
  router: Router;
}> = ({ presenter, router }) => {
  const goTo = async (productId: string) => {
    await router.goToPath(`/products/${productId}`);
  };
  const deleteProduct = async (productId: string) => {
    await presenter.deleteProduct(productId);
  };
  return (
    <ScrollArea className={classes.mainWrapper} size="2">
      <div className={classes.listContainer}>
        <ListHeader presenter={presenter}/>
        {presenter.viewModel.map((product) => (
          <ListItem
            key={product.id}
            product={product}
            goTo={goTo}
            deleteProd={deleteProduct}
            presenter={presenter}
          />
        ))}
        {presenter.status === "LOADING" && <div>{presenter.statusHint}</div>}
        {presenter.viewModel.length === 0 && presenter.status === "LOADED" && (
          <div
            style={{
              display: "flex",
              height: "50vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {presenter.statusHint}
          </div>
        )}
      </div>
    </ScrollArea>
  );
};

export const SimpleList = withInjection({
  presenter: ProductListPresenter,
  router: Router,
})(observer(SimpleListComp));
