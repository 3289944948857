import { injectable, inject } from "inversify";
import TreeModel from "tree-model";
// import { AuthenticationRepository } from '../Authentication/AuthenticationRepository'
import { Router } from "../Routing/Router";
import { Locale } from "../Core/Locale";
import { makeAutoObservable } from "mobx";

type NavigationModel = {
  id: string;
  type: "root" | "link";
  text: string;
  children: NavigationModel[];
};

export type NavigationNode = TreeModel.Node<NavigationModel>;

@injectable()
export class NavigationRepository {
  //   @inject(AuthenticationRepository)
  //   authenticationRepository

  @inject(Router)
  router: Router;

  @inject(Locale)
  locale: Locale;

  get currentNode(): NavigationNode {
    return this.getTree().all((node) => {
      return node.model.id === this.router.currentRoute.routeId;
    })[0];
  }

  constructor() {
    makeAutoObservable(this);
  }

  getTree() {
    const tree = new TreeModel();

    const root = tree.parse({
      id: "homeLink",
      type: "root",
      text: this.locale.translate("MY_PRODUCTS"),
      children: [
        {
          id: "productDetails",
          type: "link",
          text: this.locale.translate("PRODUCT_DETAILS"),
          children: [],
        },
      ],
    });

    return root;
  }

  back = () => {
    const currentNode = this.currentNode;
    this.router.goToId(currentNode.parent.model.id);
  };
}
