import { observer } from "mobx-react";
import { MessagesPresenter } from "../../Core/Messages/MessagesPresenter";
import { Button } from "../Button/Button";

type variants = "classic" | "solid" | "soft" | "surface" | "outline" | "ghost" | undefined

const flags = {
  en: "🇺🇸",
  es: "🇪🇸",
};

const LocaleSwitcher = observer(
  ({ presenter, variant }: { presenter: MessagesPresenter, variant?: variants }) => {
    const newLocale = presenter.locale.currentLocale === "en" ? "es" : "en";
    return (
      <Button variant={variant ? variant : "classic"} style={{marginLeft: "1rem"}} onClick={() => presenter.locale.setLocale(newLocale)}>
        {flags[presenter.locale.currentLocale]}
      </Button>
    );
  }
);



export default LocaleSwitcher;