import "reflect-metadata";
import ReactDOM from "react-dom/client";
import { App } from "./App.tsx";
import { ValidationProvider } from "./Core/Providers/Validation.tsx";
import "./reset.css";
import "./index.css";
import "@radix-ui/themes/styles.css";
import "./theme-config.css"
import { InjectionProvider } from "./Core/Providers/Injection.tsx";
import { container } from "./AppIOC.ts";
import { configure } from "mobx";
import { Theme } from "@radix-ui/themes";
import * as Sentry from "@sentry/react";

configure({
  enforceActions: "never",
  computedRequiresReaction: false,
  reactionRequiresObservable: false,
  observableRequiresReaction: false,
  disableErrorBoundaries: false,
});

//...

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


ReactDOM.createRoot(document.getElementById("root")!).render(
  // <React.StrictMode>
  <InjectionProvider container={container}>
    <ValidationProvider>
      <Theme style={{height: "100%", overflowY: "scroll"}}>
        <App />
      </Theme>
    </ValidationProvider>
  </InjectionProvider>
  // </React.StrictMode>
);
