import React from "react";
import { observer } from "mobx-react";
import Toast from "./Toast";
import styles from "./ToastShelf.module.css";
import { AppPresenter } from "../../../AppPresenter";

const ToastShelf: React.FC<{
  presenter: AppPresenter;
}> = observer(({ presenter }) => {
  if (presenter.toastMessages === null) return null;

  return (
    <ol
      className={styles.wrapper}
      role="region"
      aria-live="polite"
      aria-label="Notifications"
    >
      {presenter.toastMessages.map((toast) => (
          <Toast
            variant={toast.type}
            onClose={() => presenter.closeToast(toast.id)}
            isOpen
            key={toast.id}
            closing={toast.closing}
          >
            {toast.message}
          </Toast>
      ))}
    </ol>
  );
});

export default ToastShelf;
