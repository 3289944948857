import { observer } from "mobx-react";
import { QuotePresenter } from "../../../../Products/QuotePresenter";
import classes from "./PriceBreaks.module.css";
import { AddBreakInline } from "./AddBreakInline";
import { AlertDialogButton } from "../../../../Components/AlertDialog/AlertDialog";
import { Button } from "../../../../Components/Button/Button";
import { Pencil1Icon, Cross2Icon } from "@radix-ui/react-icons";
import { InfoHoverCard } from "../../../../Components/InfoTooltip/InfoTooltip";

const BreakInlineComp: React.FC<{
  presenter: QuotePresenter;
  type: "Manufacturing" | "Shipping";
  priceBreak: PriceBreak;
}> = ({ presenter, type, priceBreak }) => {
  const isUpdate = presenter.editingPriceBreak.id === priceBreak.id;

  return isUpdate ? (
    <AddBreakInline presenter={presenter} type={type} priceBreak={priceBreak} />
  ) : (
    <div className={classes.priceBreakRow} key={priceBreak.id}>
      <div className={classes.priceRowQty}>{priceBreak.quantity}</div>
      <div className={classes.priceRowPrice}>
        {priceBreak.price} {priceBreak.currency === "EUR" ? "€" : "$"}
      </div>
      <div>{priceBreak.incoterm}</div>
      <div>
        <InfoHoverCard title="" content={presenter.locale.translate("EDIT_PRICE_BREAK")}>
          <Button
            variant="soft"
            onClick={() =>
              priceBreak.id && presenter.setEditingPriceBreak(priceBreak.id)
            }
          >
            <Pencil1Icon width="1rem" height="1rem" />
          </Button>
        </InfoHoverCard>

        <AlertDialogButton
          title={presenter.locale.translate("DELETE_PRICE_BREAK")}
          description={presenter.locale.translate("CONFIRM_DELETE_PRICE_BREAK")}
          cancelText={presenter.locale.translate("CANCEL")}
          actionText={presenter.locale.translate("DELETE")}
          onClick={() =>
            presenter.deletePriceBreak(type, priceBreak?.id as string)
          }
        >
          <InfoHoverCard title="" content={presenter.locale.translate("DELETE_PRICE_BREAK")}>
            <Cross2Icon width="1rem" height="1rem" />
          </InfoHoverCard>
        </AlertDialogButton>
      </div>
    </div>
  );
};

export const BreakInline = observer(BreakInlineComp);
