import React from "react";
import { observer } from "mobx-react";
import { QuotePresenter } from "../../../../../Products/QuotePresenter";
import { Flex, Text, TextArea, TextField } from "@radix-ui/themes";

const EditSupplierComp: React.FC<{ presenter: QuotePresenter }> = ({
  presenter,
}) => {
  const handleInputChange = (e: React.BaseSyntheticEvent): void => {
    const name = e.target.name as "name" | "contact_name" | "url";
    const value = e.target.value;
    if (!presenter.supplierDetails) {
      presenter.supplierDetails = {};
    }
    presenter.supplierDetails[name] = value;
  };

  if (!presenter.supplierDetails) {
    return <div>{presenter.locale.translate("NO_SUPPLIER_DETAILS")}</div>;
  }
  return (
    <Flex direction="column" gap="3">
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {presenter.locale.translate("MANUFACTURER_NAME")}
        </Text>
        <TextField.Root
          placeholder={presenter.locale.translate("MANUFACTURER_NAME")}
          value={presenter.supplierDetails.name as string}
          name="name"
          onChange={handleInputChange}
        />
      </label>
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {presenter.locale.translate("MANUFACTURER_CONTACT_NAME")}
        </Text>
        <TextField.Root
          placeholder={presenter.locale.translate("MANUFACTURER_CONTACT_NAME")}
          value={presenter.supplierDetails.contact_name as string}
          name="contact_name"
          onChange={handleInputChange}
        />
      </label>
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {presenter.locale.translate("CONTACT_CHAT_DESCRIPTION")}
        </Text>
        <TextField.Root
          placeholder={presenter.locale.translate("CONTACT_CHAT_URL")}
          value={presenter.supplierDetails.url as string}
          name="url"
          onChange={handleInputChange}
        />
      </label>
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {presenter.locale.translate("CONTACT_PHONE")}
        </Text>
        <TextField.Root
          placeholder={presenter.locale.translate("CONTACT_PHONE")}
          value={presenter.supplierDetails.phone as string}
          name="phone"
          onChange={handleInputChange}
        />
      </label>
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {presenter.locale.translate("EMAIL")}
        </Text>
        <TextField.Root
          placeholder={presenter.locale.translate("EMAIL")}
          value={presenter.supplierDetails.email as string}
          name="email"
          onChange={handleInputChange}
        />
      </label>
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {presenter.locale.translate("ADDRESS")}
        </Text>
        <TextArea
          placeholder={presenter.locale.translate("ADDRESS")}
          value={presenter.supplierDetails.address as string}
          name="address"
          onChange={handleInputChange}
        />
      </label>
      <label>
        <Text as="div" size="2" mb="1" weight="bold">
          {presenter.locale.translate("NOTES")}
        </Text>
        <TextArea
          placeholder={presenter.locale.translate("NOTES")}
          value={presenter.supplierDetails.notes as string}
          name="notes"
          onChange={handleInputChange}
        />
      </label>
    </Flex>
  );
};

export const EditSupplier = observer(EditSupplierComp);
