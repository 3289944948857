import { injectable, inject } from "inversify";
import { Config } from "../Core/Config";
import { Locale } from "../Core/Locale";
import { makeObservable, observable, action, reaction, comparer } from "mobx";
import { Types } from "../Core/Types";
import { type IProductGateway } from "./ProductListRepository";
import { MessagePacking } from "../Core/Messages/MessagePacking";
import { debounce } from "lodash-es";
import { MessagesRepository } from "../Core/Messages/MessagesRepository";

@injectable()
export class ProductDetailRepository {
  @inject(Types.IDataGateway)
  dataGateway: IProductGateway;

  @inject(Config)
  config: Config;

  @inject(Locale)
  locale: Locale;

  @inject(MessagesRepository)
  messagesRepository: MessagesRepository;

  status: RepoStatus = "UNINITIALIZED";
  statusMsg: string = "";

  productStatus: RepoStatus = "UNINITIALIZED";
  suppliersStatus: RepoStatus = "UNINITIALIZED";
  exchangeRateStatus: RepoStatus = "UNINITIALIZED";

  product: Product | null = null;

  suppliers: Supplier[] | null = null;

  productBuffer: Product | null = null;

  exchangeRateEURUSD: number = 1.08;

  autonomoMode: boolean = false;

  reactionDisposer: (() => void) | null = null;

  constructor() {
    makeObservable(this, {
      product: observable,
      status: observable,
      statusMsg: observable,
      suppliers: observable,
      productBuffer: observable,
      exchangeRateEURUSD: observable,
      autonomoMode: observable,
      load: action,
      reset: action,
      update: action,
      updateBuffer: action,
      saveAndUpdateProduct: action,
      saveQuote: action,
      addSupplier: action,
      updateSupplier: action,
      addQuote: action,
      updateFees: action,
      addPriceBreak: action,
    });
  }
  load = async (id: string) => {
    this.status = "LOADING";
    try {
      await this.loadProduct(id);
      await this.loadSuppliers();
      await this.loadExchangeRate();
      this.status = "LOADED";
    } catch (error) {
      this.status = "ERROR";
      if (error instanceof Error) {
        this.statusMsg = error.message;
      }
    }
  };

  loadProduct = async (id: string) => {
    this.productStatus = "LOADING";
    try {
      const dto = await this.dataGateway.get<Product>("/products/" + id);
      this.product = dto.result.data;
      this.statusMsg = dto.result.message ? dto.result.message : "";

      this.productBuffer = dto.result.data;

      this.productStatus = "LOADED";
      this.reactionDisposer = reaction(
        () => ({
          pvp: this.productBuffer?.pvp,
          category: this.productBuffer?.category,
          hazmat: this.productBuffer?.hazmat,
        }),
        debounce(() => {
          this.updateFees();
        }, 500),
        { equals: comparer.structural }
      );

      // this.messagesRepository.addToast(
      //   "Detalles de producto cargados con éxito",
      //   "success"
      // );
      return MessagePacking.unpackServerDtoToPm(dto);
    } catch (error) {
      this.productStatus = "ERROR";

      this.statusMsg =
        error instanceof Error
          ? error.message
          : "unexpected error at product detail repo";
      this.messagesRepository.addToast(
        this.locale.translate("ERROR_LOADING_DETAILS"),
        "error"
      );
      return MessagePacking.unpackServerDtoToPm({
        success: false,
        result: { message: this.statusMsg },
      });
    }
  };

  loadSuppliers = async () => {
    this.suppliersStatus = "LOADING";
    try {
      const supplierDto = await this.dataGateway.get<Supplier[]>("/suppliers/");
      this.suppliers = supplierDto.result.data;
      this.statusMsg = supplierDto.result.message
        ? supplierDto.result.message
        : "";
      this.suppliersStatus = "LOADED";
      // this.messagesRepository.addToast(
      //   "Fabricantes cargados con éxito",
      //   "success"
      // );
      return MessagePacking.unpackServerDtoToPm(supplierDto);
    } catch (error) {
      this.suppliersStatus = "ERROR";
      this.statusMsg =
        error instanceof Error
          ? error.message
          : "unexpected error at product detail repo";
      this.messagesRepository.addToast(this.locale.translate("ERROR_LOADING_MANUFACTURERS"), "error");
      return MessagePacking.unpackServerDtoToPm({
        success: false,
        result: { message: this.statusMsg },
      });
    }
  };

  loadExchangeRate = async () => {
    try {
      this.exchangeRateStatus = "LOADING";
      const exchangeRateDto = await this.dataGateway.get<ExchangeRate>(
        "/exchange-rates/EUR/USD"
      );
      this.exchangeRateEURUSD = exchangeRateDto.result.data.rate;
      this.statusMsg = exchangeRateDto.result.message
        ? exchangeRateDto.result.message
        : "";
      this.exchangeRateStatus = "LOADED";
      // this.messagesRepository.addToast("Tipo de cambio cargado con éxito", "success");
      return MessagePacking.unpackServerDtoToPm(exchangeRateDto);
    } catch (error) {
      this.exchangeRateStatus = "ERROR";
      this.statusMsg =
        error instanceof Error
          ? error.message
          : "unexpected error at product detail repo";
      this.messagesRepository.addToast(
        this.locale.translate("ERROR_LOADING_EXCHANGE_RATES"),
        "error"
      );
      return MessagePacking.unpackServerDtoToPm({
        success: false,
        result: { message: this.statusMsg },
      });
    }
  };

  reset = () => {
    this.status = "RESET";
    this.statusMsg = "";
    this.productStatus = "RESET";
    this.suppliersStatus = "RESET";
    this.exchangeRateStatus = "RESET";
    this.product = null;
    this.suppliers = null;
    this.reactionDisposer?.call(this);
  };

  update = async (product: Product, reloadFromBack: boolean = true) => {
    if (this.product === null) throw new Error("Product not found");
    this.productStatus = "LOADING";
    try {
      const dto = await this.dataGateway.put(
        "/products/" + this.product.id,
        product
      );
      if (reloadFromBack) {
        this.product = dto.result.data;
        this.updateBuffer(dto.result.data);
      }
      this.statusMsg = dto.result.message ? dto.result.message : "";
      this.productStatus = "LOADED";
      this.messagesRepository.addToast(
        this.locale.translate("PRODUCT_UPDATED"),
        "success"
      );
      return MessagePacking.unpackServerDtoToPm(dto);
    } catch (error) {
      this.productStatus = "ERROR";

      this.statusMsg =
        error instanceof Error
          ? error.message
          : "unexpected error at product detail repo";
      this.messagesRepository.addToast(
        this.locale.translate("ERROR_UPDATING_PRODUCT"),
        "error"
      );
      return MessagePacking.unpackServerDtoToPm({
        success: false,
        result: { message: this.statusMsg },
      });
    }
  };

  // created to avoid warning when updating product buffer with no mobx action
  updateBuffer = (product: Partial<Product>) => {
    this.productBuffer = product as Product;
  };

  saveAndUpdateProduct = async () => {
    this.product = {
      ...(this.product as Product),
      pvp: this.productBuffer?.pvp,
      category: this.productBuffer?.category,
      hazmat: this.productBuffer?.hazmat,
      quantity: this.productBuffer?.quantity,
    };
    this.update(this.product, false);
  };

  saveQuote = async (quote: Quote) => {
    try {
      this.status = "LOADING"
      const dto = await this.dataGateway.put<Quote>(
        "/quotes/" + quote.id,
        quote
      );
      const indexToUpdate = this.product?.quotes?.findIndex(
        (q) => q.id === quote.id
      ) as number;
      if (indexToUpdate === -1) throw new Error("Quote not found");
      if (this.product?.quotes === undefined)
        throw new Error("Quotes not found");
      this.product.quotes[indexToUpdate] = {
        ...this.product.quotes[indexToUpdate],
        ...dto.result.data,
      };
      this.status = "LOADED";
      this.statusMsg = dto.result.message ? dto.result.message : "";
      this.messagesRepository.addToast(
        this.locale.translate("QUOTE_SAVED"),
        "success"
      );
      return MessagePacking.unpackServerDtoToPm(dto);
    } catch (error) {
      this.statusMsg =
        error instanceof Error
          ? error.message
          : "unexpected error at product detail repo";
      this.messagesRepository.addToast(this.locale.translate("ERROR_SAVING_QUOTE"), "error");
      return MessagePacking.unpackServerDtoToPm({
        success: false,
        result: { message: this.statusMsg },
      });
    }
  };

  // this function updates all quotes but may be called from several quotes at the same time
  // so we need to debounce it
  updateFees = debounce(async () => {
    const quotes = this.product?.quotes;
    const feeInputs = quotes?.map((quote) => {
      return {
        length: quote.package_length,
        width: quote.package_width,
        height: quote.package_height,
        weight: quote.package_weight,
        country: "ES",
        shipping_type: "Local",
        price: this.productBuffer?.pvp,
        category: this.productBuffer?.category,
        hazmat: this.productBuffer?.hazmat,
      } as FeeInput;
    });
    if (!feeInputs) throw new Error("No quotes found");
    try {
      const newFeesDto = await this.dataGateway.post<FeeInput[], FeeOutput[]>(
        "/fees/",
        feeInputs
      );
      if (newFeesDto.success && newFeesDto.result.data.length !== 0) {
        // map new fees to quotes
        const newFees = newFeesDto.result.data.map((quote) => {
          return {
            reference_fee_calculated: quote.reference_fee,
            logistic_fee_calculated: quote.logistic_fee,
            storage_fee_calculated: quote.storage_fee_jan_sep,
            storage_fee_xmas_calculated: quote.storage_fee_oct_dec,
          };
        });

        // update quotes with new fees
        this.product?.quotes?.forEach((quote, index) => {
          if (this.product?.quotes === undefined)
            throw new Error("Quotes not found");
          this.product.quotes[index] = { ...quote, ...newFees[index] };
        });

        // copy new fees to product and save with new fees on quotes
        this.product = {
          ...(this.product as Product),
          pvp: this.productBuffer?.pvp,
          category: this.productBuffer?.category,
          hazmat: this.productBuffer?.hazmat,
        };

        this.update(this.product, false);
      }
      return MessagePacking.unpackServerDtoToPm(newFeesDto);
    } catch (error) {
      this.statusMsg =
        error instanceof Error
          ? error.message
          : "unexpected error at product detail repo";
      console.error("Error getting new fees", this.statusMsg);
      return MessagePacking.unpackServerDtoToPm({
        success: false,
        result: { message: this.statusMsg },
      });
    }
  }, 500);

  addSupplier = async (supplier: Partial<Supplier>) => {
    try {
      const dto = await this.dataGateway.post<Supplier, Supplier>(
        "/suppliers/",
        supplier
      );
      this.suppliers?.push(dto.result.data);
      this.statusMsg = dto.result.message ? dto.result.message : "";
      this.messagesRepository.addToast(
        this.locale.translate("MANUFACTURER_ADDED"),
        "success"
      );
      return dto.result.data;
    } catch (error) {
      this.statusMsg =
        error instanceof Error
          ? error.message
          : "unexpected error at product detail repo";
      this.messagesRepository.addToast(this.locale.translate("ERROR_ADDING_MANUFACTURER"), "error");
      return MessagePacking.unpackServerDtoToPm({
        success: false,
        result: { message: this.statusMsg },
      });
    }
  };

  updateSupplier = async (supplier: Partial<Supplier>) => {
    try {
      const dto = await this.dataGateway.put<Supplier>(
        "/suppliers/" + supplier.id,
        supplier as Supplier
      );
      // modify supplier in suppliers array
      const supplierIndex = this.suppliers?.findIndex(
        (s) => s.id === supplier.id
      ) as number;
      if (supplierIndex === -1) throw new Error("Supplier not found");
      if (!this.suppliers) throw new Error("Suppliers not found");
      this.suppliers[supplierIndex] = {
        ...this.suppliers[supplierIndex],
        ...dto.result.data,
      };
      this.statusMsg = dto.result.message ? dto.result.message : "";
      this.messagesRepository.addToast(
        this.locale.translate("MANUFACTURER_UPDATED"),
        "success"
      );
      return MessagePacking.unpackServerDtoToPm(dto);
    } catch (err) {
      this.statusMsg =
        err instanceof Error
          ? err.message
          : "unexpected error at product detail repo";
      this.messagesRepository.addToast(
        this.locale.translate("ERROR_UPDATING_MANUFACTURER"),
        "error"
      );
    }
  };

  addQuote = async (quote: Partial<Quote>) => {
    try {
      const dto = await this.dataGateway.post<Quote, Quote>("/quotes/", quote);
      this.product?.quotes?.push(dto.result.data);
      this.statusMsg = dto.result.message ? dto.result.message : "";
      this.messagesRepository.addToast(
        this.locale.translate("QUOTE_ADDED"),
        "success"
      );
      MessagePacking.unpackServerDtoToPm(dto);
      return dto.result.data;
    } catch (error) {
      this.statusMsg =
        error instanceof Error
          ? error.message
          : "unexpected error at product detail repo";
      this.messagesRepository.addToast(this.locale.translate("ERROR_ADDING_QUOTE"), "error");
      MessagePacking.unpackServerDtoToPm({
        success: false,
        result: { message: this.statusMsg },
      });
      return;
    }
  };

  removeQuote = async (quoteId: string) => {
    try {
      const dto = await this.dataGateway.delete<string, Quote>(
        "/quotes/" + quoteId
      );
      const indexToRemove = this.product?.quotes?.findIndex(
        (q) => q.id === quoteId
      ) as number;
      if (indexToRemove === -1) throw new Error("Quote not found");
      if (this.product?.quotes === undefined)
        throw new Error("Quotes not found");
      this.product.quotes.splice(indexToRemove, 1);
      this.statusMsg = dto.result.message ? dto.result.message : "";
      this.messagesRepository.addToast(
        this.locale.translate("QUOTE_REMOVED"),
        "success"
      );
      return MessagePacking.unpackServerDtoToPm(dto);
    } catch (error) {
      this.statusMsg =
        error instanceof Error
          ? error.message
          : "unexpected error at product detail repo";
      this.messagesRepository.addToast(this.locale.translate("ERROR_REMOVING_QUOTE"), "error");
      return MessagePacking.unpackServerDtoToPm({
        success: false,
        result: { message: this.statusMsg },
      });
    }
  };

  addPriceBreak = async (priceBreak: Partial<PriceBreak>) => {
    try {
      const dto = await this.dataGateway.post<Partial<PriceBreak>, PriceBreak>(
        "/price_breaks/",
        priceBreak
      );

      const quote = this.product?.quotes?.find(
        (q) => q.id === priceBreak.quote_id
      );
      if (!quote) throw new Error("Quote not found");
      if (priceBreak.type === "Manufacturing") {
        quote.manufacturing_price_breaks?.push(dto.result.data);
      } else if (priceBreak.type === "Shipping") {
        quote.shipping_price_breaks?.push(dto.result.data);
      }
      this.statusMsg = dto.result.message ? dto.result.message : "";
      this.messagesRepository.addToast(this.locale.translate("PRICE_BREAK_ADDED"), "success");
      return MessagePacking.unpackServerDtoToPm(dto);
    } catch (error) {
      this.statusMsg =
        error instanceof Error
          ? error.message
          : "unexpected error at product detail repo";
      this.messagesRepository.addToast(this.locale.translate("ERROR_ADDING_PRICE_BREAK"), "error");
      return MessagePacking.unpackServerDtoToPm({
        success: false,
        result: { message: this.statusMsg },
      });
    }
  };

  deletePriceBreak = async (
    type: "Manufacturing" | "Shipping",
    priceBreakId: string,
    quoteId: string
  ) => {
    try {
      const dto = await this.dataGateway.delete<string, PriceBreak>(
        "/price_breaks/" + priceBreakId
      );
      const quote = this.product?.quotes?.find((q) => q.id === quoteId);
      if (!quote) throw new Error("Quote not found");
      if (type === "Manufacturing") {
        const manufacturingPriceBreakIndex =
          quote.manufacturing_price_breaks?.findIndex(
            (pb) => pb.id === priceBreakId
          );
        quote.manufacturing_price_breaks?.splice(
          manufacturingPriceBreakIndex,
          1
        );
      } else if (type === "Shipping") {
        const shippingPriceBreakIndex = quote.shipping_price_breaks?.findIndex(
          (pb) => pb.id === priceBreakId
        );
        quote.shipping_price_breaks?.splice(shippingPriceBreakIndex, 1);
      }
      this.messagesRepository.addToast(
        this.locale.translate("PRICE_BREAK_REMOVED"),
        "success"
      );
      return MessagePacking.unpackServerDtoToPm(dto);
    } catch (error) {
      this.statusMsg =
        error instanceof Error
          ? error.message
          : "unexpected error at product detail repo";
      this.messagesRepository.addToast(this.locale.translate("ERROR_REMOVING_PRICE_BREAK"), "error");
      return MessagePacking.unpackServerDtoToPm({
        success: false,
        result: { message: this.statusMsg },
      });
    }
  };

  savePriceBreak = async (priceBreak: PriceBreak) => {
    try {
      const priceBreakId = priceBreak.id;
      const quoteId = priceBreak.quote_id;
      const type = priceBreak.type;
      const dto = await this.dataGateway.put<PriceBreak>(
        "/price_breaks/" + priceBreakId,
        priceBreak
      );
      const quoteIndexToUpdate = this.product?.quotes?.findIndex(
        (q) => q.id === quoteId
      ) as number;
      if (quoteIndexToUpdate === -1) throw new Error("Quote not found");
      if (this.product?.quotes === undefined)
        throw new Error("Quotes not found");
      const quote = this.product.quotes[quoteIndexToUpdate];
      if (type === "Manufacturing") {
        const mfPriceBreakIndex = quote.manufacturing_price_breaks?.findIndex(
          (pb) => pb.id === priceBreakId
        );
        quote.manufacturing_price_breaks[mfPriceBreakIndex] = {
          ...quote.manufacturing_price_breaks[mfPriceBreakIndex],
          ...dto.result.data,
        };
      } else if (type === "Shipping") {
        const shpPriceBreakIndex = quote.shipping_price_breaks?.findIndex(
          (pb) => pb.id === priceBreakId
        );
        quote.shipping_price_breaks[shpPriceBreakIndex] = {
          ...quote.shipping_price_breaks[shpPriceBreakIndex],
          ...dto.result.data,
        };
      }

      this.statusMsg = dto.result.message ? dto.result.message : "";
      this.messagesRepository.addToast(
        this.locale.translate("PRICE_BREAK_SAVED"),
        "success"
      );
      return MessagePacking.unpackServerDtoToPm(dto);
    } catch (error) {
      this.statusMsg =
        error instanceof Error
          ? error.message
          : "unexpected error at product detail repo";
      this.messagesRepository.addToast(
        this.locale.translate("ERROR_SAVING_PRICE_BREAK"),
        "error"
      );
      return MessagePacking.unpackServerDtoToPm({
        success: false,
        result: { message: this.statusMsg },
      });
    }
  };

  switchAutonomoMode = async () => {
    this.autonomoMode = !this.autonomoMode;
  };
}
