import React from "react";

export const useKeyShortCutWithModifier = (
  keyCodeString: string,
  callback: () => void,
  modifier: "ctrl" | "alt" | "shift" = "alt"
) => {
  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === keyCodeString && event[`${modifier}Key`]) {
        callback();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [keyCodeString, callback, modifier]);
};
