// export { Button as Button } from "@radix-ui/themes"

import React from "react";
import { Button as BaseButton } from "@radix-ui/themes";

export const Button = React.forwardRef<
  React.ElementRef<typeof BaseButton>,
  React.ComponentPropsWithoutRef<typeof BaseButton>
>((props, forwardedRef) => {
  const { style } = props;
  return (
    <BaseButton
      {...props}
      style={{ ...style, cursor: "pointer" }}
      ref={forwardedRef}
    />
  );
});
Button.displayName = "Button";
