export const Types = {
  IRouterGateway: Symbol.for("IRouterGateway"),
  IDataGateway: Symbol.for("IDataGateway"),
  ILocalStorageGateway: Symbol.for("ILocalStorageGateway"),
  IAuthGateway: Symbol.for("IAuthGateway"),
};

declare global {
  type FeeInput = {
    length: number;
    width: number;
    height: number;
    weight: number;
    country: string;
    shipping_type: string;
    price: number;
    category: string;
    hazmat: boolean;
  };

  type FeeOutput = {
    reference_fee: number;
    logistic_fee: number;
    storage_fee?: number;
    storage_fee_jan_sep: number;
    storage_fee_oct_dec: number;
    package_type: string;
    small_logistics_eligible: boolean;
  };

  type PriceBreak = {
    quantity: number | null;
    price: number | null;
    incoterm: Incoterms | null;
    currency: Currencies | null;
    type?: "Manufacturing" | "Shipping" | null;
    quote_id?: string;
    id?: string | null;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string | null;
    is_deleted?: boolean;
  };

  export type Quote = {
    margin: number | null;
    roi: number | null;
    alibaba_url: string | null;
    chat_url: string | null;
    notes: string | null;
    stock_lead_time: string | null;
    manufacturing_lead_time: string | null;
    use_manufacturing_price_breaks: boolean;
    manufacturing_cost: number | null;
    manufacturing_price_breaks: PriceBreak[];
    manufacturing_cost_currency: Currencies | null;
    package_length: number | null;
    package_width: number | null;
    package_height: number | null;
    package_weight: number | null;
    packaging_cost: number | null;
    import_fees: number | null;
    import_fees_is_percentage: boolean | null;
    reference_fee: number | null;
    logistic_fee: number | null;
    storage_fee: number | null;
    storage_fee_xmas: number | null;
    reference_fee_calculated: number | null;
    logistic_fee_calculated: number | null;
    storage_fee_calculated: number | null;
    storage_fee_xmas_calculated: number | null;
    reference_fee_overridden: boolean;
    logistic_fee_overridden: boolean;
    storage_fee_overridden: boolean;
    storage_fee_xmas_overridden: boolean;
    hs_code: string | null;
    num_items_per_box: number | null;
    num_boxes?: number | null;
    box_length: number | null;
    box_width: number | null;
    box_height: number | null;
    box_weight: number | null;
    use_shipping_price_breaks: boolean;
    shipping_cost: number | null;
    shipping_price_breaks: PriceBreak[];
    shipping_cost_currency: Currencies | null;
    supplier_id: string;
    product_id: string;
    id: string;
    is_deleted?: boolean;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string | null;
    supplier?: Supplier;
    fee_calculation?: FeeOutput;
  };

  type OverridableFees =
    | "reference_fee_overridden"
    | "logistic_fee_overridden"
    | "storage_fee_overridden"
    | "storage_fee_xmas_overridden";

  type ExchangeRate = {
    from_currency: Currencies;
    to_currency: Currencies;
    rate: number;
    id: string;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    is_deleted: boolean;
  };

  type Supplier = {
    name?: string | null;
    contact_name: string | null;
    whatsapp?: string | null;
    phone?: string | null;
    wechat?: string | null;
    email?: string | null;
    address?: string | null;
    url?: string | null;
    notes?: string | null;
    feeling?: string | null;
    id: string;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string | null;
    is_deleted?: boolean;
    owner_id?: string;
    quotes?: string[];
    products: Product[];
  };

  type Currencies = (typeof currencies)[number];

  type Incoterms = (typeof incoterms)[number];

  type ProductStatus = (typeof productStatuses)[number];

  type Categories = (typeof categories)[number];

  type RepoStatus = "UNINITIALIZED" | "LOADING" | "LOADED" | "ERROR" | "RESET";

  type ProductDto<T> = {
    success: true;
    result: {
      message?: string;
      data: T;
    };
  };

  type createAPIMethod<
    TInput extends Record<string, string>,
    TOutput,
  > = (opts: {
    url: string;
    headers?: Record<string, string>;
    method: "GET" | "POST" | "PUT" | "DELETE";
  }) => (input: TInput) => Promise<TOutput>;

  type Product = {
    id?: string;
    name: string;
    url?: string;
    pvp?: number | null;
    pvp_min?: number | null;
    status?: ProductStatus;
    notes?: string;
    category?: Categories;
    created_at?: string;
    updated_at?: string;
    is_deleted?: boolean;
    owner_id?: string;
    deleted_at?: string | null;
    quotes?: Quote[];
    hazmat?: boolean | null;
    vat_percentage?: number | null;
    additional_cost?: number | null;
    quantity?: number | null;
    chosen_incoterm?: Incoterms | null;
    return_rate?: number | null;
    marketing_rate?: number | null;
  };
}

export type TProduct = Product;
export type TQuote = Quote;
export type TPriceBreak = PriceBreak;

export const currencies = [
  "USD",
  "EUR",
  // "GBP",
  // "JPY",
  // "CNY",
  // "RUB",
  // "INR",
  // "BRL",
  // "AUD",
  // "CAD",
  // "CHF",
  // "SEK",
  // "NZD",
  // "MXN",
  // "SGD",
  // "HKD",
  // "NOK",
  // "KRW",
  // "TRY",
  // "ZAR",
  // "AED",
  // "SAR",
  // "QAR",
  // "EGP",
  // "THB",
  // "MYR",
  // "IDR",
  // "VND",
  // "PHP",
  // "PKR",
  // "KES",
  // "NGN",
  // "GHS",
  // "UGX",
  // "TZS",
  // "ZMW",
  // "MWK",
  // "MZN",
] as const;

export const incoterms = ["EXW", "FOB", "DDP"] as const;

export const productStatuses = [
  "Discarded",
  "In Progress",
  "Reconsider",
  "Selling",
] as const;

export const categories = [
  "Accesorios para dispositivos de Amazon",
  "Coche y Moto",
  "Productos para bebé",
  "Mochilas y bolsos",
  "Belleza, salud y cuidado personal",
  "Cerveza, vino y licores",
  "Libros",
  "Suministros de industria, empresa y ciencia",
  "Ropa y accesorios",
  "Suministros comerciales de electricidad y energía",
  "Electrodomésticos compactos",
  "Informática",
  "Electrónica",
  "Accesorios de ciclismo",
  "Material educativo",
  "Accesorios de electrónica",
  "Gafas y accesorios",
  "Zapatos",
  "Electrodomésticos de tamaño completo",
  "Mobiliario",
  "Alimentación y bebidas",
  "Amazon Handmade",
  "Hogar y cocina",
  "Joyería",
  "Jardín",
  "Equipaje",
  "Colchones",
  "Música, Video y DVD",
  "Instrumentos musicales y producción audiovisual",
  "Oficina y papelería",
  "Productos para mascotas",
  "Software",
  "Deportes y aire libre",
  "Neumáticos",
  "Bricolaje y herramientas",
  "Juguetes y Juegos",
  "Videojuegos y accesorios para videojuegos",
  "Consolas de videojuegos",
  "Relojes",
  "Otros",
] as const;
