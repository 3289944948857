import { withInjection } from "../../../Core/Providers/withInjection";
import { observer } from "mobx-react-lite";
import { ProductListPresenter } from "../../../Products/ProductListPresenter";
import { ButtonDialog } from "../../../Components/DialogButton/DialogButton";
import { AddDialog } from "./AddDialog";
import { FilterMenu } from "../FilterMenu/FilterMenu";
import { PlusCircle } from "react-feather";
import classes from "./TopActions.module.css";

const TopActionsComp: React.FC<{
  presenter: ProductListPresenter;
}> = ({ presenter }) => {
  return (
    <div className={classes.topActions}>
      <FilterMenu />
      <ButtonDialog
        title={presenter.locale.translate("NEW_PRODUCT")}
        description={presenter.locale.translate("INSERT_PRODUCT_DETAILS")}
        cancelText={presenter.locale.translate("CANCEL")}
        confirmText={presenter.locale.translate("ADD")}
        onConfirm={presenter.addProduct}
        body={<AddDialog presenter={presenter} />}
        disabled={presenter.status === "LOADING" || presenter.hasMaxProducts}
        menuDisabled={presenter.hasMaxProducts}
      >
        <PlusCircle width="1rem" height="1rem" />
        {presenter.locale.translate("ADD_PRODUCT")}
      </ButtonDialog>
    </div>
  );
};

export const TopActions = withInjection({
  presenter: ProductListPresenter,
})(observer(TopActionsComp));
