import en from './en.json';
import es from './es.json';

const translations = {
  en,
  es,
};

export type possibleLanguages = keyof typeof translations;
export type possibleKeys = keyof typeof translations["en"];

const translate = (key: possibleKeys, lang: possibleLanguages = "en") => {
  if (translations[lang][key] === undefined) {
    console.error(`Translation for key ${key} not found in language ${lang}`);
    return key.toLocaleLowerCase().replace(/_/g, " ");
  }
  return translations[lang][key] || key;
}

export default translate;