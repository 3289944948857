import classes from "./Quote.module.css";
import { Link, Strong, Text } from "@radix-ui/themes";
import { InfoHoverCard } from "../../../../Components/InfoTooltip/InfoTooltip";
import { QuoteListPresenter } from "../../../../Products/QuoteListPresenter";

export const QuoteHeader: React.FC<{
  presenter: QuoteListPresenter
}> = ({ presenter }) => {
  return (
    <div className={classes.quoteHeader}>
      <Text>{presenter.locale.translate("MANUFACTURER_CONTACT_NAME")}</Text>
      <Text>{presenter.locale.translate("ROI")}</Text>
      <Text>{presenter.locale.translate("MARGIN")}</Text>
      <InfoHoverCard
        title={presenter.locale.translate("MANUFACTURING_COST")}
        content={<>{presenter.locale.translate("MANUFACTURING_COST_TOOLTIP")}</>}
      />
      <InfoHoverCard
        title={presenter.locale.translate("SHIPPING_COST")}
        content={<>{presenter.locale.translate("SHIPPING_COST_TOOLTIP")}</>}
      />
      <InfoHoverCard
        title={presenter.locale.translate("REFERENCE_FEE")}
        content={
          <>
            {presenter.locale.translate("REFERENCE_FEE_TOOLTIP_TEXT")}{" "}
            <Link href={presenter.locale.translate("REFERENCE_FEE_TOOLTIP_URL")} target="_blank">
              {presenter.locale.translate("REFERENCE_FEE_TOOLTIP_URL")}
            </Link>
          </>
        }
      />
      <InfoHoverCard
        title={presenter.locale.translate("LOGISTIC_FEE")}
        content={<>{presenter.locale.translate("LOGISTIC_FEE_TOOLTIP")}</>}
      />
      <InfoHoverCard
        title={presenter.locale.translate("STORAGE_FEE")}
        content={
          <>
            {presenter.locale.translate("STORAGE_FEE_TOOLTIP_1")}<Strong>{presenter.locale.translate("STORAGE_FEE_TOOLTIP_2")}</Strong>.
          </>
        }
      />
      <Text>{presenter.locale.translate("IMPORT_FEES")}</Text>
      <Text>{presenter.locale.translate("ACTIONS")}</Text>
    </div>
  );
};
