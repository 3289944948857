import { observer } from "mobx-react";
import { withInjection } from "../../Core/Providers/withInjection";
import { AuthenticationPresenter } from "../../Authentication/AuthenticationPresenter";
import { Button } from "../Button/Button";
import { LogOut } from "react-feather";

const LogoutComp = observer(
  ({ presenter }: { presenter: AuthenticationPresenter }) => {
    return (
      <Button style={{marginLeft: "auto"}} onClick={presenter.logOut}>
        <LogOut width="1rem" height="1rem"/>
        {presenter.locale.translate("LOGOUT")}
      </Button>
    );
  }
);

const Logout = withInjection({
  presenter: AuthenticationPresenter,
})(LogoutComp);

export default Logout;
