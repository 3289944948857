import { HoverCard, Flex, Text, Blockquote } from "@radix-ui/themes";
import { Cross2Icon, CheckIcon } from "@radix-ui/react-icons";
import { QuotePresenter } from "../../../../Products/QuotePresenter";
import { observer } from "mobx-react";
import { isNumber } from "../../../../Core/utils";

export const PopMarginResult: React.FC<{
  presenter: QuotePresenter;
}> = observer(({ presenter }) => {
  if (!presenter.quote) {
    return <div>{presenter.locale.translate("NO_QUOTES")}</div>;
  }
  const marginValue = isNumber(presenter.computedMargin.result)
    ? presenter.computedMargin.result
    : 0;
  const formattedMarginValue = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 1,
  }).format(marginValue as number);
  const marginMissing = presenter.computedMargin.missing;
  const areMissing = marginMissing.length > 0;

  const variables = presenter.computedMargin.variables;

  const valueSemaphore = (value: number) => {
    if (value < 0.1) {
      return "crimson";
    } else if (value <= 0.2) {
      return "orange";
    } else if (value <= 0.24) {
      return "cyan";
    } else {
      return "indigo";
    }
  };

  return (
    <HoverCard.Root>
      <HoverCard.Trigger>
        <Flex justify="center" align="center" style={{ height: "100%" }}>
          <Blockquote
            style={{
              fontWeight: "bold",
              cursor: "help",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            color={
              areMissing ? "crimson" : valueSemaphore(marginValue as number)
            }
          >
            {areMissing ? presenter.locale.translate("MISSING_DATA") : formattedMarginValue}
            {areMissing ? (
              <Cross2Icon
                width="16"
                height="16"
                style={{ marginLeft: "8px" }}
              />
            ) : (
              <CheckIcon width="16" height="16" style={{ marginLeft: "8px" }} />
            )}
          </Blockquote>
        </Flex>
      </HoverCard.Trigger>
      <HoverCard.Content style={{ width: 360 }}>
        <Flex gap="3" direction={"column"}>
          {areMissing && <Text weight={"bold"}>{presenter.locale.translate("MISSING_VALUES")}:</Text>}
          {presenter.locale.mapStrings(marginMissing).map((missing, index) => (
            <Text key={index}>{missing}</Text>
          ))}

          {!areMissing && <Text weight={"bold"}>{presenter.locale.translate("USED_VALUES")}:</Text>}
          {!areMissing &&
            variables &&
            Object.entries(variables).map((variable, index) => (
              <Flex key={index} justify={"between"}>
                <Text key={index}>{presenter.locale.mapStrings([variable[0]])[0]}: </Text>
                <Text style={{minWidth: "20%"}}>{variable[1]}</Text>
              </Flex>
            ))}
        </Flex>
      </HoverCard.Content>
    </HoverCard.Root>
  );
});
