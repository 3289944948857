import {
  Popover,
  Flex,
  Text,
  TextField,
  Switch,
  RadioGroup,
} from "@radix-ui/themes";
import { Button } from "../../../../Components/Button/Button";
import { ChatBubbleIcon } from "@radix-ui/react-icons";
import { QuotePresenter } from "../../../../Products/QuotePresenter";
import { SelectCategory } from "../../../../Components/SelectCategory/SelectCategory";
import { categories } from "../../../../Core/Types";
import { observer } from "mobx-react";
import { useValidation } from "../../../../Core/Providers/useValidation";
import { MessagesComponent } from "../../../../Core/Messages/MessagesComponent";
import { toDecimals, validateNumber } from "../../../../Core/utils";

// for now we will be using storage_fee_xmas as the storage fee
// this is a temporary solution untile we expose the storage fee selection
// to the user
export const PopStorageFee: React.FC<{
  presenter: QuotePresenter;
}> = observer(({ presenter }) => {
  const [validationMessages, updateClientValidationMessages] = useValidation();

  const handleImportFeechange = (e: React.BaseSyntheticEvent): void => {
    const valid = validateNumber(
      e.target.name,
      e.target.value,
      updateClientValidationMessages,
    );
    if (!valid) return;

    const value = e.target.value === "" ? null : toDecimals(e.target.value);
    presenter.updateAmazonFee("storage_fee_xmas", value);
  };

  const handlePackagingChange = (e: React.BaseSyntheticEvent): void => {
    const valid = validateNumber(
      e.target.name,
      e.target.value,
      updateClientValidationMessages,
    );
    if (!valid) return;
    const value = e.target.value === "" ? null : toDecimals(e.target.value);
    const name = e.target.name;
    presenter.updatePackagingProperty(name, value);
  };

  const cleanAndSave = (opening: boolean) => {
    updateClientValidationMessages([]);
    // if the popover is closing, save the quote
    if (!opening) {
      presenter.setPreventReorder(false);
      presenter.saveQuote();
    } else {
      presenter.setPreventReorder(true);
    }
  };

  // utilities for handling radio inputs
  const boolToRadioValue = (value: boolean | null | undefined): string => {
    if (value === true) {
      return "1";
    } else if (value === false) {
      return "2";
    } else {
      return "3";
    }
  };

  const radioToBoolValue = (value: string): boolean | null => {
    if (value === "1") {
      return true;
    } else if (value === "2") {
      return false;
    } else {
      return null;
    }
  };

  const handleRadioChange = (value: string): void => {
    presenter.setProductBuffer({ hazmat: radioToBoolValue(value) });
  };

  const isValueAvailable =
    (presenter.quote?.storage_fee_xmas_overridden &&
      presenter.quote?.storage_fee_xmas) ||
    (!presenter.quote?.storage_fee_xmas_overridden &&
      presenter.quote?.storage_fee_xmas_calculated);

  if (!presenter.quote) {
    return <div>{presenter.locale.translate("NO_QUOTES")}</div>;
  }

  return (
    <Popover.Root onOpenChange={cleanAndSave}>
      <Popover.Trigger>
        <Button variant="soft" color={isValueAvailable ? "indigo" : "orange"}>
          <ChatBubbleIcon width="16" height="16" />
          {!presenter.quote.storage_fee_xmas_overridden
            ? presenter.quote.storage_fee_xmas_calculated !== null
              ? Number(presenter.quote.storage_fee_xmas_calculated).toFixed(2) +
                "  €"
              : presenter.locale.translate("NO_DATA")
            : presenter.quote.storage_fee_xmas !== null
            ? Number(presenter.quote.storage_fee_xmas).toFixed(2) + "  €"
            : presenter.locale.translate("NO_DATA")}
        </Button>
      </Popover.Trigger>
      <Popover.Content style={{ width: 360 }}>
        <Flex gap="3" direction={"column"}>
          <Text as="label" size="2">
            <Flex gap="2">
              <Switch
                onCheckedChange={(checked) => {
                  presenter.overrideFee("storage_fee_xmas_overridden", checked);
                }}
                checked={presenter.quote.storage_fee_xmas_overridden}
              />{" "}
              {presenter.locale.translate("MANUAL_VALUE")}
            </Flex>
          </Text>
          {presenter.quote.storage_fee_xmas_overridden ? (
            <label>
              <Text as="div" size="2" mb="1" weight="bold">
                {presenter.locale.translate("MANUAL_STORAGE_FEE")}
              </Text>
              <TextField.Root
                placeholder="Tarifa de Almacenamiento"
                value={
                  presenter.quote.storage_fee_xmas !== null
                    ? presenter.quote.storage_fee_xmas
                    : ""
                }
                onChange={handleImportFeechange}
                type="number"
                name="storage_fee_xmas"
                disabled={presenter.status === "LOADING"}
              />
            </label>
          ) : (
            <>
              <label>
                <Text as="div" size="2" mb="1" weight="bold">
                  {presenter.locale.translate("PRODUCT_CATEGORY")}
                </Text>
                <SelectCategory
                  options={[...categories]}
                  value={presenter?.productBuffer?.category || "Equipaje"}
                  onChange={(value) => {
                    presenter.setProductBuffer({ category: value });
                  }}
                  disabled={presenter.status === "LOADING"}
                  presenter={presenter}
                />
              </label>
              <label>
                <Text as="div" size="2" mb="1" weight="bold">
                  {presenter.locale.translate("HAZMAT")}
                </Text>
                <RadioGroup.Root
                  value={boolToRadioValue(presenter?.productBuffer?.hazmat)}
                  onValueChange={handleRadioChange}
                  disabled={presenter.status === "LOADING"}
                >
                  <Flex gap="2" direction="column">
                    <Text as="label" size="2">
                      <Flex gap="2">
                        <RadioGroup.Item value="1" /> {presenter.locale.translate("YES")}
                      </Flex>
                    </Text>
                    <Text as="label" size="2">
                      <Flex gap="2">
                        <RadioGroup.Item value="2" /> {presenter.locale.translate("NO")}
                      </Flex>
                    </Text>
                    <Text as="label" size="2">
                      <Flex gap="2">
                        <RadioGroup.Item value="3" /> {presenter.locale.translate("NOT_AVAILABLE")}
                      </Flex>
                    </Text>
                  </Flex>
                </RadioGroup.Root>
              </label>
              <label>
                <Text as="div" size="2" mb="1" weight="bold">
                  {`${presenter.locale.translate("PACKAGE_LENGTH")} (cm)`}
                </Text>
                <TextField.Root
                  placeholder={presenter.locale.translate("PACKAGE_LENGTH")}
                  value={
                    presenter.quote.package_length !== null
                      ? presenter.quote.package_length
                      : ""
                  }
                  onChange={handlePackagingChange}
                  type="number"
                  name="package_length"
                  disabled={presenter.status === "LOADING"}
                />
              </label>
              <label>
                <Text as="div" size="2" mb="1" weight="bold">
                  {`${presenter.locale.translate("PACKAGE_WIDTH")} (cm)`}
                </Text>
                <TextField.Root
                  placeholder={presenter.locale.translate("PACKAGE_WIDTH")}
                  value={
                    presenter.quote.package_width !== null
                      ? presenter.quote.package_width
                      : ""
                  }
                  onChange={handlePackagingChange}
                  type="number"
                  name="package_width"
                  disabled={presenter.status === "LOADING"}
                />
              </label>
              <label>
                <Text as="div" size="2" mb="1" weight="bold">
                  {`${presenter.locale.translate("PACKAGE_HEIGHT")} (cm)`}
                </Text>
                <TextField.Root
                  placeholder={presenter.locale.translate("PACKAGE_HEIGHT")}
                  value={
                    presenter.quote.package_height !== null
                      ? presenter.quote.package_height
                      : ""
                  }
                  onChange={handlePackagingChange}
                  type="number"
                  name="package_height"
                  disabled={presenter.status === "LOADING"}
                />
              </label>
              <label>
                <Text as="div" size="2" mb="1" weight="bold">
                  {`${presenter.locale.translate("PACKAGE_WEIGHT")} (kg)`}
                </Text>
                <TextField.Root
                  placeholder={presenter.locale.translate("PACKAGE_WEIGHT")}
                  value={
                    presenter.quote.package_weight !== null
                      ? presenter.quote.package_weight
                      : ""
                  }
                  onChange={handlePackagingChange}
                  type="number"
                  name="package_weight"
                  disabled={presenter.status === "LOADING"}
                />
              </label>
            </>
          )}
        </Flex>
        {validationMessages.length > 0 && <br />}
        <MessagesComponent />
      </Popover.Content>
    </Popover.Root>
  );
});
