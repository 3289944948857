import { observer } from "mobx-react";
import React from "react";
import { withInjection } from "../../Core/Providers/withInjection";
import { ProductDetailPresenter } from "../../Products/ProductDetailPresenter";
import { ProductInfo } from "./ProductInfo/ProductInfo";
import { QuotesList } from "./QuoteList/QuoteList";
import { Tabs, Box, Heading } from "@radix-ui/themes";
import classes from "./ProductDetail.module.css";

const ProductDetailComp: React.FC<{
  presenter: ProductDetailPresenter;
}> = ({ presenter }) => {
  return (
    <div>
      <Heading className={classes.heading} style={{ marginBottom: "0.5rem" }}>
        {presenter.viewModel && presenter.viewModel.name}
      </Heading>
      <Tabs.Root defaultValue="info">
        <Tabs.List>
          <Tabs.Trigger value="info">
            {presenter.locale.translate("PRODUCT_DETAILS")}
          </Tabs.Trigger>
          <Tabs.Trigger value="quoteList">{presenter.locale.translate("QUOTES")}</Tabs.Trigger>
        </Tabs.List>
        <Box px="4" pt="3" pb="2">
          <Tabs.Content value="info">
            <ProductInfo />
          </Tabs.Content>
          <Tabs.Content value="quoteList">
            <QuotesList />
          </Tabs.Content>
        </Box>
      </Tabs.Root>
    </div>
  );
};

export const ProductDetail = withInjection({
  presenter: ProductDetailPresenter,
})(observer(ProductDetailComp));
