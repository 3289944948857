import { makeAutoObservable } from "mobx";
import { inject, injectable } from "inversify";
import type IRouterGateway from "./IRouterGateway";
import { Types } from "../Core/Types";
import { ProductListRepository } from "../Products/ProductListRepository";
import { Match } from "navigo";
import { ProductDetailRepository } from "../Products/ProductDetailRepository";
import { MessagesRepository } from "../Core/Messages/MessagesRepository";

export type Route = {
  routeId: string | null;
  routeDef: {
    path: string;
    uses?: string;
    isSecure: boolean;
  };
  onEnter?: (params?: Partial<{ id: string; quoteId: string }>) => void;
  onLeave?: () => void;
  params?: {
    [key: string]: string;
  };
  query?: {
    [key: string]: string;
  };
};

type RouteConfig = {
  [key: string]: {
    as: string | null;
    uses?: () => void;
    hooks: {
      before: (done: () => void, match?: Match) => void;
    };
  };
};

@injectable()
export class RouterRepository {
  currentRoute: Route = {
    routeId: null,
    routeDef: { path: "", isSecure: false },
  };

  @inject(Types.IRouterGateway)
  routerGateway: IRouterGateway;

  @inject(MessagesRepository)
  messagesRepository: MessagesRepository;

  @inject(ProductListRepository)
  productListRepository: ProductListRepository;

  @inject(ProductDetailRepository)
  productDetailRepository: ProductDetailRepository;

  onRouteChanged: (() => Promise<void>) | null = null;

  routes: Route[] = [
    {
      routeId: "loginLink",
      routeDef: {
        path: "/login",
        isSecure: false,
      },
    },
    {
      routeId: "registerLink",
      routeDef: {
        path: "/register",
        isSecure: false,
      },
    },
    {
      routeId: "homeLink",
      routeDef: {
        path: "/",
        isSecure: true,
      },
      onEnter: () => {
        this.productListRepository.load();
      },
      onLeave: () => {
        this.productListRepository.reset();
      },
    },
    {
      routeId: "productDetails",
      routeDef: {
        path: "/products/:id",
        isSecure: true,
      },
      onEnter: (params) => {
        if (params?.id) {
          this.productDetailRepository.load(params.id);
        }
      },
      onLeave: () => {
        this.productDetailRepository.reset();
      },
    },
    {
      routeId: "default",
      routeDef: {
        path: "*",
        isSecure: false,
      },
    },
  ];

  constructor() {
    makeAutoObservable(this);
  }

  async registerRoutes(
    updateCurrentRoute: (
      routeId: Route["routeId"],
      params?: Route["params"],
      query?: Route["query"]
    ) => Promise<void>,
    onRouteChanged: () => Promise<void>
  ): Promise<void> {
    this.onRouteChanged = onRouteChanged;
    const routeConfig: RouteConfig = {};
    this.routes.forEach((routeArg) => {
      const route: Route = this.findRoute(routeArg.routeId);
      routeConfig[route.routeDef.path] = {
        as: route.routeId,
        uses: () => {},
        hooks: {
          before: (done: () => void, match?: Match) => {
            if (match && match.data) {
              updateCurrentRoute(route.routeId, match.data);
              done();
            } else {
              updateCurrentRoute(route.routeId);
              done();
            }
          },
        },
      };
    });

    this.routerGateway.registerRoutes(routeConfig);
  }

  findRoute(routeId: string | null): Route {
    const route = this.routes.find((route) => route.routeId === routeId);

    return (
      route || {
        routeId: "loadingSpinner",
        routeDef: { path: "", uses: undefined, isSecure: false },
      }
    );
  }

  async goToId(routeId: string): Promise<void> {
    this.routerGateway.goToId(routeId);
  }

  async goToPath(path: string): Promise<void> {
    this.routerGateway.goToPath(path);
  }
}
