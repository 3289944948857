import { Popover, Flex, Text, TextField, Switch } from "@radix-ui/themes";
import { categories } from "../../../../Core/Types";
import { Button } from "../../../../Components/Button/Button";
import { ChatBubbleIcon } from "@radix-ui/react-icons";
import { QuotePresenter } from "../../../../Products/QuotePresenter";
import { SelectCategory } from "../../../../Components/SelectCategory/SelectCategory";
import { observer } from "mobx-react";
import { useValidation } from "../../../../Core/Providers/useValidation";
import { MessagesComponent } from "../../../../Core/Messages/MessagesComponent";
import { toDecimals, validateNumber } from "../../../../Core/utils";

export const PopReferenceFee: React.FC<{
  presenter: QuotePresenter;
}> = observer(({ presenter }) => {
  const [validationMessages, updateClientValidationMessages] = useValidation();

  const handleReferenceFeechange = (e: React.BaseSyntheticEvent): void => {
    const valid = validateNumber(
      e.target.name,
      e.target.value,
      updateClientValidationMessages,
    );
    if (!valid) return;
    const value = e.target.value === "" ? null : toDecimals(e.target.value);

    presenter.updateAmazonFee("reference_fee", value);
  };

  if (!presenter.quote) {
    return <div>No quote found</div>;
  }

  const cleanAndSave = (opening: boolean) => {
    updateClientValidationMessages([]);
    // if the popover is closing, save the quote
    if (!opening) {
      presenter.setPreventReorder(false);
      presenter.saveQuote();
    } else {
      presenter.setPreventReorder(true);
    }
  };

  const handleProductChange = (e: React.BaseSyntheticEvent): void => {
    const valid = validateNumber(
      e.target.name,
      e.target.value,
      updateClientValidationMessages,
    );
    if (!valid) return;
    const name = e.target.name;
    const value = e.target.value === "" ? null : toDecimals(e.target.value);
    const newProduct = { ...presenter.productBuffer, [name]: value };
    presenter.setProductBuffer(newProduct);
  };

  const isValueAvailable =
    (presenter.quote.reference_fee !== null &&
      presenter.quote.reference_fee_overridden) ||
    (presenter.quote.reference_fee_calculated !== null &&
      !presenter.quote.reference_fee_overridden);

  return (
    <Popover.Root onOpenChange={cleanAndSave}>
      <Popover.Trigger>
        <Button variant="soft" color={isValueAvailable ? "indigo" : "orange"}>
          <ChatBubbleIcon width="16" height="16" />
          {!presenter.quote.reference_fee_overridden
            ? presenter.quote.reference_fee_calculated !== null
              ? Number(presenter.quote.reference_fee_calculated).toFixed(2) +
                "  €"
              : presenter.locale.translate("NO_DATA")
            : presenter.quote.reference_fee !== null
            ? Number(presenter.quote.reference_fee).toFixed(2) + "  €"
            : presenter.locale.translate("NO_DATA")}
        </Button>
      </Popover.Trigger>
      <Popover.Content style={{ width: 360 }}>
        <Flex gap="3" direction={"column"}>
          <Text as="label" size="2">
            <Flex gap="2">
              <Switch
                onCheckedChange={(checked) => {
                  presenter.overrideFee("reference_fee_overridden", checked);
                }}
                checked={presenter.quote.reference_fee_overridden}
              />{" "}
              {presenter.locale.translate("MANUAL_VALUE")}
            </Flex>
          </Text>
          {presenter.quote.reference_fee_overridden ? (
            <label>
              <Text as="div" size="2" mb="1" weight="bold">
                {presenter.locale.translate("MANUAL_VALUE_REFERENCE_FEE")}
              </Text>
              <TextField.Root
                placeholder={presenter.locale.translate("REFERENCE_FEE")}
                value={
                  presenter.quote.reference_fee !== null
                    ? presenter.quote.reference_fee
                    : ""
                }
                onChange={handleReferenceFeechange}
                type="number"
                name="reference_fee"
                disabled={presenter.status === "LOADING"}
              />
            </label>
          ) : (
            <>
              <label>
                <Text as="div" size="2" mb="1" weight="bold">
                  {presenter.locale.translate("PRODUCT_PRICE")}
                </Text>
                <TextField.Root
                  placeholder={presenter.locale.translate("PRODUCT_PRICE_PLACEHOLDER")}
                  value={
                    presenter.productBuffer?.pvp !== null
                      ? presenter.productBuffer?.pvp
                      : ""
                  }
                  onChange={handleProductChange}
                  type="number"
                  name="pvp"
                  disabled={presenter.status === "LOADING"}
                />
              </label>
              <label>
                <Text as="div" size="2" mb="1" weight="bold">
                  {presenter.locale.translate("PRODUCT_CATEGORY")}
                </Text>
                <SelectCategory
                  options={[...categories]}
                  value={presenter?.productBuffer?.category || "Equipaje"}
                  onChange={(value) => {
                    presenter.setProductBuffer({ category: value });
                  }}
                  disabled={presenter.status === "LOADING"}
                  presenter={presenter}
                />
              </label>
            </>
          )}
        </Flex>
        {validationMessages.length > 0 && <br />}
        <MessagesComponent />
      </Popover.Content>
    </Popover.Root>
  );
});
