import React from "react";
import { observer } from "mobx-react";
import { withInjection } from "../../Core/Providers/withInjection";
import { AuthenticationPresenter } from "../../Authentication/AuthenticationPresenter";
import { MessagesComponent } from "../../Core/Messages/MessagesComponent";
import { useValidation } from "../../Core/Providers/useValidation";
import { Button } from "../../Components/Button/Button";
import classes from "./LoginRegister.module.css";
import {
  Text,
  Checkbox,
  Flex,
  Heading,
  Link,
  TextField,
} from "@radix-ui/themes";
import LocaleSwitcher from "../../Components/LocaleSwitcher/LocaleSwitcher";

const LoginRegisterComp = observer(
  ({
    presenter,
    register,
  }: {
    presenter: AuthenticationPresenter;
    register: boolean;
  }): React.ReactNode => {
    const [, updateClientValidationMessages] = useValidation();
    const formValid = () => {
      const clientValidationMessages: Array<string> = [];
      if (presenter.email === "") {
        clientValidationMessages.push(
          presenter.locale.translate("MISSING_EMAIL"),
        );
      }
      if (presenter.password === "") {
        clientValidationMessages.push(
          presenter.locale.translate("MISSING_PASSWORD"),
        );
      } else if (
        presenter.option === "register" &&
        presenter.password &&
        presenter.password.length < 8
      ) {
        clientValidationMessages.push(presenter.locale.translate("MIN_CHARS"));
      }
      updateClientValidationMessages(clientValidationMessages);
      return clientValidationMessages.length === 0;
    };
    const switchOption = () => {
      presenter.option = presenter.option === "register" ? "login" : "register";
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!formValid()) {
        return;
      }
      presenter.option === "register"
        ? presenter.register()
        : presenter.login();
    };

    // initially clearing the form, setting option to login
    React.useEffect(() => {
      presenter.reset();
      if (register) {
        presenter.option = "register";
      }
    }, [presenter, register]);

    return (
      <div className={classes.loginRegister}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Heading>{presenter.title}</Heading>
          <LocaleSwitcher variant="ghost" presenter={presenter} />
        </div>
        <form onSubmit={handleSubmit}>
          <fieldset>
            <div className={classes.formGroup}>
              <label htmlFor="email-input">
                {presenter.locale.translate("EMAIL")}
              </label>
              <TextField.Root
                type="email"
                className="form-control"
                id="email-input"
                placeholder="abc@gmail.com"
                value={presenter.email === null ? "" : presenter.email}
                onChange={(e) => (presenter.email = e.target.value)}
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="password-input">
                {presenter.locale.translate("PASSWORD")}
              </label>
              <TextField.Root
                type="password"
                className="form-control"
                id="password-input"
                placeholder={presenter.locale.translate("PASSWORD_PLACEHOLDER")}
                value={presenter.password === null ? "" : presenter.password}
                onChange={(e) => (presenter.password = e.target.value)}
              />
            </div>

            {presenter.option === "register" && (
              <Flex justify={"end"} align={"center"} mt="2" pr="3">
                <Text as="label">
                  <Flex align={"center"} gap="2">
                    <Checkbox
                      mb="1"
                      checked={presenter.acceptedTerms}
                      onCheckedChange={(checked) =>
                        (presenter.acceptedTerms = !!checked)
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          presenter.acceptedTerms = !presenter.acceptedTerms;
                        }
                      }}
                    />
                    <Link
                      target="_blank"
                      href={presenter.locale.translate("TERMS_URL")}
                    >
                      {presenter.locale.translate("ACCEPT_TERMS")}
                    </Link>
                  </Flex>
                </Text>
              </Flex>
            )}
          </fieldset>
          <MessagesComponent />
          <Button
            className={classes.submitButton}
            disabled={
              //important not to block the login!
              presenter.option === "register" && !presenter.acceptedTerms
            }
            type="submit"
          >
            {presenter.submitButtonTitle}
          </Button>
        </form>
        <div className={classes.loginLinkWrapper}>
          <Link className={classes.loginLink} onClick={switchOption}>
            {presenter.switchButtonTitle}
          </Link>
        </div>
        <div className={classes.loginLinkWrapper}>
          <Link
            className={classes.loginLink}
            href="https://www.agilizaseller.com/pw-reset"
            target="_blank"
          >
            {presenter.locale.translate("FORGOT_PASSWORD")}
          </Link>
        </div>
      </div>
    );
  },
);

const LoginRegister = withInjection({
  presenter: AuthenticationPresenter,
})(LoginRegisterComp);

export default LoginRegister;
