import { observer } from "mobx-react";
import { Flex, Box, TextField } from "@radix-ui/themes";
import { QuotePresenter } from "../../../../Products/QuotePresenter";
import { SelectIncoterm } from "../TopSection/SelectIncoterm";
import { incoterms, currencies } from "../../../../Core/Types";
import { SelectCurrency } from "../Quote/SelectCurrency";
import { useValidation } from "../../../../Core/Providers/useValidation";
import { MessagesComponent } from "../../../../Core/Messages/MessagesComponent";
import { Button } from "../../../../Components/Button/Button";
import { toDecimals, validateNumber } from "../../../../Core/utils";
import classes from "./PriceBreaks.module.css";
import { Cross2Icon, CheckIcon } from "@radix-ui/react-icons";
import { InfoHoverCard } from "../../../../Components/InfoTooltip/InfoTooltip";

const AddBreakInlineComp: React.FC<{
  presenter: QuotePresenter;
  type: "Manufacturing" | "Shipping";
  priceBreak: PriceBreak | null;
}> = ({ presenter, type, priceBreak }) => {
  const [validationMessages, updateClientValidationMessages] = useValidation();
  const isUpdate: boolean = priceBreak !== null && priceBreak?.id !== null;
  const handleBreakChange = (e: React.BaseSyntheticEvent): void => {
    const valid = validateNumber(
      e.target.name,
      e.target.value,
      updateClientValidationMessages,
    );
    if (!valid) return;
    const name = e.target.name as "quantity" | "price";
    const value = e.target.value === "" ? null : toDecimals(e.target.value);
    if (isUpdate) {
      presenter.editingPriceBreak[name] = value;
    } else {
      presenter.newPriceBreak[name] = value;
    }
  };

  const defaultCurrency = (type: "Manufacturing" | "Shipping") => {
    const cur: Currencies | null =
      type === "Manufacturing"
        ? presenter.quote && presenter.quote.manufacturing_cost_currency
        : presenter.quote && presenter.quote.shipping_cost_currency;
    return cur;
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isUpdate) {
      presenter.savePriceBreak();
    } else {
      presenter.addPriceBreak(type);
    }
  };

  return (
    <form onSubmit={onSubmit} style={{ width: "100%" }}>
      <div className={classes.priceBreakRow}>
        <Flex direction="column" gap="1">
          <Flex direction="row" gap="1" justify="between">
            <Box className={classes.abi_qty}>
              <TextField.Root
                placeholder={presenter.locale.translate("AMOUNT").toLowerCase()}
                value={
                  isUpdate
                    ? presenter.editingPriceBreak.quantity
                      ? presenter.editingPriceBreak.quantity
                      : ""
                    : presenter.newPriceBreak.quantity
                    ? presenter.newPriceBreak.quantity
                    : ""
                }
                name="quantity"
                onChange={handleBreakChange}
                disabled={presenter.status === "LOADING"}
              />
            </Box>
            <Box className={classes.abi_priceContainer}>
              <Box>
                <TextField.Root
                  placeholder={presenter.locale.translate("PRICE").toLowerCase()}
                  value={
                    isUpdate
                      ? presenter.editingPriceBreak.price
                        ? presenter.editingPriceBreak.price
                        : ""
                      : presenter.newPriceBreak.price
                      ? presenter.newPriceBreak.price
                      : ""
                  }
                  name="price"
                  type="number"
                  step="any"
                  onChange={handleBreakChange}
                  disabled={presenter.status === "LOADING"}
                />
              </Box>
              <Box className={classes.abi_cur}>
                <SelectCurrency
                  options={[...currencies]}
                  value={
                    isUpdate
                      ? presenter.editingPriceBreak.currency
                        ? presenter.editingPriceBreak.currency
                        : defaultCurrency(type)
                      : presenter.newPriceBreak.currency
                      ? presenter.newPriceBreak.currency
                      : defaultCurrency(type)
                  }
                  onChange={(currency) =>
                    isUpdate
                      ? (presenter.editingPriceBreak.currency = currency)
                      : (presenter.newPriceBreak.currency = currency)
                  }
                  compact
                disabled={presenter.status === "LOADING"}
                fallback={presenter.locale.translate("NO_CURRENCY")}
                />
              </Box>
            </Box>
            <Box className={classes.abi_inco}>
              <SelectIncoterm
                value={
                  isUpdate
                    ? presenter.editingPriceBreak.incoterm
                      ? presenter.editingPriceBreak.incoterm
                      : null
                    : presenter.newPriceBreak.incoterm
                    ? presenter.newPriceBreak.incoterm
                    : null
                }
                onChange={(incoterm) =>
                  isUpdate
                    ? (presenter.editingPriceBreak.incoterm = incoterm)
                    : (presenter.newPriceBreak.incoterm = incoterm)
                }
                options={[...incoterms]}
                disabled={presenter.status === "LOADING"}
                fallback={presenter.locale.translate("NO_INCOTERM")}
              />
            </Box>
            {isUpdate && (
              <>
                <InfoHoverCard title="" content="Confirmar Cambios">
                  <Button type="submit">
                    <CheckIcon width="1rem" height="1rem" />
                  </Button>
                </InfoHoverCard>
                <InfoHoverCard title="" content="Cancelar Cambios">
                  <Button
                    type="button"
                    color="red"
                    onClick={() => {
                      presenter.setEditingPriceBreak(null);
                    }}
                  >
                    <Cross2Icon width="1rem" height="1rem" />
                  </Button>
                </InfoHoverCard>
              </>
            )}
          </Flex>
          {!isUpdate && (
            <Button type="submit" disabled={presenter.status === "LOADING"}>
              {presenter.locale.translate("ADD_PRICE_BREAK")}
            </Button>
          )}
        </Flex>
      </div>
      {validationMessages.length > 0 && <MessagesComponent />}
    </form>
  );
};

export const AddBreakInline = observer(AddBreakInlineComp);
