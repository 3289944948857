import { observer } from "mobx-react";
import { withInjection } from "../../Core/Providers/withInjection";
import { Router } from "../../Routing/Router";
import { NavigationPresenter } from "../../Navigation/NavigationPresenter";
import classes from "./Navigation.module.css";
import { Link, Heading } from "@radix-ui/themes";

const NavigationComp = observer(
  ({ presenter }: { router: Router; presenter: NavigationPresenter }) => {
    return (
      <div className={classes.navMenu}>
        {presenter.currentNode?.model?.type === "root" && (
          <Heading>{presenter?.currentNode?.model?.text}</Heading>
        )}
        {presenter?.viewModel?.showBack && (
          <Heading>
            <Link onClick={() => presenter.back()}>
              {presenter.parentNode.model.text}
            </Link>
            {" > "}
            {presenter.currentNode.model.text}
          </Heading>
        )}
      </div>
    );
  }
);

export const Navigation = withInjection({
  presenter: NavigationPresenter,
  router: Router,
})(NavigationComp);
