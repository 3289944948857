import React from "react";
import { Callout, Flex } from "@radix-ui/themes";
import {
  ExclamationTriangleIcon,
  Cross1Icon,
  RocketIcon,
} from "@radix-ui/react-icons";
import { Link, Text, IconButton } from "@radix-ui/themes";
import { Button } from "../Button/Button";
import { observer } from "mobx-react";
import { AppPresenter } from "../../AppPresenter";

export const TrialCallout = observer(({
  color = "jade",
  daysLeft,
  userId,
  presenter
}: {
  daysLeft: number;
  color?: "indigo" | "jade" | "red";
  userId: string;
  presenter: AppPresenter
}) => {
  const isTrialOver = daysLeft < 0;
  const [isClosed, setClosed] = React.useState(false);
  return isClosed ? null : (
    <Callout.Root
      mb="5"
      color={isTrialOver ? "red" : color}
      style={{ display: "block" }}
    >
      <Flex justify={"between"} align="center" gap="3">
        <Callout.Icon>
          <ExclamationTriangleIcon />
        </Callout.Icon>
        <Callout.Text>
          {isTrialOver ? (
            <Text>{presenter.locale.translate("TRIAL_OVER")}</Text>
          ) : (
            <Text>
              {presenter.locale.translate("REMAINING_DAYS_1")}<strong>{daysLeft}</strong>{presenter.locale.translate("REMAINING_DAYS_2")}
            </Text>
          )}
        </Callout.Text>
        <Button style={{ marginLeft: "auto" }}>
          <Link
            href={`https://www.agilizaseller.com/pricing?uuid=${userId}`}
            target="_blank"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            {presenter.locale.translate("UPGRADE_PLAN")}
          </Link>
          <RocketIcon width="16" height="16" />
        </Button>
        {!isTrialOver && (
          <IconButton
            style={{ cursor: "pointer" }}
            onClick={() => setClosed(true)}
            variant="soft"
          >
            <Cross1Icon width="16" height="16" />
          </IconButton>
        )}
      </Flex>
    </Callout.Root>
  );
});
