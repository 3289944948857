import { Select } from "@radix-ui/themes";

type SelectStatusProps<T>= {
  value: T;
  options: T[];
  onChange: (value: T) => void;
};

export const SelectStatus: React.FC<SelectStatusProps<ProductStatus>> = ({ onChange, value, options }) => {
  if(!value) return null;
  return (
    <Select.Root value={value} onValueChange={onChange}>
      <Select.Trigger />
      <Select.Content>
        <Select.Group>
          {options.map((option) => {
            return <Select.Item key={option} value={option}>{option}</Select.Item>;
          })}
        </Select.Group>
      </Select.Content>
    </Select.Root>
  );
};
