export type ServerDto = {
  success: boolean;
  result: { message?: string };
};

export type PackedMessage = {
  success: boolean;
  serverMessage: string;
};

export class MessagePacking {
  static unpackServerDtoToPm = (dto: ServerDto): PackedMessage => {
    const serverMessage = dto?.result?.message ? dto.result.message : "No message from server";
    return { success: dto?.success, serverMessage };
  };
}
