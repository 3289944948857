import React from "react";
import { Link, Callout, Flex, IconButton } from "@radix-ui/themes";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { Cross1Icon, ResetIcon } from "@radix-ui/react-icons";
import { observer } from "mobx-react";
import { AuthenticationPresenter } from "../../Authentication/AuthenticationPresenter";
import { withInjection } from "../../Core/Providers/withInjection";
import { Button } from "../Button/Button";

export const VerifyCalloutComp = observer(
  ({
    email,
    color = "jade",
    presenter,
  }: {
    email?: string | null;
    color?: "indigo" | "jade" | "red";
    presenter: AuthenticationPresenter;
  }) => {
    const [isClosed, setClosed] = React.useState(false);
    return isClosed ? null : (
      <Callout.Root mb="5" color={color} style={{ display: "block" }}>
        <Flex justify={"between"} align="center" gap="3">
          <Callout.Icon>
            <ExclamationTriangleIcon />
          </Callout.Icon>
          <Callout.Text>
            {presenter.locale.translate("VERIFY_EMAIL_1")}<strong>{presenter.locale.translate("VERIFY_EMAIL_2")}</strong>{presenter.locale.translate("VERIFY_EMAIL_3")}{" "}
            <strong>{presenter.locale.translate("EMAIL")}</strong>{presenter.locale.translate("VERIFY_EMAIL_4")}<strong>{email}</strong>.
          </Callout.Text>
          <Button style={{ marginLeft: "auto" }}>
            <Link
              href="#"
              onClick={() => presenter.resendVerificationEmail()}
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              {presenter.locale.translate("RESEND_EMAIL")}
            </Link>
            <ResetIcon width="16" height="16" />
          </Button>
          <IconButton
            variant="soft"
            style={{ cursor: "pointer" }}
            onClick={() => setClosed(true)}
          >
            <Cross1Icon width="16" height="16" />
          </IconButton>
        </Flex>
      </Callout.Root>
    );
  },
);

export const VerifyCallout = withInjection({
  presenter: AuthenticationPresenter,
})(VerifyCalloutComp);
