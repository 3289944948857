import { Popover, Flex, Text, TextField, Switch } from "@radix-ui/themes";
import { Button } from "../../../../Components/Button/Button";
import { ChatBubbleIcon } from "@radix-ui/react-icons";
import { QuotePresenter } from "../../../../Products/QuotePresenter";
import { observer } from "mobx-react";
import { useValidation } from "../../../../Core/Providers/useValidation";
import { MessagesComponent } from "../../../../Core/Messages/MessagesComponent";
import { toDecimals, validateNumber } from "../../../../Core/utils";

export const PopLogisticFee: React.FC<{
  presenter: QuotePresenter;
}> = observer(({ presenter }) => {
  const [validationMessages, updateClientValidationMessages] = useValidation();

  const handleImportFeechange = (e: React.BaseSyntheticEvent): void => {
    const valid = validateNumber(
      e.target.name,
      e.target.value,
      updateClientValidationMessages,
    );
    if (!valid) return;
    const value = e.target.value === "" ? null : toDecimals(e.target.value);
    presenter.updateAmazonFee("logistic_fee", value);
  };

  const handlePackagingChange = (e: React.BaseSyntheticEvent): void => {
    const valid = validateNumber(
      e.target.name,
      e.target.value,
      updateClientValidationMessages,
    );
    if (!valid) return;
    const value = e.target.value === "" ? null : toDecimals(e.target.value);
    const name = e.target.name;
    presenter.updatePackagingProperty(name, value);
  };

  const cleanAndSave = (opening: boolean) => {
    updateClientValidationMessages([]);
    // if the popover is closing, save the quote
    if (!opening) {
      presenter.setPreventReorder(false);
      presenter.saveQuote();
    } else {
      presenter.setPreventReorder(true);
    }
  };

  const handleProductChange = (e: React.BaseSyntheticEvent): void => {
    const valid = validateNumber(
      e.target.name,
      e.target.value,
      updateClientValidationMessages,
    );
    if (!valid) return;
    const name = e.target.name;
    const value = e.target.value === "" ? null : toDecimals(e.target.value);
    const newProduct = { ...presenter.productBuffer, [name]: value };
    presenter.setProductBuffer(newProduct);
  };

  const isValueAvailable =
    (presenter?.quote?.logistic_fee !== null &&
      presenter?.quote?.logistic_fee_overridden) ||
    (presenter?.quote?.logistic_fee_calculated !== null &&
      !presenter?.quote?.logistic_fee_overridden);

  if (!presenter.quote) {
    return <div>{presenter.locale.translate("NO_QUOTES")}</div>;
  }

  return (
    <Popover.Root onOpenChange={cleanAndSave}>
      <Popover.Trigger>
        <Button variant="soft" color={isValueAvailable ? "indigo" : "orange"}>
          <ChatBubbleIcon width="16" height="16" />
          {!presenter.quote.logistic_fee_overridden
            ? presenter.quote.logistic_fee_calculated !== null
              ? Number(presenter.quote.logistic_fee_calculated).toFixed(2) +
                "  €"
              : presenter.locale.translate("NO_DATA")
            : presenter.quote.logistic_fee !== null
            ? Number(presenter.quote.logistic_fee).toFixed(2) + "  €"
            : presenter.locale.translate("NO_DATA")}
        </Button>
      </Popover.Trigger>
      <Popover.Content style={{ width: 360 }}>
        <Flex gap="3" direction={"column"}>
          <Text as="label" size="2">
            <Flex gap="2">
              <Switch
                onCheckedChange={(checked) => {
                  presenter.overrideFee("logistic_fee_overridden", checked);
                }}
                checked={presenter.quote.logistic_fee_overridden}
              />{" "}
              {presenter.locale.translate("MANUAL_VALUE")}
            </Flex>
          </Text>
          {presenter.quote.logistic_fee_overridden ? (
            <label>
              <Text as="div" size="2" mb="1" weight="bold">
                {presenter.locale.translate("MANUAL_VALUE_LOGISTIC_FEE")}
              </Text>
              <TextField.Root
                placeholder={presenter.locale.translate("LOGISTIC_FEE")}
                value={
                  presenter.quote.logistic_fee !== null
                    ? presenter.quote.logistic_fee
                    : ""
                }
                onChange={handleImportFeechange}
                type="number"
                name="logistic_fee"
                disabled={presenter.status === "LOADING"}
              />
            </label>
          ) : (
            <>
              <label>
                <Text as="div" size="2" mb="1" weight="bold">
                  {presenter.locale.translate("PRODUCT_PRICE")}
                </Text>
                <TextField.Root
                  placeholder={presenter.locale.translate("PRODUCT_PRICE_PLACEHOLDER")}
                  value={
                    presenter.productBuffer?.pvp !== null
                      ? presenter.productBuffer?.pvp
                      : ""
                  }
                  onChange={handleProductChange}
                  type="number"
                  name="pvp"
                  disabled={presenter.status === "LOADING"}
                />
              </label>
              <label>
                <Text as="div" size="2" mb="1" weight="bold">
                  { `${presenter.locale.translate("PACKAGE_LENGTH")} (cm)` }
                </Text>
                <TextField.Root
                  placeholder={presenter.locale.translate("PACKAGE_LENGTH")}
                  value={
                    presenter.quote.package_length !== null
                      ? presenter.quote.package_length
                      : ""
                  }
                  onChange={handlePackagingChange}
                  type="number"
                  name="package_length"
                  disabled={presenter.status === "LOADING"}
                />
              </label>
              <label>
                <Text as="div" size="2" mb="1" weight="bold">
                  { `${presenter.locale.translate("PACKAGE_WIDTH")} (cm)` }
                </Text>
                <TextField.Root
                  placeholder={presenter.locale.translate("PACKAGE_WIDTH")}
                  value={
                    presenter.quote.package_width !== null
                      ? presenter.quote.package_width
                      : ""
                  }
                  onChange={handlePackagingChange}
                  type="number"
                  name="package_width"
                  disabled={presenter.status === "LOADING"}
                />
              </label>
              <label>
                <Text as="div" size="2" mb="1" weight="bold">
                  {`${presenter.locale.translate("PACKAGE_HEIGHT")} (cm)`}
                </Text>
                <TextField.Root
                  placeholder={presenter.locale.translate("PACKAGE_HEIGHT")}
                  value={
                    presenter.quote.package_height !== null
                      ? presenter.quote.package_height
                      : ""
                  }
                  onChange={handlePackagingChange}
                  type="number"
                  name="package_height"
                  disabled={presenter.status === "LOADING"}
                />
              </label>
              <label>
                <Text as="div" size="2" mb="1" weight="bold">
                  {`${presenter.locale.translate("PACKAGE_WEIGHT")} (kg)`}
                </Text>
                <TextField.Root
                  placeholder={presenter.locale.translate("PACKAGE_WEIGHT")}
                  value={
                    presenter.quote.package_weight !== null
                      ? presenter.quote.package_weight
                      : ""
                  }
                  onChange={handlePackagingChange}
                  type="number"
                  name="package_weight"
                  disabled={presenter.status === "LOADING"}
                />
              </label>
            </>
          )}
        </Flex>
        {validationMessages.length > 0 && <br />}
        <MessagesComponent />
      </Popover.Content>
    </Popover.Root>
  );
});
